import configuration from "../config";
import axios from "./axiosConfiguration";

const endpoint = `${configuration.api_url}constante`;
export const findByKey = (key) => {
    return axios
        .get(`${endpoint}/${key}`)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const saveByKey = (key, value) => {
    return axios
        .post(`${endpoint}/${key}`, { value })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const getPowerBiEmbedInfos = () => {
    return axios
        .get(`${endpoint}/power-bi`)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};


export default {
    findByKey,
    saveByKey,
    getPowerBiEmbedInfos
};
