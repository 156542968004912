export const HEAD = 1;
export const NECK = 2;
export const BACK = 3;
export const TRUNCK = 4;
export const UPPER_LIMB = 5;
export const LOWER_LIMB = 6;
export const WHOLE_BODY = 7;
export const OTHER_PARTS = 8;

export default {
  HEAD,
  NECK,
  BACK,
  TRUNCK,
  UPPER_LIMB,
  LOWER_LIMB,
  WHOLE_BODY,
  OTHER_PARTS,
};
