<template>
  <h1 class="" v-if="size === 1">{{ title }}</h1>
  <h2 class="" v-else-if="size === 2">{{ title }}</h2>
  <h3 class="" v-else-if="size === 3">{{ title }}</h3>
  <h4 class="" v-else-if="size === 4">{{ title }}</h4>
  <h5 class="" v-else-if="size === 5">{{ title }}</h5>
  <h6 class="" v-else-if="size === 6">{{ title }}</h6>
</template>

<script>
export default {
  name: "AsTitle",
  props: {
    size: {
      type: Number,
      default: 1,
    },
    titleClass2: {
      type: String,
      default: "title",
    },
    title: String,
    paddingTop: {
      type: String,
      default: "10px",
    },
    paddingBottom: {
      type: String,
      default: "10px",
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/fonts.scss";
body {
  div,
  .article .ql-editor {
    .bg-default {
      h1,
      h2 {
        background-color: white;
        color: $default_color;
      }
    }
    .bg-white {
      h1,
      h2 {
        background-color: $default_color;
        color: $light_font;
      }
    }
  }
}
</style>
