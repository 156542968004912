export const getTinyMceConfig = (
    translateCallback,
    customData = null
) => {
    return {
        menubar: true,
        statusbar: true,
        language: "fr_FR",
        height: 500,
        plugins: "advlist autolink autoresize hr lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste help wordcount",
        toolbar:
            "visualblocks | undo redo | insert | formatselect | translate | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent nonbreaking | link image | code | removeformat | help ",
        content_css: ["styles.css"],
        image_title: true,
        automatic_uploads: true,
        file_browser_callback_types: "image",
        file_picker_callback: function (cb) {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.onchange = function () {
                const file = this.files[0];
                const reader = new FileReader();
                reader.onload = function () {
                    const id = "blobid" + new Date().getTime();
                    const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                    const base64 = reader.result.split(',')[1];
                    const blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);
                    cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
            };

            input.click();
        },
        setup: function (editor) {
            editor.ui.registry.addButton('translate', {
                text: 'Traduire',
                onAction: function () {
                    translateCallback(customData)
                }
            })
        }
    };

}