<template>
  <div id="statistics">
    <as-container>
      <div class="grid">
        <div class="col flex justify-content-between">
          <div v-if="!editMode">
            <Dropdown v-model="selectedEntreprise" :options="entreprisesOptions" optionLabel="label" optionValue="value"
              @change="onSelectCompany" class="p-d-print-none" />
            <as-title class="hidden p-d-print-block" :title="currentEntreprise ? currentEntreprise.nom : ''"
              :size="1" />
          </div>
          <div class="col" v-else>
            <as-title :title="currentEntreprise ? currentEntreprise.nom : ''" :size="1" />
          </div>
          <div v-if="(isAdmin || (isSafetyLeader && isSameEntreprise)) && !editMode
            ">
            <Button @click="enterEditMode" icon="pi pi-pencil" class="
                p-button-sm p-button-rounded
                button-default
                reversed
                p-d-print-none
              "></Button>
          </div>
        </div>
      </div>
      <as-block shadow>
        <div id="statistics-table">
          <div class="grid">
            <div class="col-12 sm:col-12 lg:col-6 xl:col-3">
              <Skeleton v-if="isBusy" width="100%" height="400px" />
              <bu-block v-else-if="chiffres" v-model="chiffres" :editMode="editMode" />
            </div>
            <div class="col-12 sm:col-12 lg:col-6 xl:col-3">
              <Skeleton v-if="isBusy" width="100%" height="400px" />
              <organisation-block v-else-if="chiffres" v-model="chiffres" :editMode="editMode" />
            </div>
            <div class="col-12 sm:col-12 lg:col-6 xl:col-3">
              <Skeleton v-if="isBusy" width="100%" height="400px" />
              <performances-block v-else-if="chiffres" v-model="chiffres" :editMode="editMode && isAdmin" />
            </div>
            <div class="col-12 sm:col-12 lg:col-6 xl:col-3">
              <Skeleton v-if="isBusy" width="100%" height="400px" />
              <evaluation-block v-else-if="chiffres" v-model="chiffres" :editMode="editMode" />
            </div>
          </div>
        </div>
      </as-block>
    </as-container>

    <as-container class="actions">
      <div class="flex justify-content-end">
        <Button :label="$t('statistics.download_button')" class="button-default mr-2 p-d-print-none" @click="download"
          v-if="!editMode && !isBusy && chiffres" />
        <Button v-if="editMode && (isAdmin || isSafetyLeader)" icon="pi pi-times"
          class="p-button-sm button-default mr-2" iconPos="right" :label="$t('cms_page.cancel')"
          @click="handleQuitEditMode">
        </Button>
        <Button v-if="editMode && (isAdmin || isSafetyLeader)" icon="pi pi-check" class="p-button-sm button-primary"
          iconPos="right" :label="$t('cms_page.save')" @click="handleSave">
        </Button>
      </div>
    </as-container>
    <as-confirm-modal :isVisible="showConfirmModal" :modalMessage="modalMessage" @onConfirm="confirmModal"
      @onCancel="cancelModal" />
  </div>
</template>

<script>
// @ is an alias to /src
import AsContainer from "@/components/Containers/AsContainer.vue";
import AsBlock from "@/components/Containers/AsBlock.vue";
import AsTitle from "@/components/Elements/AsTitle.vue";
import AsConfirmModal from "@/components/Modals/AsConfirmModal.vue";

import BuBlock from "../components/Chiffres/BuBlock.vue";
import OrganisationBlock from "../components/Chiffres/OrganisationBlock.vue";
import PerformancesBlock from "../components/Chiffres/PerformancesBlock.vue";
import EvaluationBlock from "../components/Chiffres/EvaluationBlock.vue";
import chiffresServiceApi from "../services/chiffresServiceApi";
import entreprisesServiceApi from "../services/entreprisesServiceApi";

import { mapGetters } from "vuex";
import roleMixins from "../mixins/roleMixins";

import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Skeleton from "primevue/skeleton";

import modalModes from "../constantes/modalModes";
import fichiersServiceApi from "../services/fichiersServiceApi";
import apiEventTypes from "@/constantes/apiEventTypes";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  name: "Statistics",
  components: {
    AsContainer,
    AsConfirmModal,
    AsTitle,
    AsBlock,
    BuBlock,
    OrganisationBlock,
    PerformancesBlock,
    EvaluationBlock,
    Button,
    Dropdown,
    Skeleton,
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      driveConfig: "getConfig",
    }),
    isSameEntreprise() {
      return this.user && this.user.idEntreprise === this.selectedEntreprise;
    },
    entreprisesOptions() {
      return this.entreprises.map((el) => {
        return {
          label: `${el.nom}`,
          value: el.id,
        };
      });
    },
    currentEntreprise() {
      return this.entreprises.find((el) => el.id === this.selectedEntreprise);
    },
  },
  data() {
    return {
      originalChiffres: undefined,
      chiffres: undefined,
      editMode: false,
      showConfirmModal: false,
      modalMessage: "",
      entreprises: [],
      selectedEntreprise: undefined,
      isBusy: false,
    };
  },
  watch: {
    user() {
      this.getChiffres(this.user.idEntreprise);
      this.selectedEntreprise = this.user.idEntreprise;
    },
  },
  mixins: [roleMixins, fichiersServiceApi],
  beforeMount() {
    if (this.user && this.user.idEntreprise) {
      this.getChiffres(this.user.idEntreprise);
      this.selectedEntreprise = this.user.idEntreprise;
    }
    this.getEntreprises();
  },
  methods: {
    getEntreprises() {
      return entreprisesServiceApi
        .getEntreprises()
        .then((result) => {
          this.entreprises = result;
          return Promise.resolve(result);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    getChiffres(idEntreprise) {
      this.isBusy = true;
      return chiffresServiceApi
        .getChiffres(idEntreprise)
        .then((result) => {
          this.originalChiffres = result;
          this.chiffres = Object.assign({}, result);
          this.isBusy = false;
          return Promise.resolve();
        })
        .catch((err) => {
          this.isBusy = false;
          return Promise.reject(err);
        });
    },
    enterEditMode() {
      this.editMode = true;
    },
    handleQuitEditMode() {
      this.confirmModalMode = modalModes.CONFIRM_QUIT;
      this.modalMessage = this.$t(
        "confirm_modal.confirm_discard_change_message"
      );
      this.showConfirmModal = true;
    },
    handleSave() {
      this.confirmModalMode = modalModes.CONFIRM_SAVE;
      this.modalMessage = this.$t("confirm_modal.confirm_save_change_message");
      this.showConfirmModal = true;
    },
    confirmModal() {
      switch (this.confirmModalMode) {
        case modalModes.CONFIRM_SAVE:
          this.save();
          break;
        case modalModes.CONFIRM_QUIT:
          this.quitEditMode();
          break;
      }
    },
    cancelModal() {
      this.showConfirmModal = false;
    },
    quitEditMode() {
      this.chiffres = Object.assign({}, this.originalChiffres);
      this.editMode = false;
      this.showConfirmModal = false;
    },
    async save() {
      chiffresServiceApi
        .saveChiffres(this.chiffres.id, this.chiffres)
        .then((savedChiffre) => {
          this._uploadStatisticsFiles(
            this.chiffres,
            savedChiffre.id
          );
          this.getChiffres(this.selectedEntreprise);
          this.editMode = false;
          this.showConfirmModal = false;
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_title"),
                message: this.$t("popup_messages.success_message"),
              },
            })
          );
        });
    },
    download() {
      var elementHeight = 872;
      var elementWidth = 1728;
      var ratio = elementHeight / elementWidth;
      var element = document.getElementById("statistics-table");

      html2canvas(element, {
        height: elementHeight,
        width: elementWidth,
        windowWidth: 1920,
      }).then((canvas) => {
        let pdf = new jsPDF({
          orientation: "l",
          unit: "mm",
          format: "a4",
          compress: true,
        });
        let canvasImg = canvas.toDataURL();
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();
        height = ratio * width;
        pdf.addImage(canvasImg, "PNG", 5, 5, width - 10, height);
        pdf.save(
          this.currentEntreprise.nom + "_" + new Date().toISOString() + ".pdf"
        );
      });
    },
    onSelectCompany() {
      this.getChiffres(this.selectedEntreprise);
    },

    async _uploadStatisticsFiles(el, entityId) {
      const files = this._getFilesToUpload(el)
      this.uploadFiles(files, "chiffre", entityId, +this.user.idLdap, () => {
        this.getChiffres(this.selectedEntreprise);
      })
    },

    _getFilesToUpload(el) {
      const files = []
      if (el.fileToUpload) {
        files.push({ file: el.fileToUpload })
      }
      return files;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/colors.scss";
@import "../assets/styles/fonts.scss";

#statistics {
  .actions {
    padding-top: 20px;
  }
}

#statistics-table {
  font-size: $font_default;
  word-break: break-word;
  text-align: center;

  .subtitle {
    text-align: left;
    text-decoration: underline;
    font-weight: bold;
    border-right: solid 0px $light_bg !important;
  }

  .section-title {
    color: white;
    font-weight: bold;
    font-size: $font_title_size;
    padding-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chiffre-line-demi {
    min-height: 50px;
  }

  .chiffre-line {
    min-height: 100px;
  }

  .chiffre-line-2 {
    min-height: 200px;
  }

  .chiffre-line-2-demi {
    min-height: 250px;
  }

  b {
    font-size: $font_title_size;
  }

  .grid {
    margin: 0;
    border-bottom: solid 1px $light_bg;
    border-left: solid 1px $light_bg;
    border-right: solid 1px $light_bg;

    &:first-child {
      border-top: solid 1px $light_bg;
    }

    div {
      &.col-3 {
        align-self: center;
      }

      &:not(.col-12) {
        &:not(:last-child) {
          border-right: solid 1px $light_bg;
        }
      }

      &.col-12:not(:last-child) {
        border-bottom: solid 1px $light_bg;
      }
    }
  }

  @media (max-width: 425px) {
    font-size: $font_very_small;
    line-height: $font_small;

    .section-title {
      font-size: $font_default;
      line-height: $font_default;
    }

    b {
      font-size: $font_default;
    }
  }

  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: $font_small;
    line-height: $font_small;

    .section-title {
      font-size: $font_default;
      line-height: $font_default;
    }

    b {
      font-size: $font_default;
      letter-spacing: 0px;
    }
  }

  @media (min-width: 1400px) and (max-width: 1500px) {
    .section-title {
      font-size: $font_navigation_size;
      line-height: $font_navigation_size;
    }

    b {
      font-size: $font_navigation_size;
      letter-spacing: 0px;
    }
  }
}
</style>
