<template>
  <div id="reporting">
    <as-container>
      <div class="grid">
        <div class="col-12">
          <div>
            <div class="flex justify-content-between mb-2">
              <as-title :title="$t('reporting.page_title')" :size="1" style="flex:1;" />
              <Button v-show="!editMode && isAdmin" @click="editMode = true" icon="pi pi-pencil"
                class="p-button-sm p-button-rounded button-default reversed" />
              <Button id="power-bi-report-minimize-button" v-show="powerBiFullScreen" @click="powerBiFullScreen = false"
                icon="pi pi-window-minimize" class="p-button-sm p-button-rounded button-default reversed"
                style="margin-left:10px;" />
              <Button v-show="!powerBiFullScreen" @click="powerBiFullScreen = true" icon="pi pi-window-maximize"
                class="p-button-sm p-button-rounded button-default reversed" style="margin-left:10px;" />
            </div>
            <as-block shadow gutters="40px 20px">
              <div id="power-bi-report" v-show="!editMode" v-bind:class="{ 'fullscreen': powerBiFullScreen }"
                ref="powerBiContainer" style="height: 850px;">
              </div>
              <div v-show="editMode" class="p-float-label">
                <InputText type="text" v-model="powerBiReportId" class="w-100" />
                <label>{{ $t("reporting.report_id_title") }}</label>
              </div>
            </as-block>
          </div>
        </div>
      </div>
    </as-container>
    <as-container>
      <div class="grid">
        <div class="col-12">
          <div>
            <div class="flex justify-content-between mb-2">
              <as-title :title="$t('reporting.page_archive_title')" :size="1" />
              <Button v-show="!editMode && isAdmin" @click="editMode = true" icon="pi pi-pencil"
                class="p-button-sm p-button-rounded button-default reversed" />
            </div>
            <as-block shadow>
              <div class="grid">
                <div class="xl:col-4 lg:col-5 col-12">
                  <as-fichiers-list :rubrique="rubrique" :editMode="editMode" @onDelete="deleteDocument"
                    @onUpload="uploadDocument" @onCategoryCreated="reloadRubrique" @onCategoryToReload="reloadRubrique"
                    @onSelect="getFileInfo" fileFilters="application/pdf" :isDownloadable="false" />
                </div>
                <div v-if="!isMobile()" class="xl:col-8 lg:col-7 col-12 reader" :class="{ 'reader-large': fileBlob }">
                  <iframe v-if="fileBlob" :src="fileBlob + '#view=fith'" frameborder="0"></iframe>
                  <ProgressSpinner v-else-if="isBusy" />
                </div>
                <div v-else class="xl:col-8 lg:col-7 col-12 reader" :class="{ 'reader-large': fileBlob }">
                  <PdfViewer v-if="fileBlob" :pdfUrl="fileBlob" />
                  <ProgressSpinner v-else-if="isBusy" />
                </div>
              </div>
            </as-block>
          </div>
        </div>
      </div>
      <div v-show="editMode" class="grid actions-tab align-items w-100 justify-content-center">
        <Button @click="savePowerBiReport()" icon="pi pi-save" class="button-default mr-2"
          :label="$t('form.submit_button')" iconPos="right">
        </Button>
        <Button @click="editMode = false" class="button-default" icon="pi pi-sign-out" iconPos="right"
          :label="$t('cms_page.quit_edit_mode')">
        </Button>
      </div>
    </as-container>
  </div>
</template>
  
<script>
// @ is an alias to /src
import AsContainer from "@/components/Containers/AsContainer.vue";
import AsBlock from "@/components/Containers/AsBlock.vue";
import AsTitle from "@/components/Elements/AsTitle.vue";
import AsFichiersList from "@/components/Elements/AsFichiersList.vue";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import InputText from "primevue/inputtext";

import rubriqueMixins from "@/mixins/rubriqueMixins";
import roleMixins from "@/mixins/roleMixins";
import localeChangeDetectionMixins from "@/mixins/localeChangeDetectionMixins";
import fichiersServiceApi from "@/services/fichiersServiceApi";
import constanteServiceApi from "../services/constanteServiceApi";
import apiEventTypes from "../constantes/apiEventTypes";

import rubriques from "@/constantes/rubriques.js";
import { bufferToBlobUrl, isMobile } from "../util";
import { getTinyMceConfig } from "../config/tinymce.config";

import PdfViewer from "@/components/Pdf/PdfViewer.vue";
import { service, factories, models } from "powerbi-client";

export default {
  name: "Reporting",
  components: {
    AsContainer,
    AsBlock,
    AsTitle,
    AsFichiersList,
    Button,
    ProgressSpinner,
    PdfViewer,
    InputText
  },
  data() {
    return {
      editMode: false,
      fileBlob: undefined,
      base64: undefined,
      isBusy: false,
      allFiles: [],
      configTinyMce: getTinyMceConfig(),
      selectedLocale: this.$i18n.locale,
      powerBiReportId: '',
      powerBiFullScreen: false
    };
  },
  mixins: [
    rubriqueMixins(rubriques.reporting),
    roleMixins,
    localeChangeDetectionMixins,
    fichiersServiceApi
  ],
  watch: {
    rubrique() {
      if (
        this.rubrique &&
        this.rubrique.categories &&
        this.rubrique.categories.length > 0
      ) {
        this.allFiles = [];
        if (this.allFiles.length === 0) {
          this.fileBlob = undefined;
        }
        this.rubrique.categories.forEach((categorie) => {
          this.mapCategory(categorie);
        });

        let sortedFiles = this.allFiles;
        sortedFiles.sort((a, b) => b.id - a.id);
        if (this.fileBlob === undefined && sortedFiles.length > 0) {
          this.getFileInfo(sortedFiles[0].externalId);
        }
      }
    },
  },
  emits: ["onLocaleChange"],
  beforeMount() {
  },
  mounted() {
    this.getPowerBiReport();
    window.addEventListener('keydown', (e) => {
      if (e && e.key === 'Escape') {
        this.powerBiFullScreen = false
      }
    });
  },
  methods: {
    uploadDocument() {
      this.reloadRubrique();
    },
    deleteDocument() {
      this.reloadRubrique();
    },
    onLocaleChange() {
      this.reloadRubrique();
      this.$emit("onLocaleChange");
    },
    async getFileInfo(externalId) {
      if (this.isBusy) return;
      this.isBusy = true;
      this.fileBlob = undefined;
      try {
        const fichier = await this.downloadFile(externalId);
        this.fileBlob = bufferToBlobUrl(fichier, "application/pdf");
      } catch (error) {
        console.log(error)
      }
      this.isBusy = false;
    },

    mapCategory(category) {
      if (category.fichiers) {
        this.allFiles = this.allFiles.concat(category.fichiers);
      }
      if (category.children) {
        return this.mapCategory(category.children);
      }
    },

    isMobile() {
      return isMobile({ tablet: true, featureDetect: true });
    },

    getPowerBiReport() {
      constanteServiceApi
        .getPowerBiEmbedInfos()
        .then((result) => {
          this.powerBiReportId = result.reportId
          if (result && result.status !== 200) {
            const { accessToken, embedUrl } = result
            const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
            const embedConfiguration = {
              type: "report",
              tokenType: models.TokenType.Embed,
              accessToken,
              embedUrl,
              id: this.powerBiReportId
            };
            powerbi.embed(this.$refs.powerBiContainer, embedConfiguration);
          }
          return Promise.resolve();
        })
        .catch((err) => {
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_ERROR, {
              detail: {
                title: this.$t("popup_messages.default_error_title"),
                message: this.$t("reporting.power_bi_error_message"),
              },
            })
          );
          return Promise.reject(err);
        });
    },

    savePowerBiReport() {
      constanteServiceApi
        .saveByKey("POWER_BI_REPORT_ID", this.powerBiReportId)
        .then(() => {
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_title"),
                message: this.$t("popup_messages.success_message"),
              },
            })
          );
          this.getPowerBiReport()
          return Promise.resolve();
        })
        .catch((err) => {
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_ERROR, {
              detail: {
                title: this.$t("popup_messages.default_error_title"),
                message: this.$t("popup_messages.default_error_message"),
              },
            })
          );
          return Promise.reject(err);
        });
    },
  },
};
</script>
  
<style lang="scss" scoped>
#power-bi-report.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: auto !important;
}

#power-bi-report-minimize-button {
  position: fixed;
  top: 10px;
  right: 20px;
  z-index: 101;
}

.reader {
  justify-content: center;
  display: flex;
  align-items: center;

  &.reader-large {
    min-height: 68vh;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.quit-button-container {
  position: sticky;
  bottom: 0;
  pointer-events: none;

  .p-button {
    pointer-events: fill;
  }
}

@media (max-width: 425px) {

  .col-3,
  .col-9 {
    width: 100%;
  }

  .reader.reader-large {
    min-height: 10vh;
  }
}
</style>
  