export default {
  props: {
    modelValue: null,
  },
  emits: ["update:modelValue"],
  methods: {
    onUpdate(updatedData) {
      this.$emit("update:modelValue", updatedData);
    },
  },
};
