import { createStore } from "vuex";
import { config } from "./modules/config";
import { user } from "./modules/user";
import { languages } from "./modules/languages";
import { fileProgress } from "./modules/fileProgress";

export default createStore({
  modules: {
    config: config,
    user: user,
    languages: languages,
    fileProgress: fileProgress,
  },
});
