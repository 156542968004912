import referentialsServiceApi from "../../services/referentialsServiceApi";

export const user = {
  state: {
    userInformations: {},
  },
  getters: {
    getUser(state) {
      return state.userInformations;
    },
    getRole(state) {
      return state.userInformations.role;
    },
  },
  mutations: {
    setUserInformations: (state, value) => {
      state.userInformations = value;
    },
  },
  actions: {
    async getUserFromApi(store) {
      return referentialsServiceApi
        .getUserInfos()
        .then((result) => {
          store.commit("setUserInformations", result);
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
  },
};
