<template>
  <div id="agenda-detail-form" class="p-fluid">
    <div class="grid grid-nogutter mb-2">
      <div
        :class="
          currentIsoCodeLocale !== selectedLocale
            ? 'lg:col-6 md:col-10 sm:col-12'
            : 'lg:col-10 md:col-10 sm:col-10'
        "
      >
        <div class="p-float-label">
          <InputText
            type="text"
            v-model="v$.titre.$model"
            :class="{ 'p-invalid': v$.titre.$invalid && submitted }"
            @change="onChange"
          />
          <label>{{ $t("agenda_form.title_field") }}</label>
          <as-input-error :errors="v$.titre.$silentErrors" v-show="submitted" />
        </div>
      </div>
      <div class="lg:col-2 md:col-2 sm:col-2">
        <img class="flag" :src="getFlag" />
      </div>
      <div class="lg:col-4 md:col-6" v-if="currentIsoCodeLocale !== selectedLocale">
        <ProgressSpinner strokeWidth="5" v-if="beingTranslated" />
        <Button
          v-if="!beingTranslated"
          :label="$t('form.translate')"
          class="button-primary ml-2"
          @click="translateTo"
        />
      </div>
    </div>

    <div class="field col-12">
      <div class="p-float-label">
        <Textarea
          type="text"
          :autoResize="true"
          :rows="6"
          v-model="v$.description.$model"
          :class="{ 'p-invalid': v$.description.$invalid && submitted }"
          @change="onChange"
        />
        <label>{{ $t("agenda_form.description_field") }}</label>
      </div>
      <as-input-error
        :errors="v$.description.$silentErrors"
        v-show="submitted"
      />
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import { required, minLength } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import AsInputError from "./AsInputError.vue";

export default {
  name: "AgendaDetailForm",
  components: {
    InputText,
    ProgressSpinner,
    Button,
    Textarea,
    AsInputError,
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return { titre: "", description: "", idLangue: 1 };
      },
    },
    submitted: Boolean,
    beingTranslated: Boolean,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      titre: this.detail.titre,
      description: this.detail.description,
      currentIsoCodeLocale: "",
      selectedLocale: this.$i18n.locale,
    };
  },
  mounted() {
    this.currentIsoCodeLocale = this.getCurrentIsoCode();
  },
  validations() {
    return {
      titre: { required, minLength: minLength(1) },
      description: { required, minLength: minLength(1) },
    };
  },
  computed: {
    ...mapGetters({
      languages: "getLanguages",
    }),
    getFlag() {
      return require(`../../assets/images/flags/${this.getCurrentIsoCode()}.svg`);
    },
  },
  emits: ["onChange", "onTranslate"],
  methods: {
    onChange() {
      this.$emit("onChange", {
        titre: this.titre,
        description: this.description,
        idLangue: this.detail.idLangue,
      });
    },
    translateTo() {
      const langue = this.languages.find(
        (el) => el.id === this.detail.idLangue
      );
      this.$emit("onTranslate", langue);
    },
    getCurrentIsoCode() {
      return this.languages.find((el) => el.id === this.detail.idLangue)
        .codeIso;
    },
  },
};
</script>
<style lang="scss" scoped>
.flag {
  width: 100%;
  max-height: 40px;
}

#agenda-detail-form {
  .p-progress-spinner {
    height: 40px;
  }
}
</style>
