<template>
  <div id="copyright">
    <span v-if="config.env === 'preprod'"
      >Copyright {{ year }} | Tous droits réservés - {{ config.env }}</span
    >
    <span v-else>Copyright {{ year }} | Tous droits réservés</span>
  </div>
</template>

<script>
import config from "../../config";
export default {
  name: "Copyright",
  data() {
    return {
      year: new Date().getFullYear(),
      config: config,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/sizing.scss";

#copyright {
  background-color: $copyright_bg;
  color: $light_font;
  height: $copyright_height;
  width: 100%;
  padding-left: $copyright_padding;
  padding-right: $copyright_padding;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
</style>
