import fr from "../locales/primevue/fr.json";
import en from "../locales/primevue/en.json";
import localeUtils from "../locales/utils";

export default {
    methods: {
        changeCurrentLocale(locale) {
            localeUtils.changeLocale(locale);
            this.changePrimeVueLocale(locale);
        },
        changePrimeVueLocale(locale) {
            if (locale.includes("fr")) {
                this.$primevue.config.locale = fr;
            } else {
                this.$primevue.config.locale = en;
            }

            if (this.$primevue.config.locale) {
                if (!this.$primevue.config.locale.aria) {
                    this.$primevue.config.locale.aria = {}
                }
                this.$primevue.config.locale.aria['star'] = '1 star'
                this.$primevue.config.locale.aria['stars'] = '{star} stars'
                this.$primevue.config.locale.aria['slideNumber'] = ''
                this.$primevue.config.locale.aria['pageLabel'] = ''
            }
        },
    },
};
