<template>
  <as-cms-page-layout id="agenda" :idRubrique="idRubrique" v-model="editMode" :fichiers="fichiers" :articles="articles"
    :rubrique="rubrique" :hideEnterEditMode="true" :customAction1Label="$t('agenda.add_event_button')"
    :customAction1Icon="'pi-plus'" @customAction1="openEventModal" @reloadArticles="reloadArticles"
    @reloadRubrique="reloadRubrique">
    <template #headerPage>
      <div>
        <as-article-title v-model="rubriqueTitle" :editMode="editMode" @onEnterEditMode="enterEditMode"
          :isDeletable="false" :isEditable="false" id="agenda-title" :key="rubriqueTitle" />

        <as-block gutters="15px" shadow class="events">
          <event-block v-for="event in events" :event="event" :key="event.id" :editMode="editMode"
            @onDelete="handleOnDeleteEvent(event.id)" @onEdit="onUpdateEvent(event.id)" />
          <div v-if="events.length === 0" class="flex justify-content-center">
            <as-title :size="5" :title="$t('agenda.no_event_available')"></as-title>
          </div>
          <Paginator :first="currentPage" :rows="numberPerPage" :totalRecords="totalEvents"
            @page="onChangePage($event)" />
        </as-block>
        <div class="flex justify-content-end mt-4 mb-4">
          <Button v-show="!editMode && !showPastEvents" @click="showExpiredEvents" icon="pi pi-chevron-circle-right"
            iconPos="right" class="button-default last-events-button" :label="$t('agenda.expired_events_button')">
          </Button>
          <Button v-show="!editMode && showPastEvents" @click="showNextEvents" icon="pi pi-chevron-circle-right"
            iconPos="right" class="button-default last-events-button" :label="$t('agenda.current_events_button')">
          </Button>
        </div>
      </div>
    </template>
  </as-cms-page-layout>
  <as-confirm-modal :isVisible="showConfirmModal" :modalMessage="modalMessage" @onConfirm="confirm" @onCancel="cancel" />
  <as-modal v-if="isAdmin" :isVisible="showAddEventModal">
    <agenda-form v-if="showAddEventModal" :isBusy="isBusy" @onClose="onHideAddEventModal" @onSubmit="createEvent" />
  </as-modal>
  <as-modal v-if="isAdmin" :isVisible="showUpdateEventModal">
    <agenda-form v-if="showUpdateEventModal" :isBusy="isBusy" :update="true" :event="eventToUpdate"
      @onClose="onHideUpdateEventModal" @onSubmit="updateEvent" />
  </as-modal>
</template>

<script>
// @ is an alias to /src
import AsCmsPageLayout from "@/components/Layout/AsCmsPageLayout.vue";
import AsArticleTitle from "@/components/Elements/AsArticleTitle.vue";
import AsTitle from "@/components/Elements/AsTitle.vue";
import AsConfirmModal from "@/components/Modals/AsConfirmModal.vue";
import AsModal from "@/components/Modals/AsModal.vue";
import AsBlock from "@/components/Containers/AsBlock.vue";
import EventBlock from "@/components/Agenda/EventBlock.vue";
import AgendaForm from "@/components/Forms/AgendaForm.vue";
import Button from "primevue/button";
import Paginator from "primevue/paginator";
import agendasService from "../services/agendasServiceApi.js";
import rubriques from "../constantes/rubriques";
import articlesMixins from "../mixins/articlesMixins";
import rubriqueMixins from "../mixins/rubriqueMixins";
import roleMixins from "../mixins/roleMixins.js";
import apiEventTypes from "../constantes/apiEventTypes";
import fichiersServiceApi from "../services/fichiersServiceApi";
import { mapGetters } from "vuex";

export default {
  name: "Agenda",
  components: {
    AsCmsPageLayout,
    AsConfirmModal,
    AsArticleTitle,
    AsTitle,
    AsBlock,
    AsModal,
    EventBlock,
    Button,
    Paginator,
    AgendaForm,
  },
  data() {
    return {
      rubriqueTitle: this.$t("agenda.page_title"),
      editMode: false,
      isBusy: false,
      showConfirmModal: false,
      showAddEventModal: false,
      showUpdateEventModal: false,
      modalMessage: "",
      eventToDelete: NaN,
      eventToUpdateId: NaN,
      eventToUpdate: undefined,
      events: [],
      currentPage: 0,
      numberPerPage: 5,
      totalEvents: 0,
      showPastEvents: false,
      fichiers: [],
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    ...mapGetters({
      driveConfig: "getConfig",
      user: "getUser",
    }),
  },
  watch: {
    locale() {
      this.rubriqueTitle = this.$t("agenda.page_title");
      this.getEvents(this.currentPage, this.numberPerPage, this.showPastEvents);
    },
  },
  mounted() {
    this.skeletonAgendas = new Array(this.numberPerPage);
    this.getEvents(this.currentPage, this.numberPerPage, this.showPastEvents);
  },
  mixins: [
    articlesMixins(rubriques.agenda),
    rubriqueMixins(rubriques.agenda),
    roleMixins,
    fichiersServiceApi,
  ],
  methods: {
    enterEditMode() {
      this.editMode = true;
    },
    showExpiredEvents() {
      this.showPastEvents = true;
      this.currentPage = 0;
      this.$nextTick(() => {
        this.getEvents(
          this.currentPage,
          this.numberPerPage,
          this.showPastEvents
        );
      });
    },
    showNextEvents() {
      this.showPastEvents = false;
      this.currentPage = 0;
      this.$nextTick(() => {
        this.getEvents(
          this.currentPage,
          this.numberPerPage,
          this.showPastEvents
        );
      });
    },
    async onUpdateEvent(eventId) {
      this.eventToUpdateId = eventId;
      this.eventToUpdate = await agendasService.getAgenda(eventId);
      this.showUpdateEventModal = true;
    },
    handleOnDeleteEvent(eventId) {
      this.eventToDelete = eventId;
      this.modalMessage = this.$t("confirm_modal.delete_message");
      this.showConfirmModal = true;
    },
    confirm() {
      this.isBusy = true;
      agendasService
        .deleteAgenda(this.eventToDelete)
        .then(() => {
          this.getEvents(
            this.currentPage,
            this.numberPerPage,
            this.showPastEvents
          );
          this.eventToDelete = NaN;
          this.showConfirmModal = false;
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_delete_title"),
                message: this.$t("popup_messages.success_delete_message"),
              },
            })
          );
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    cancel() {
      this.eventToDelete = NaN;
      this.showConfirmModal = false;
    },
    getEvents(page, numberPerPage, past) {
      agendasService.getAgendas(page, numberPerPage, past).then((result) => {
        this.events = result.data;
        this.totalEvents = result.count;
      });
    },
    onChangePage(event) {
      if (event.page !== this.currentPage) {
        this.getEvents(event.page, event.rows, this.showPastEvents);
      }
    },
    openEventModal() {
      this.showAddEventModal = true;
    },
    onHideAddEventModal() {
      this.showAddEventModal = false;
    },
    onHideUpdateEventModal() {
      this.eventToUpdateId = NaN;
      this.showUpdateEventModal = false;
    },
    async createEvent(agenda) {
      this.isBusy = true;
      agendasService
        .saveAgenda(agenda)
        .then((savedAgenda) => {
          this._uploadAgendaFiles(
            agenda,
            savedAgenda.id
          );
          if (this.showPastEvents) {
            this.showPastEvents = false;
          }
          this.getEvents(this.currentPage, this.numberPerPage, false);
          this.showAddEventModal = false;
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_title"),
                message: this.$t("popup_messages.success_message"),
              },
            })
          );
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    async updateEvent(agenda) {
      this.isBusy = true;
      agendasService
        .updateAgenda(this.eventToUpdate.id, agenda)
        .then(() => {
          this._uploadAgendaFiles(
            agenda,
            this.eventToUpdate.id
          );
          if (this.showPastEvents) {
            this.showPastEvents = false;
          }
          this.getEvents(this.currentPage, this.numberPerPage, false);

          this.showUpdateEventModal = false;
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_title"),
                message: this.$t("popup_messages.success_message"),
              },
            })
          );
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    async _uploadAgendaFiles(el, entityId) {
      const files = this._getFilesToUpload(el)
      this.uploadFiles(files, "agenda", entityId, +this.user.idLdap, () => {
        this.getEvents(this.currentPage, this.numberPerPage, false)
      })
    },

    _getFilesToUpload(el) {
      const files = []
      if (el.fileToUpload) {
        files.push({ file: el.fileToUpload })
      }
      return files;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors.scss";
@import "../assets/styles/sizing.scss";

#agenda {
  #agenda-title {
    color: $light_font;
  }

  .events {
    overflow: auto;
  }

  .last-events-button {
    text-transform: uppercase;
    font-weight: bold;
  }
}
</style>
