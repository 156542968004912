import referentialsServiceApi from "../../services/referentialsServiceApi";

export const languages = {
  state: {
    languages: [],
  },
  getters: {
    getLanguages(state) {
      return state.languages;
    },
  },
  mutations: {
    setLanguage: (state, value) => {
      state.languages = value;
    },
  },
  actions: {
    getLanguagesReferentialsFromApi(store) {
      referentialsServiceApi.getLanguages().then((result) => {
        store.commit("setLanguage", result);
      });
    },
  },
};
