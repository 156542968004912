export const fileProgress = {
    state: {
        uploadedFiles: [],      // { progress : number, filename: string, size: number, step: number}
        downloadedFiles: [],    // { progress : number, filename: string, documentId: string}
    },
    mutations: {
        UPDATE_UPLOADED_FILES(state, payload) {
            state.uploadedFiles = payload
        },
        UPDATE_DOWNLOADED_FILES(state, payload) {
            state.downloadedFiles = payload
        }
    },
    actions: {
        isAlreadyDownloading: (documentId) => fileProgress.state.downloadedFiles.map(e => e.documentId).includes(documentId),
        setProgressUpload(context, progressUploadData) {
            const newUploadedFiles = [...context.state.uploadedFiles]
            progressUploadData.forEach(e => {
                const indexOf = newUploadedFiles.findIndex(f => f.filename === e.filename)
                if (indexOf >= 0) {
                    newUploadedFiles[indexOf].progress = e.progress
                    newUploadedFiles[indexOf].step = e.step
                } else {
                    newUploadedFiles.push(e)
                }

                if (e.progress >= 100 && e.step !== 1) {
                    setTimeout(() => {
                        fileProgress.actions.removeDocument(context, { filename: e.filename, isUpload: true })
                    }, 3000)
                }
            })
            context.commit('UPDATE_UPLOADED_FILES', newUploadedFiles)
        },
        setProgressDownload(context, progressDownloadData) {
            const newDownloadedFiles = [...context.state.downloadedFiles]
            progressDownloadData.forEach(e => {
                const indexOf = newDownloadedFiles.findIndex(f => f.filename === e.filename)
                indexOf >= 0 ?
                    newDownloadedFiles[indexOf].progress = e.progress :
                    newDownloadedFiles.push(e)

                if (e.progress >= 100) {
                    setTimeout(() => {
                        fileProgress.actions.removeDocument(context, { filename: e.filename, isUpload: false })
                    }, 200)
                }
            })
            context.commit('UPDATE_DOWNLOADED_FILES', newDownloadedFiles)
        },
        removeDocument(context, { filename, isUpload }) {
            const newFiles = isUpload ? [...context.state.uploadedFiles] : [...context.state.downloadedFiles]
            const indexOf = newFiles.findIndex(f => f.filename === filename)
            newFiles.splice(indexOf, 1)
            context.commit(isUpload ? 'UPDATE_UPLOADED_FILES' : 'UPDATE_DOWNLOADED_FILES', newFiles)
        }
    },
    getters: {}
};
