<template>
  <div class="safety-card">
    <div class="safety-card__header">
      <div class="profile-img">
        <img :src="userPhoto" />
      </div>
      <div class="safety-card__header-right">
        <img :src="entreprise.fichierLogo.url" alt="profile" />
        <img :src="entreprise.fichierDrapeau.url" class="drapeau-img" />
      </div>
    </div>
    <div class="safety-card__content">
      <div class="safety-card__content-name">
        <strong> {{ user.prenom + " " + user.nom }} </strong>
        <p>{{ user.email }}</p>
      </div>
      <div class="safety-card__content-icons">
        <div>
          <a :href="'tel:' + user.mobile">
            <img :src="mobilIcon" alt="mobil" />
            <p>{{ user.mobile }}</p>
          </a>
        </div>
        <div>
          <a :href="'mailto:' + user.email">
            <img :src="emailIcon" alt="email" />
            <p>{{ $t("community.email") }}</p>
          </a>
        </div>
        <div>
          <a
            :href="
              user.workchatLink
                ? user.workchatLink
                : 'https://adeo.workplace.com/groups/579248309513400/'
            "
            target="_blank"
          >
            <img :src="workchatIcon" alt="workchat" />
            <p>{{ $t("community.workchat") }}</p>
          </a>
        </div>
      </div>
    </div>
    <div class="admin-actions" v-show="editable">
      <Button
        @click="onUpdate()"
        icon="pi pi-pencil"
        class="p-button p-button-rounded button-default"
      />
      <Button
        @click="onDelete()"
        icon="pi pi-times"
        class="p-button p-button-rounded p-button-danger"
      />
    </div>
  </div>
  <as-confirm-modal
    :isVisible="showConfirmModal"
    :modalMessage="$t('confirm_modal.delete_message')"
    @onConfirm="confirm"
    @onCancel="cancel"
  />
</template>

<script>
import Button from "primevue/button";
import AsConfirmModal from "@/components/Modals/AsConfirmModal.vue";
import { bufferToJpegBase64 } from "@/util";

export default {
  name: "SafetyCardBlock",
  components: {
    Button,
    AsConfirmModal,
  },
  props: {
    user: Object,
    entreprise: Object,
    editable: Boolean,
  },
  data() {
    return {
      showConfirmModal: false,
      bgImg: require("../../assets/images/fond-fiche.jpg"),
      noUserImg: require("../../assets/images/no-user-image.jpg"),
      mobilIcon: require("../../assets/images/icons/mobil.png"),
      emailIcon: require("../../assets/images/icons/email.png"),
      workchatIcon: require("../../assets/images/icons/workchat.png"),
    };
  },
  computed: {
    backgroundStyle() {
      return { backgroundImage: "url(" + this.bgImg + ")" };
    },
    userPhoto() {
      if (this.user.photo && this.user.photo.data.length) {
        return bufferToJpegBase64(this.user.photo.data);
      } else {
        return this.noUserImg;
      }
    },
  },
  emits: ["onUpdate", "onDelete"],
  methods: {
    onUpdate() {
      this.$emit("onUpdate", this.user.idLdap);
    },
    onDelete() {
      this.showConfirmModal = true;
    },
    confirm() {
      this.$emit("onDelete", this.user.idLdap);
      this.cancel();
    },
    cancel() {
      this.showConfirmModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/fonts.scss";
.safety-card {
  flex: 1 0 21%;
  max-width: 23%;
  height: 220px;
  margin: 20px;
  background: white;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  box-shadow: 0px 0px 20px 0px $shadow_color;
  border-radius: 20px;

  &__header {
    display: flex;
    height: 50px;
    padding: 5px 10px;
    background: $default_light_color;
    border-radius: 20px 20px 0 0;

    &-right {
      display: flex;
      margin-left: auto;
      margin-right: 10px;
      img {
        height: 100%;
        width: 100%;
        margin-left: 10px;
        object-fit: cover;
        max-width: 40px;;
      }
    }
  }

  &__content {
    padding: 20px;
    padding-top: 30px;
    &-icons {
      display: flex;
      flex-wrap: wrap;
      font-size: 11px;
      margin-top: 20px;
      > div {
        margin: auto;
        line-height: 15px;
        text-align: center;
        a {
          text-decoration: none;
          color: inherit;
        }
        img {
          height: 40px;
          width: 40px;
        }

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  .profile-img {
    background-color: white;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    border-radius: 40px;
    border: solid 3px $safety_card_border_blue;
    overflow: hidden;
  }

  &:hover {
    .admin-actions {
      display: block;
    }
  }
  .admin-actions {
    display: none;
    background: $light_bg;
    border-radius: 30px;
    box-shadow: 0px 0px 10px 0px $shadow_color;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    right: 5px;
    bottom: 5px;
    .p-button:not(:last-child) {
      margin-right: 2px;
    }
  }
}

@media screen and (max-width: 1360px) {
  .safety-card {
    flex: 1 0 44%;
    max-width: 44%;
  }
}
@media screen and (max-width: 696px) {
  .safety-card {
    max-width: 100%;
  }
}
</style>
