import articlesServiceApi from "../services/articlesServiceApi";

export default (idRubrique) => ({
  data() {
    return {
      idRubrique: NaN,
      articles: [],
    };
  },
  created() {
    this.idRubrique = idRubrique;
  },
  beforeMount() {
    this.reloadArticles();
  },
  methods: {
    reloadArticles() {
      articlesServiceApi.getArticles(this.idRubrique).then((result) => {
        this.articles = result;
      });
    },
  },
});
