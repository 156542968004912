<template>
  <as-form id="agenda-form" :title="update ? $t('agenda_form.title_update') : $t('agenda_form.title')" :isBusy="isBusy"
    @onClose="onClose" @onSubmit="onSubmit(!v$.$invalid)">
    <template #body>
      <div class="col">
        <div class="p-fluid grid">
          <div class="md:col-9 col-12">
            <div class="p-fluid grid">
              <div class="field col-6">
                <div class="p-float-label">
                  <InputText type="text" v-model="v$.libelle.$model"
                    :class="{ 'p-invalid': v$.libelle.$invalid && submitted }" />
                  <label>{{ $t("agenda_form.label_field") }}</label>
                </div>
                <as-input-error :errors="v$.libelle.$silentErrors" v-show="submitted" />
              </div>
              <div class="field col-6">
                <div class="p-float-label">
                  <Calendar v-model="dates" selectionMode="range" :dateFormat="$t('form.date_format')"
                    :class="{ 'p-invalid': v$.dates.$invalid && submitted }" />
                  <label>{{ $t("agenda_form.date_field") }}</label>
                </div>
                <as-input-error :errors="v$.dates.$silentErrors" v-show="submitted" />
              </div>
            </div>
            <div class="p-fluid grid">
              <agenda-detail-form class="lg:col-6 col-12 agenda-detail p-p-0" v-for="(d, index) in details" :detail="d"
                :key="index + d.titre + d.description" :submitted="submitted" :beingTranslated="beingTranslated"
                @onChange="updateDetail($event, index)" @onTranslate="translateTo" />
            </div>
          </div>

          <div class="md:col-3 col-12">
            <img class="image-event" v-if="update" :src="fichier && !image ? fichier : image" />
            <img class="image-event" v-if="!update && image" :src="image" />
            <div v-if="!image && !fichier">
              {{ $t("agenda_form.no_image") }}
            </div>
            <FileUpload class="button-default file-upload" mode="basic" :customUpload="true"
              @select="onSelectedEventImage" accept="image/*" :chooseLabel="$t('agenda_form.choose_img_button')"
              :multiple="false" />
            <Button v-if="image || fichier" :label="$t('agenda_form.delete_image')" class="button-primary mt-2"
              @click="deleteImage" />
          </div>
        </div>
      </div>
    </template>
  </as-form>
</template>

<script>
import useVuelidate from "@vuelidate/core";

import AsForm from "./AsForm.vue";
import AsInputError from "./AsInputError.vue";
import AgendaDetailForm from "./AgendaDetailForm.vue";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";
import { required, minLength } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import fichiersServiceApi from "../../services/fichiersServiceApi";
import { bufferToBlobUrl } from "../../util";
import { translate } from "../../services/googleTranslateService";
import moment from "moment";

export default {
  name: "AgendaForm",
  components: {
    AsForm,
    AsInputError,
    Calendar,
    AgendaDetailForm,
    InputText,
    FileUpload,
    Button
  },
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    isBusy: Boolean,
    update: Boolean,
    event: Object,
  },
  data() {
    return {
      submitted: false,
      dateDebut: this.event ? new Date(this.event.dateDebut) : undefined,
      dateFin:
        this.event && this.event.dateFin
          ? new Date(this.event.dateFin)
          : undefined,
      dates: [],
      libelle: this.event ? this.event.libelle : "",
      details: this.event ? this.event.details : [],
      fileToUpload: undefined,
      fileToDelete: undefined,
      image: undefined,
      fichier: undefined,
      beingTranslated: false,
    };
  },
  mixins: [fichiersServiceApi],
  computed: {
    ...mapGetters({
      languages: "getLanguages",
    }),
    availableLocales() {
      return this.$i18n.availableLocales.filter((el) => {
        return el.length === 2;
      });
    },
  },
  watch: {
    languages() {
      this.initDetails();
    },
  },
  validations() {
    return {
      dates: { required, minLength: minLength(1) },
      libelle: { required },
    };
  },
  emits: ["onClose", "onSubmit"],
  beforeMount() {
    if (this.dateDebut) this.dates.push(moment(this.dateDebut).toDate());
    if (this.dateFin) this.dates.push(moment(this.dateFin).toDate());
    this.initDetails();
  },
  methods: {
    async initDetails() {
      if (this.languages && this.languages.length > 0 && !this.update) {
        this.availableLocales.forEach((loc) => {
          this.details.push({
            description: "",
            titre: "",
            idLangue: this.languages.find((l) => l.codeIso === loc).id,
          });
        });
      }

      this.details.sort((a, b) => b.idLangue - a.idLangue);

      if (
        this.update &&
        this.event &&
        this.event.image &&
        this.event.image.externalId
      ) {
        try {
          const fichier = await this.downloadFile(this.event.image.externalId);
          this.fichier = bufferToBlobUrl(fichier);
        } catch (error) {
          console.log(error)
        }
      }
    },
    onClose() {
      this.$emit("onClose");
    },
    onSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      } else {
        this.$emit("onSubmit", {
          dateDebut: moment(this.dates[0]).format("YYYY-MM-DD"),
          dateFin: moment(this.dates[1]).format("YYYY-MM-DD"),
          libelle: this.libelle,
          details: this.details,
          fileToUpload: this.fileToUpload,
          fileToDelete: this.fileToUpload && this.event && this.event.image ? this.event.image : this.fileToDelete,
        });
      }
    },
    updateDetail(event, index) {
      this.details[index] = event;
    },
    onSelectedEventImage(event) {
      this.fileToUpload = event.files[0];
      this.image = event.files[0].objectURL;
    },
    async translateTo(target) {
      this.beingTranslated = true;
      const primaryLangueAccident = this.details.find(
        (e) => e.idLangue !== target.id
      );
      const stringsToTranslate = [
        primaryLangueAccident.titre,
        primaryLangueAccident.description,
      ];

      const translatedData = await translate(
        stringsToTranslate,
        target.codeIso
      );

      if (stringsToTranslate.length === translatedData.length) {
        const newDetails = [...this.details];
        const indexOfDetails = newDetails.findIndex(
          (e) => e.idLangue === target.id
        );
        const details = newDetails[indexOfDetails];
        details.titre = translatedData.splice(0, 1)[0];
        details.description = translatedData.splice(0, 1)[0];
        this.details = newDetails;
      }

      this.beingTranslated = false;
    },
    deleteImage() {
      if (!this.update) {
        this.fileToUpload = undefined
      } else {
        this.fichier = undefined
        if (this.event && this.event.image) {
          this.fileToDelete = this.event.image
        }
      }
      this.image = undefined
    }
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";

#agenda-form {
  .p-fluid .p-fileupload .p-button {
    width: 100% !important;
  }

  .image-event {
    width: 100%;
  }
}
</style>
