<template>
  <div id="objectives-block">
    <div class="flex justify-content-center" id="objectives-block__icon">
      <as-icon
        :source="iconBlock"
        size="80"
        respectRatio
        class="mb-4 bg-white"
      />
    </div>
    <div class="flex justify-content-center">
      <as-title
        :title="$t('home.objectives_title')"
        :size="2"
        class="color-white"
      />
    </div>

    <div
      class="grid mt-5 flex align-items-stretch vertical-container"
      style="text-align: center"
    >
      <div class="col-12 lg:col-4">
        <as-block gutters="40px" class="bg-secondary-sliced">
          <as-icon :source="simplifier" :size="80" />
          <as-title :title="$t('home.objectives_subtitle1')" :size="3" />
          <p>
            {{ $t("home.objectives_text1") }}
          </p></as-block
        >
      </div>
      <div class="col-12 lg:col-4">
        <as-block gutters="40px" class="bg-primary-sliced">
          <as-icon :source="informer" :size="80" />
          <as-title :title="$t('home.objectives_subtitle2')" :size="3" />
          <p>
            {{ $t("home.objectives_text2") }}
          </p></as-block
        >
      </div>
      <div class="col-12 lg:col-4">
        <as-block gutters="40px" class="bg-tertiary-sliced">
          <as-icon :source="adapter" :size="80" />
          <as-title :title="$t('home.objectives_subtitle3')" :size="3" />
          <p>
            {{ $t("home.objectives_text3") }}
          </p>
        </as-block>
      </div>
    </div>
  </div>
</template>

<script>
import AsTitle from "../Elements/AsTitle.vue";
import AsIcon from "../Elements/AsIcon.vue";
import AsBlock from "../Containers/AsBlock.vue";
export default {
  name: "ObjectivesBlock",
  components: {
    AsTitle,
    AsBlock,
    AsIcon,
  },
  data() {
    return {
      iconBlock: require("../../assets/images/icons/picto-home-objectifs.png"),
      informer: require("../../assets/images/icons/informer.svg"),
      adapter: require("../../assets/images/icons/adapter.svg"),
      simplifier: require("../../assets/images/icons/simplifier.svg"),
    };
  },
};
</script>

<style lang="scss">
#objectives-block {
  &__icon {
    > div {
      border-radius: 45px;
    }
  }
}
</style>
