<template>
  <as-modal :isVisible="isVisible" class="small">
    <as-title :size="5" :title="$t('confirm_modal.title')"></as-title>
    <p>{{ modalMessage }}</p>
    <div class="flex justify-content-end">
      <Button
        icon="pi pi-check"
        class="p-button-sm button-primary mt-2"
        :class="[{ 'mr-2': isCancelable }]"
        iconPos="right"
        :label="
          yesButtonMessage ? yesButtonMessage : $t('confirm_modal.yes_button')
        "
        @click="confirm"
      >
      </Button>
      <Button
        v-if="isCancelable"
        icon="pi pi-times"
        iconPos="right"
        class="p-button-sm button-default mt-2"
        :label="
          noButtonMessage ? noButtonMessage : $t('confirm_modal.no_button')
        "
        @click="cancel"
      >
      </Button>
    </div>
  </as-modal>
</template>

<script>
import AsModal from "@/components/Modals/AsModal.vue";
import AsTitle from "@/components/Elements/AsTitle.vue";
import Button from "primevue/button";

export default {
  name: "AsConfirmModal",
  components: {
    AsModal,
    AsTitle,
    Button,
  },
  props: {
    modalMessage: String,
    yesButtonMessage: String,
    noButtonMessage: String,
    isVisible: Boolean,
    isCancelable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["onConfirm", "onCancel"],
  methods: {
    confirm() {
      this.$emit("onConfirm");
    },
    cancel() {
      this.$emit("onCancel");
    },
  },
};
</script>
