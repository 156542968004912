<template>
  <div class="container" :style="backgroundStyles">
    <div class="content-container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "AsContainer",
  props: {
    backgroundImg: String,
  },
  computed: {
    backgroundStyles() {
      return {
        "background-image": this.backgroundImg ? this.backgroundImg : undefined,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/sizing.scss";

.container {
  padding-top: $container_block_padding;
  padding-bottom: $container_block_padding;
  width: 100%;
  display: flex;
  justify-content: center;

  .content-container {
    width: $sm_container_width;
  }
  @media screen and (min-width: 1360px) {
    .content-container {
      width: $xl_container_width;
    }
  }
}
</style>
