<template>
  <div class="grid accident-form">
    <div class="col-6" v-if="isEdit">
      <declaration-form
        ref="edited-declaration"
        :isEdit="isEdit"
        :declaration="declaration"
        @onEditChange="onEditChange"
      />
    </div>
    <div v-bind:class="[isEdit ? 'col-6' : 'col-12']">
      <div class="p-fluid grid accident-form">
        <as-title
          v-if="isEdit"
          class="title"
          :title="$t('accident_form.edit_title')"
          :size="5"
        />
        <div class="field col-12">
          <div class="p-float-label">
            <InputText
              type="text"
              v-model="v$.modelValue.titre.$model"
              :class="{
                'p-invalid': v$.modelValue.titre.$invalid && submitted,
              }"
              @update:modelValue="onChange($event, 'titre')"
            />
            <label>{{ $t("accident_form.title_field") }}</label>
          </div>
          <as-input-error
            :errors="v$.modelValue.titre.$silentErrors"
            v-show="submitted"
          />
        </div>
        <div class="field col-12">
          <div class="p-float-label">
            <Textarea
              type="text"
              :autoResize="true"
              v-model="v$.modelValue.description.$model"
              @update:modelValue="onChange($event, 'description')"
              :class="{
                'p-invalid': v$.modelValue.description.$invalid && submitted,
              }"
            />
            <label>{{ $t("accident_form.description_field") }}</label>
          </div>
          <as-input-error
            :errors="v$.modelValue.description.$silentErrors"
            v-show="submitted"
          />
        </div>
        <div class="field col-12">
          <div class="p-float-label">
            <Textarea
              type="text"
              :autoResize="true"
              v-model="v$.modelValue.causes.$model"
              @update:modelValue="onChange($event, 'causes')"
              :class="{
                'p-invalid': v$.modelValue.causes.$invalid && submitted,
              }"
            />
            <label>{{ $t("accident_form.causes_field") }}</label>
          </div>
          <as-input-error
            :errors="v$.modelValue.causes.$silentErrors"
            v-show="submitted"
          />
        </div>
        <div class="field col-11">
          <div
            v-for="(e, index) in [].constructor(mesureCount)"
            :key="index"
            class="p-float-label"
          >
            <Textarea
              type="text"
              :autoResize="true"
              v-model="mesureValues[index]"
              @update:modelValue="onChangeMesure($event, index)"
              :class="{
                'p-invalid': v$.modelValue.mesures.$invalid && submitted,
              }"
            />
            <label>{{ $t("accident_form.measures_field") }}</label>
          </div>
          <as-input-error
            :errors="v$.modelValue.mesures.$silentErrors"
            v-show="submitted"
          />
        </div>
        <div class="field col-1">
          <Button icon="pi pi-plus" @click="incrementMesure" />
        </div>
        <div class="field col-12">
          <AsFileUpload
            :fichiers="modelValue.filesToUpload"
            :maxFilesize="maxFileUpload"
            :chooseLabel="$t('declaration_accident_form.choose_button')"
            :multiple="true"
          />
        </div>
        <div class="field col-12">
          <as-drive-selector
            v-model="modelValue.fichiers"
            :availableFiles="availableFiles"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import vmodelMixins from "../../mixins/vmodelMixins";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import AsTitle from "../Elements/AsTitle.vue";
import AsFileUpload from "../Elements/AsFileUpload.vue";
import DeclarationForm from "./DeclarationForm.vue";

import AsInputError from "./AsInputError.vue";
import AsDriveSelector from "./AsDriveSelector";

import config from "../../config";

export default {
  name: "AccidentForm",
  components: {
    Button,
    InputText,
    Textarea,
    AsTitle,
    AsInputError,
    AsDriveSelector,
    AsFileUpload,
    DeclarationForm,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    submitted: Boolean,
    files: Array,
    mesureCount: Number,
    isEdit: Boolean,
    declaration: Object,
  },
  mixins: [vmodelMixins],
  computed: {
    availableFiles() {
      return this.files.map((el) => {
        return {
          titre: `${el.titre}`,
          id: el.id,
          icon: el.iconLink,
        };
      });
    },
  },
  data() {
    const mesures = {};
    if (this.modelValue && this.modelValue.mesures) {
      JSON.parse(this.modelValue.mesures).map((e, i) => {
        mesures[i] = e;
      });
    }
    return {
      maxFileUpload: config.max_file_upload,
      mesureValues: mesures,
    };
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue.mesures) {
          const mesures = JSON.parse(newValue.mesures);
          mesures.forEach((e, i) => (this.mesureValues[i] = e));
        }
      },
      deep: true,
    },
  },
  validations() {
    return {
      modelValue: {
        titre: { required },
        description: { required },
        causes: { required },
        mesures: { required },
      },
    };
  },
  emits: ["incrementMesure", "onDeclarationDataChange"],
  methods: {
    onChange(event, key) {
      this.modelValue[key] = event;
      this.onUpdate(this.modelValue);
    },
    onChangeMesure(event, index) {
      this.mesureValues[index] = event;
      this.onChange(
        JSON.stringify(
          Object.values(this.mesureValues).filter((e) => e !== "")
        ),
        "mesures"
      );
    },
    incrementMesure() {
      this.$emit("incrementMesure");
    },
    onEditChange(data) {
      this.$emit("onDeclarationDataChange", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.accident-form {
  .title {
    padding-top: 0;
    padding-bottom: 30px;
  }
}
</style>
