import configuration from "../config";
import axios from "./axiosConfiguration";

const endpoint = `${configuration.api_url}articles`;

const getArticles = async (idRubrique) => {
  const options = {
    params: {
      idRubrique,
    },
  };
  return axios
    .get(endpoint, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const deleteArticle = (idArticle) => {
  return axios
    .delete(`${endpoint}/${idArticle}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const updateArticle = (idArticle, article) => {
  return axios
    .put(`${endpoint}/${idArticle}`, article)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const saveArticle = (article) => {
  return axios
    .post(endpoint, article)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const updateOrderArticle = (article, articleId, swapId) => {
  const options = {
    params: {
      swapId,
    },
  };
  return axios
    .patch(`${endpoint}/${articleId}`, article, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getArticles,
  deleteArticle,
  updateArticle,
  saveArticle,
  updateOrderArticle,
};
