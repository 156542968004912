import configuration from "../config";
import axios from "./axiosConfiguration";

const endpoint = `${configuration.api_url}rubriques`;

const createCategory = async (category) => {
  return axios
    .post(`${endpoint}/categories`, category)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const updateCategory = async (category) => {
  return axios
    .patch(`${endpoint}/categories`, category)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const deleteCategory = async (idRubrique, idCategorie) => {
  return axios
    .delete(`${endpoint}/${idRubrique}/categories/${idCategorie}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  createCategory,
  updateCategory,
  deleteCategory,
};
