<template>
  <div
    class="block"
    :class="[{ shadow: shadow }]"
    :style="{ padding: gutters }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AsBlock",
  props: {
    shadow: {
      type: Boolean,
      default: false,
    },
    gutters: {
      type: String,
      default: "0px",
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";

.block {
  min-height: 50px;
  min-width: 100%;
  background-color: white;

  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
  &.shadow {
    box-shadow: 0px 0px 30px 0px $shadow_color;
  }
}
</style>
