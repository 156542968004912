<template>
  <div>
    <div
      class="flex align-items-center justify-content-between fichier"
      @click="downloadFichier(fichier.externalId, fichier.titre)"
    >
      <p class="mr-2">
        {{ fichier.titre }}
      </p>
      <div grid>
        <Button
          v-if="canRemove"
          icon="pi pi-times"
          class="p-button-sm p-button-rounded p-button-danger"
          @click="removeFichier(fichier.externalId, fichier.titre)"
        />
        <Button
          v-show="fichier.externalId"
          icon="pi pi-download"
          class="p-button-sm p-button-rounded button-primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
export default {
  name: "AsFile",
  components: {
    Button,
  },
  props: {
    fichier: Object,
    canRemove: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onDownload", "onRemove"],
  methods: {
    downloadFichier(externalId, titre) {
      if (this.fichier.externalId) {
        this.$emit("onDownload", externalId, titre);
      }
    },
    removeFichier(externalId, titre) {
      this.$emit("onRemove", externalId, titre);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
.fichier {
  background-color: $light_grey;
  padding: 10px;
  &:hover {
    background-color: $default_lighter_color;
    cursor: pointer;
  }
}
</style>
