<template>
  <div id="organisation">
    <div class="header grid">
      <div class="col-12 section-title chiffre-line">
        {{ $t("statistics.organization_title") }}
        <as-icon :source="iconImg" respectRatio class="ml-2" />
      </div>
    </div>
    <div class="grid safety-line chiffre-line">
      <div class="col-9">
        <div class="subtitle">{{ $t("statistics.safety_leader") }}</div>
        <div class="flex justify-content-start align-items-center" style="height: 100%">
          {{
            modelValue.safetyLeader
            ? modelValue.safetyLeader.prenom +
            " " +
            modelValue.safetyLeader.nom
            : $t("unknown")
          }}
        </div>
      </div>
      <div class="col-3 profile-img">
        <img :src="safetyLeaderImage" />
      </div>
    </div>
    <div class="grid chiffre-line-2">
      <div class="col-12">
        <div class="subtitle">{{ $t("statistics.relationships") }}</div>
        <div class="flex flex-column justify-content-center align-items-start" style="height: 100%">
          <Textarea v-if="editMode" v-model="modelValue.rattachements" :autoResize="true" />
          <span class="rattachements" v-else>
            {{ modelValue.rattachements }}
          </span>
        </div>
      </div>
    </div>
    <div class="grid chiffre-line-2-demi organigramme">
      <div class="col-12">
        <div class="subtitle">{{ $t("statistics.organization_chart") }}</div>
        <div class="flex justify-content-start align-items-center" style="height: 100%">
          <FileUpload v-if="editMode" ref="fileUploader" :showUploadButton="false" :showCancelButton="false"
            :customUpload="true" accept="image/*" :multiple="false" @select="onSelectedOrganizationImage"
            :chooseLabel="$t('statistics.choose_button')" class="col button-default file-upload" />
          <span v-else> <img :src="organigrammeImage" /></span>
        </div>
      </div>
    </div>
    <div class="header grid chiffre-line">
      <div class="col-4">
        <span> {{ $t("statistics.full_time_work") }} </span>
      </div>
      <div class="col-4">
        <span> {{ $t("statistics.part_time_work") }} </span>
      </div>
      <div class="col-4">
        <span> {{ $t("statistics.number_employees") }} </span>
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div class="col-4">
        <InputNumber v-if="editMode" v-model="modelValue.safetyCdiTempsComplet" />
        <b v-else>
          {{
            modelValue.safetyCdiTempsComplet
            ? modelValue.safetyCdiTempsComplet
            : "/"
          }}
        </b>
      </div>
      <div class="col-4">
        <InputNumber v-if="editMode" v-model="modelValue.safetyCdiTempsPartiel" />
        <b v-else>
          {{
            modelValue.safetyCdiTempsPartiel
            ? modelValue.safetyCdiTempsPartiel
            : "/"
          }}
        </b>
      </div>
      <div class="col-4">
        <InputNumber v-if="editMode" v-model="modelValue.safetyNombreCollaborateurs" />
        <b v-else>
          {{
            modelValue.safetyNombreCollaborateurs
            ? modelValue.safetyNombreCollaborateurs
            : "/"
          }}
        </b>
      </div>
    </div>
  </div>
</template>

<script>
import AsIcon from "../Elements/AsIcon.vue";
import vmodelMixins from "../../mixins/vmodelMixins";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import { bufferToBlobUrl, bufferToJpegBase64 } from "../../util";
import fichiersServiceApi from "../../services/fichiersServiceApi";

export default {
  name: "OrganisationBlock",
  components: {
    AsIcon,
    InputNumber,
    Textarea,
    FileUpload,
  },
  props: {
    editMode: Boolean,
  },
  data() {
    return {
      iconImg: require("../../assets/images/icons/picto-chiffre-orga.svg"),
      noUserImg: require("../../assets/images/no-user-image.jpg"),
      orgaImage: undefined,
    };
  },
  watch: {
    modelValue() {
      this.$nextTick(() => {
        this.getOrganigrammeImage();
      });
    },
  },
  computed: {
    safetyLeaderImage() {
      if (
        this.modelValue.safetyLeader &&
        this.modelValue.safetyLeader.photo &&
        this.modelValue.safetyLeader.photo.data.length > 0
      ) {
        return bufferToJpegBase64(this.modelValue.safetyLeader.photo.data);
      } else {
        return this.noUserImg;
      }
    },
    organigrammeImage() {
      if (this.orgaImage) {
        return this.orgaImage;
      } else {
        return "";
      }
    },
  },
  mixins: [vmodelMixins, fichiersServiceApi],
  beforeMount() {
    this.getOrganigrammeImage();
  },
  methods: {
    onSelectedOrganizationImage(event) {
      this.modelValue.fichierOrganigramme = event.files[0].objectURL;
      this.modelValue.fileToUpload = event.files[0];
    },
    async getOrganigrammeImage() {
      if (this.modelValue.fichierOrganigramme) {
        try {
          const fichier = await this.downloadFile(this.modelValue.fichierOrganigramme.externalId);
          this.orgaImage = bufferToBlobUrl(fichier);
        } catch (error) {
          console.log(error)
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";

#organisation {
  .rattachements {
    white-space: pre-wrap;
    text-align: left;
  }

  .profile-img {
    padding: 0px;
    height: 100px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      height: 100%;
      width: auto;
    }
  }

  .organigramme img {
    max-width: 100%;
  }

  .p-inputtext {
    width: 100%;
  }

  background-color: $secondary_light_color;

  .grid {
    &.header {
      background-color: $secondary_color;
    }
  }
}
</style>
