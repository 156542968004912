<template>
  <form class="as-form" @submit.prevent="handleSubmit()">
    <div class="grid">
      <slot name="header">
        <div class="col">
          <as-title
            class="title"
            :title="title && title !== '' ? title : $t('form.default_title')"
            :size="5"
          />
        </div>
      </slot>
    </div>
    <div class="grid">
      <slot name="body"></slot>
    </div>
    <div class="grid fixed-bottom">
      <div class="col flex justify-content-end">
        <Button
          :label="$t('form.cancel_button')"
          class="button-default"
          @click="handleClose"
          :loading="isBusy"
        />
        <Button
          type="submit"
          :label="$t('form.submit_button')"
          class="button-primary ml-2"
          :loading="isBusy"
        />
      </div>
    </div>
  </form>
</template>

<script>
import Button from "primevue/button";
import AsTitle from "@/components/Elements/AsTitle.vue";
export default {
  name: "AsForm",
  components: {
    Button,
    AsTitle,
  },
  props: {
    title: String,
    isBusy: Boolean,
  },
  emits: ["onClose", "onSubmit"],
  methods: {
    handleSubmit() {
      this.$emit("onSubmit");
    },
    handleClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
form {
  position: relative;

  .fixed-bottom {
    position: sticky;
    width: 100%;
    box-sizing: content-box;
    background-color: white;
    margin-left: -40px;
    margin-right: -40px;
    border-top: solid 1px $default_color;
    padding-left: 40px;
    padding-right: 40px;
    bottom: -40px;
    left: 0;
    right: 0;
  }
  .title {
    padding-top: 0;
    padding-bottom: 30px;
  }
}
</style>
