<template>
  <div class="bp-block" @click="showDetail" :class="{ 'edit-mode': editMode || isBusy }">
    <div class="grid">
      <div class="col-2 flex p-jc-stretch align-items-center">
        <img v-if="image" :src="image" alt="bp-img" />
        <ProgressSpinner v-if="isBusy" />
      </div>
      <div class="col">
        <div class="">
          <div>
            <div class="flex flex-wrap">
              <as-title :title="localeBestPractice.detail[0]
                ? localeBestPractice.detail[0].titre
                : null
                " :size="6" class="mr-auto" style="flex:1" />
              <div class="user-notation flex">
                <div class="flex consultation-counter">
                  <span>{{ bestPractice.consultationCounter }}</span>
                  <i class="pi pi-eye" />
                </div>
                <Rating v-model="global" disabled="true" :stars="5" :cancel="false" />
              </div>
            </div>
            <div class="description">
              <div class="flex align-items-end">
                <as-icon class="mr-1" :source="bestPractice.entreprise.fichierLogo.url" :size="40" respectRatio />
                <as-icon class="mr-1" :source="bestPractice.entreprise.fichierDrapeau.url" :size="40" respectRatio />
              </div>
              <div v-html="localeBestPractice.detail[0]
                ? localeBestPractice.detail[0].description
                : ''
                " />
            </div>
          </div>
          <div v-if="isAdmin ||
            (isSafetyLeader &&
              bestPractice.entreprise.id === user.idEntreprise)
            ">
            <Button v-show="editMode" @click="onEdit" icon="pi pi-pencil"
              class="p-button-sm p-button-rounded button-default mr-1">
            </Button>
            <Button v-show="editMode" @click="onDelete" icon="pi pi-trash"
              class="p-button-sm p-button-rounded p-button-danger">
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AsTitle from "../Elements/AsTitle.vue";
import AsIcon from "@/components/Elements/AsIcon.vue";
import Button from "primevue/button";
import fichiersServiceApi from "../../services/fichiersServiceApi";
import { bufferToBlobUrl } from "../../util";
import ProgressSpinner from "primevue/progressspinner";
import Rating from "primevue/rating";
import localeChangeDetectionMixins from "@/mixins/localeChangeDetectionMixins";
import { mapGetters } from "vuex";
import roleMixins from "@/mixins/roleMixins";

export default {
  name: "BestPracticeBlock",
  components: {
    AsTitle,
    AsIcon,
    Button,
    ProgressSpinner,
    Rating,
  },
  props: {
    bestPractice: Object,
    editMode: Boolean,
    globalNotation: Number,
  },
  computed: {
    localeBestPractice() {
      return {
        ...this.bestPractice,
        detail: this.bestPractice.detail.filter(
          (el) => el.idLangue === this.currentLocale.id
        ),
      };
    },
    ...mapGetters({
      user: "getUser",
    }),
  },
  data() {
    return {
      image: undefined,
      isBusy: false,
      global: this.globalNotation,
    };
  },
  watch: {
    bestPractice() {
      this.global = this.globalNotation;
      this.onLoadImage(this.bestPractice);
    },
  },
  emits: ["onEdit", "onDelete", "onShow", "onNotation"],
  mixins: [localeChangeDetectionMixins, roleMixins, fichiersServiceApi],
  beforeMount() {
    this.onLoadImage(this.bestPractice);
  },
  methods: {
    onEdit() {
      this.$emit("onEdit");
    },
    onDelete() {
      this.$emit("onDelete");
    },
    showDetail() {
      if (this.editMode || this.isBusy) {
        return;
      }

      this.$emit("onShow", this.localeBestPractice, this.image);
    },
    async onLoadImage(value) {
      this.image = undefined;
      if (value.photo && value.photo.externalId != null) {
        this.isBusy = true;
        try {
          const fichier = await this.downloadFile(value.photo.externalId);
          this.image = bufferToBlobUrl(fichier);
        } catch (error) {
          console.log(error)
        }
      }
      this.isBusy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";

.bp-block {
  .user-notation {
    min-width: 180px;
    margin-left: auto;

    .consultation-counter {
      margin-right: 20px;

      i {
        margin-left: 5px;
      }
    }
  }

  padding-bottom: 20px;

  &:not(.edit-mode) {
    cursor: pointer;

    &:hover {
      background-color: $quaternary_lighter_color;
    }
  }

  &:not(:last-child) {
    border-bottom: solid 1px $default_color;
  }

  &:not(:first-child) {
    padding-top: 20px;
  }

  .title {
    margin-bottom: 5px !important;
  }

  h6 {
    color: $primary_color;
    font-weight: bold;
    padding-bottom: 10px;
  }

  img {
    width: auto;
    max-height: 5vh;
    max-width: 9vw;
    border-radius: 10px;
    margin: 0 auto;
  }

  .date {
    font-weight: 400;
    background-color: $primary_color;
    color: $light_font;
    margin: 30px 10px 10px 0;
    padding: 3px;
    border-radius: 5px;
  }

  .description {
    color: #3e5363;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5rem;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-width: 44vw;
  }
}
</style>
