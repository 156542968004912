<template>
  <div class="col-12 grid-nogutter">
    <div class="field flex align-items">
      <div class="p-float-label flex-1">
        <InputText
          type="text"
          v-model="v$.libelle.$model"
          :class="{ 'p-invalid': v$.libelle.$invalid && submitted }"
          @change="onChange"
        />
        <label>{{ $t("category_form.title_field") }}</label>
      </div>
      <as-input-error :errors="v$.libelle.$silentErrors" v-show="submitted" />
      <img class="flag" :src="getFlag" />
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import InputText from "primevue/inputtext";
import AsInputError from "./AsInputError.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    InputText,
    AsInputError,
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return { libelle: "", idLangue: 1 };
      },
    },
    submitted: Boolean,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      libelle: this.detail.libelle,
    };
  },
  validations() {
    return {
      libelle: { required },
    };
  },
  computed: {
    ...mapGetters({
      languages: "getLanguages",
    }),
    getFlag() {
      let isoCode = this.languages.find(
        (el) => el.id === this.detail.idLangue
      ).codeIso;
      return require(`../../assets/images/flags/${isoCode}.svg`);
    },
  },
  emits: ["onChange"],
  methods: {
    onChange() {
      this.$emit("onChange", {
        libelle: this.libelle,
        idLangue: this.detail.idLangue,
      });
    },
  },
};
</script>

<style scoped>
.flag {
  height: 40px;
}
</style>
