<template>
  <div>
    <Panel>
      <template #header>
        {{ $t("declaration_accident_form.choose_drive_media") }}
        <Dropdown
          v-model="selectedFile"
          :options="availableFiles"
          optionLabel="titre"
          @change="onSelectFile"
        />
      </template>
      <div
        v-for="(el, index) in modelValue"
        :key="index"
        class="flex justify-content-between align-items-center video-file"
      >
        <span class="col-8">
          {{ el.titre }}</span
        >
        <Button
          icon="pi pi-times"
          class="p-button-sm button-default col-4"
          @click="removeSelectedFile(el.externalId)"
        />
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from "primevue/panel";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";

import vmodelMixins from "../../mixins/vmodelMixins";

export default {
  name: "AsDriveSelector",
  components: {
    Panel,
    Dropdown,
    Button,
  },
  props: {
    availableFiles: Array,
  },
  data: function () {
    return {
      selectedFile: undefined,
    };
  },
  mixins: [vmodelMixins],
  methods: {
    onSelectFile() {
      if (
        !this.modelValue.find(
          (el) => el.externalId === this.selectedFile.externalId
        )
      ) {
        this.modelValue.push(this.selectedFile);
        this.onUpdate(this.modelValue);
      }
    },
    removeSelectedFile(externalId) {
      this.onUpdate(
        this.modelValue.filter((el) => el.externalId !== externalId)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
.video-file {
  background-color: $light_grey;
  padding: 15px;
  margin-top: 10px;
}
</style>
