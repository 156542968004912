import configuration from "../config";
import axios from "./axiosConfiguration";

const endpointRubriques = `${configuration.api_url}rubriques`;

const getRubriques = async () => {
  return axios
    .get(endpointRubriques)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getRubrique = async (idRubrique) => {
  return axios
    .get(`${endpointRubriques}/${idRubrique}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getCategoriesFromRubrique = async (idRubrique) => {
  return axios
    .get(`${endpointRubriques}/${idRubrique}/categories`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getCategory = async (idRubrique, idCategorie) => {
  return axios
    .get(`${endpointRubriques}/${idRubrique}/categories/${idCategorie}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const postCategoryFile = async (idRubrique, idCategorie, file) => {
  return axios
    .post(
      `${endpointRubriques}/${idRubrique}/categories/${idCategorie}/files`,
      file
    )
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const deleteFichierFromCategory = async (
  idFichier
) => {
  return axios
    .delete(
      `${endpointRubriques}/files/${idFichier}`
    )
    .then(() => {
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const deleteCategory = async (idRubrique, idCategorie) => {
  return axios
    .delete(`${endpointRubriques}/${idRubrique}/categories/${idCategorie}`)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getRubriques,
  getRubrique,
  getCategoriesFromRubrique,
  getCategory,
  postCategoryFile,
  deleteFichierFromCategory,
  deleteCategory,
};
