<template>
  <div class="layout default-layout">
    <div class="top p-d-print-none">
      <as-header />
      <div id="main-menu"><as-menu /></div>
    </div>
    <div class="layout-container" :style="backgroundStyle">
      <img class="wave p-d-print-none" v-if="showWaves" :src="waveLight" />
      <div class="page-container">
        <router-view />
      </div>
      <img
        class="wave reversed p-d-print-none"
        v-if="showWaves"
        :src="waveLight"
      />
    </div>
    <div class="p-d-print-none">
      <as-footer />
      <as-copyright />
    </div>
  </div>
  <div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import AsMenu from "../Elements/AsMenu.vue";
import Footer from "./Footer.vue";
import Copyright from "./Copyright.vue";

export default {
  name: "DefaultLayout",
  components: {
    "as-header": Header,
    "as-menu": AsMenu,
    "as-footer": Footer,
    "as-copyright": Copyright,
  },
  props: {
    showWaves: {
      type: Boolean,
      default: true,
    },
    darkWaves: {
      type: Boolean,
      default: false,
    },
    backgroundWorld: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      waveLight: require("../../assets/images/wave.svg"),
      waveDark: require("../../assets/images/wave-dark.svg"),
      bgWorld: require("../../assets/images/wave.svg"),
    };
  },
  computed: {
    backgroundStyle() {
      let bgStyle = {
        "background-image": `url(${this.bgWorld})`,
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
      if (this.backgroundWorld) {
        return bgStyle;
      }
      return {};
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/sizing.scss";
@import "../../assets/styles/zindex.scss";

.default-layout {
  .top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $top_zindex;
    @media screen and (max-width: $media_small) {
      #main-menu {
        display: none;
        height: 0;
      }
    }
  }
  .layout-container {
    margin-top: $header_margin;
    position: relative;
    display: flex;
    justify-content: center;
    min-height: calc(
      100vh - #{$header_margin + $footer_height + $copyright_height}
    );
    .wave {
      height: auto;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      &.reversed {
        bottom: 0;
        top: unset;
        transform: rotateX(180deg);
      }
    }
    .page-container {
      padding-bottom: 40px;
      position: relative;
      width: 100%;
    }

    @media screen and (max-width: $media_small) {
      margin-top: $header_margin_media_small;
    }
  }
}
</style>
