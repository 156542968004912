<template>
  <FileUpload ref="fileUpload" :disabled="uploadDisabled" class="button-default file-upload p-fileupload-basic"
    :showUploadButton="false" :showCancelButton="false" :customUpload="true" @select="onSelectedFile"
    :chooseLabel="chooseLabel" :fileLimit="fileLimit" :multiple="multiple" :accept="accept" />
  <div v-for="(fichier, index) of innerFichiers" :key="index">
    <as-file v-if="fichier.toDelete !== true" :fichier="fichier" :canRemove="true"
      @onDownload="downloadFichier(fichier.externalId, fichier.titre)" @onRemove="removeFichier(index)" />
  </div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import AsFile from "./AsFile.vue";
import fichiersServiceApi from "../../services/fichiersServiceApi";
import apiEventTypes from "@/constantes/apiEventTypes";

export default {
  name: "AsFileUpload",
  data() {
    return {
      innerFichiers: this.fichiers, //uploaded file (used for update)
      hasFiles: undefined, //no uploaded file,
    };
  },
  mixins: [fichiersServiceApi],
  components: {
    FileUpload,
    AsFile,
  },
  props: {
    fichiers: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
    maxFileSize: {
      type: Number,
      default: null,
    },
    fileLimit: {
      type: Number,
      default: null,
    },
    chooseLabel: {
      type: String,
      default: null,
    },
  },
  emits: [],
  mounted() {
    this.$refs.fileUpload.files = [];
    this.$refs.fileUpload.$el.lastChild.hidden = true;
  },
  computed: {
    uploadDisabled() {
      return this.fileLimit &&
        this.innerFichiers.filter((fichier) => fichier.toDelete !== true)
          .length >= this.fileLimit
        ? true
        : false;
    },
  },
  methods: {
    hasFilesTest() {
      this.hasFiles =
        this.innerFichiers &&
          this.innerFichiers.filter((fichier) => fichier.toDelete !== true)
            .length > 0
          ? true
          : undefined;
    },
    downloadFichier(externalId, fichierTitre) {
      this.downloadFile(externalId, fichierTitre);
    },
    removeFichier(index) {
      if (this.innerFichiers[index].externalId === undefined) {
        this.innerFichiers.splice(index, 1);
      } else {
        this.innerFichiers[index].toDelete = true;
      }
      this.hasFilesTest();
    },
    onSelectedFile(event) {
      event.files.forEach((file) => {
        if (this.validateFile(file)) {
          this.innerFichiers.push({
            titre: file.name,
            size: file.size,
            mimeType: file.type,
            externalId: undefined,
            file: file,
          });
        }
      });
      this.$refs.fileUpload.files = [];
      this.hasFilesTest();
    },
    formatSize(bytes) {
      if (bytes === 0) {
        return "0 B";
      }
      let k = 1000,
        dm = 3,
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    validateFile(file) {
      if (this.maxFileSize && file.size > this.maxFileSize) {
        //this.messages.push(this.invalidFileSizeMessage.replace('{0}', file.name).replace('{1}', this.formatSize(this.maxFileSize)));
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.ERROR, {
            detail: {
              title: this.$t("file.invalidFileSizeMessageTitle"),
              message: this.$t("file.invalidFileSizeMessage", {
                filename: file.name,
                maxFileSize: this.formatSize(this.maxFileSize),
              }),
            },
          })
        );
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
