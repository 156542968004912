import { Buffer } from "buffer";

export const ellipsis = (value, maxsize = 30) => {
  if (value === null || value === undefined) {
    return "";
  }
  if (value.length <= maxsize + 3) {
    return value;
  }
  value = value.substring(0, maxsize);
  let last = value.lastIndexOf(" ");
  value = value.substring(0, last);
  return value + "...";
};

export const bufferToBlobUrl = (buffer, mimeType) => {
  let blob = new Blob([buffer], { type: mimeType });
  return URL.createObjectURL(blob);
};

export const bufferToJpegBase64 = (buffer) => {
  let b64 = Buffer.from(buffer)
    .toString("base64")
    .replace("dataimage/jpegbase64", "");
  return `data:image/jpeg;base64,${b64}`;
};

export const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, size + i));
  }
  return result;
};

export const isMobile = (opts) => {
  const mobileRE = /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i
  const notMobileRE = /CrOS/
  const tabletRE = /android|ipad|playbook|silk/i
  if (!opts) opts = {}
  let ua = opts.ua
  if (!ua && typeof navigator !== 'undefined') ua = navigator.userAgent
  if (ua && ua.headers && typeof ua.headers['user-agent'] === 'string') {
    ua = ua.headers['user-agent']
  }
  if (typeof ua !== 'string') return false

  let result =
    (mobileRE.test(ua) && !notMobileRE.test(ua)) ||
    (!!opts.tablet && tabletRE.test(ua))

  if (
    !result &&
    opts.tablet &&
    opts.featureDetect &&
    navigator &&
    navigator.maxTouchPoints > 1 &&
    ua.indexOf('Macintosh') !== -1 &&
    ua.indexOf('Safari') !== -1
  ) {
    result = true
  }

  return result
}

export default {
  ellipsis,
  bufferToBlobUrl,
  bufferToJpegBase64,
  chunkArray,
};
