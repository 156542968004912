import configuration from "../config";
import axios from "./axiosConfiguration";

const endpoint = `${configuration.api_url}translate`;
export const translate = (strings, target) => {
  const options = { data: strings, target };
  return axios
    .post(endpoint, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
