<template>
  <div
    class="add-safety-block flex justify-content-center align-items-center"
    :style="backgroundStyle"
  >
    <Button
      @click="onClick"
      icon="pi pi-plus"
      :label="$t('community.add_user_button')"
      class="p-button-sm button-default"
    />
  </div>
</template>

<script>
import Button from "primevue/button";

export default {
  name: "AddSafetyBlock",
  components: {
    Button,
  },
  computed: {
    backgroundStyle() {
      return { backgroundImage: "url(" + this.bgImg + ")" };
    },
  },
  data() {
    return {
      bgImg: require("../../assets/images/fond-fiche.jpg"),
      noUserImg: require("../../assets/images/no-user-image.jpg"),
    };
  },
  emits: ["onClick"],
  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/fonts.scss";
.add-safety-block {
  flex: 1 0 21%;
  height: 220px;
  margin: 20px;
  background: white;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  box-shadow: 0px 0px 20px 0px $shadow_color;
  border-radius: 20px;
}
</style>
