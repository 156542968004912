import rubriquesServiceApi from "../services/rubriquesServiceApi";

export default (idRubrique) => ({
  data() {
    return {
      idRubrique: NaN,
      rubrique: {},
    };
  },
  created() {
    this.idRubrique = idRubrique;
  },
  beforeMount() {
    this.reloadRubrique();
  },
  methods: {
    reloadRubrique() {
      rubriquesServiceApi.getRubrique(this.idRubrique).then((result) => {
        this.rubrique = result;
      });
    },
  },
});
