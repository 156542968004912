<template>
  <as-form
    id="category-form"
    :title="$t('category_form.title')"
    :isBusy="isBusy"
    @onClose="onClose"
    @onSubmit="submit"
  >
    <template #body>
      <div class="col">
        <div class="p-fluid grid">
          <category-detail-form
            v-for="(d, index) in details"
            :detail="d"
            :key="index"
            :submitted="submitted"
            @onChange="updateDetail($event, index)"
          />
        </div>
      </div>
    </template>
  </as-form>
</template>

<script>
import useVuelidate from "@vuelidate/core";

import AsForm from "./AsForm.vue";
import CategoryDetailForm from "./CategoryDetailForm.vue";
import roleMixins from "@/mixins/roleMixins";
import { mapGetters } from "vuex";
export default {
  name: "CategoryForm",
  components: {
    AsForm,
    CategoryDetailForm,
  },
  props: {
    node: Object,
    isBusy: Boolean,
    categorie: {},
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      details: [],
    };
  },
  computed: {
    ...mapGetters({
      languages: "getLanguages",
    }),
    availableLocales() {
      return this.$i18n.availableLocales.filter((el) => {
        return el.length === 2;
      });
    },
  },
  watch: {
    languages() {
      this.initDetails();
    },
  },
  mixins: [roleMixins],
  emits: ["onSubmit", "onClose"],
  beforeMount() {
    this.initDetails();
  },
  methods: {
    initDetails() {
      if (this.languages && this.languages.length > 0) {
        if (this.categorie) {
          this.details = this.categorie.detail;
        } else {
          this.availableLocales.forEach((loc) => {
            this.details.push({
              titre: "",
              idLangue: this.languages.find((l) => l.codeIso === loc).id,
            });
          });
        }
      }
    },
    updateDetail(event, index) {
      this.details[index] = event;
    },
    onClose() {
      this.$emit("onClose");
    },
    submit() {
      this.submitted = true;
      if (this.v$.$invalid) {
        return;
      } else {
        this.$emit("onSubmit", {
          node: this.node,
          detail: this.details,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
