<template>
  <as-cms-page-layout
    id="plan"
    :idRubrique="idRubrique"
    v-model="editMode"
    :articles="articles"
    :rubrique="rubrique"
    @reloadArticles="reloadArticles"
    @reloadRubrique="reloadRubrique"
  >
  </as-cms-page-layout>
</template>

<script>
// @ is an alias to /src
import AsCmsPageLayout from "@/components/Layout/AsCmsPageLayout.vue";
import rubriques from "@/constantes/rubriques.js";
import articlesMixins from "@/mixins/articlesMixins";
import rubriqueMixins from "@/mixins/rubriqueMixins";
import localeChangeDetectionMixins from "@/mixins/localeChangeDetectionMixins";

export default {
  name: "Plan",
  components: {
    AsCmsPageLayout,
  },
  data() {
    return {
      editMode: false,
    };
  },
  mixins: [
    articlesMixins(rubriques.plan),
    rubriqueMixins(rubriques.plan),
    localeChangeDetectionMixins,
  ],
  methods: {
    onLocaleChange() {
      this.reloadRubrique();
      this.$emit("onLocaleChange");
    },
  },
};
</script>
