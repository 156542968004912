<template>
  <div class="flex justify-content-between mb-2">
    <as-title
      v-show="!editMode || !isEditable || !isAdmin"
      :title="updatedTitre"
      :size="1"
    />
    <InputText
      v-show="editMode && isEditable && isAdmin"
      v-model="updatedTitre"
      :placeholder="$t('cms_page.title_placeholder')"
      @update:modelValue="onInput"
    />
    <Button
      v-show="!editMode && (isEditable || showEditButton) && isAdmin"
      @click="enterEditMode"
      icon="pi pi-pencil"
      class="p-button-sm p-button-rounded button-default reversed"
    >
    </Button>
    <div v-show="editMode && isEditable && isAdmin">
      <Button
        v-if="!first"
        @click="onUp"
        icon="pi pi-caret-up"
        class="p-button-sm p-button-rounded p-button-primary ml-2"
      >
      </Button>
      <Button
        v-if="!last"
        @click="onDown"
        icon="pi pi-caret-down"
        class="p-button-sm p-button-rounded p-button-primary ml-2"
      >
      </Button>
      <Button
        @click="onDelete"
        icon="pi pi-trash"
        class="p-button-sm p-button-rounded p-button-danger ml-2"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import AsTitle from "@/components/Elements/AsTitle.vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";

import roleMixins from "../../mixins/roleMixins";

export default {
  name: "AsArticleTitle",
  components: {
    AsTitle,
    Button,
    InputText,
  },
  props: {
    modelValue: null,
    showEditButton: { type: Boolean, default: true },
    isEditable: { type: Boolean, default: true },
    isDeletable: { type: Boolean, default: true },
    first: Boolean,
    last: Boolean,
    editMode: Boolean,
  },
  mixins: [roleMixins],
  emits: ["onEnterEditMode", "onDelete", "update:modelValue", "onUp", "onDown"],
  methods: {
    onInput(event) {
      this.$emit("update:modelValue", event);
    },
    enterEditMode() {
      this.$emit("onEnterEditMode");
    },
    onDelete() {
      this.$emit("onDelete");
    },
    onUp() {
      this.$emit("onUp");
    },
    onDown() {
      this.$emit("onDown");
    },
  },
  data() {
    return {
      updatedTitre: this.modelValue,
    };
  },
};
</script>
