<template>
  <DataTable :value="elements" responsiveLayout="scroll">
    <Column v-for="column in columns" :header="column" :key="column"
      ><template #body> <Skeleton></Skeleton> </template
    ></Column>
  </DataTable>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Skeleton from "primevue/skeleton";

export default {
  name: "AsDataTableSkeleton",
  components: {
    DataTable,
    Column,
    Skeleton,
  },
  props: {
    rows: {
      type: Number,
      default: 1,
    },
    columns: {
      type: Array,
    },
  },
  computed: {
    elements() {
      return new Array(this.rows);
    },
  },
};
</script>
