<template>
    <as-form id="best-practices-form" :title="title" :isBusy="isBusy" @onClose="onClose" @onSubmit="submit">
        <template #body>
            <div class="col" style="max-width: 100%">
                <div class="p-fluid grid">
                    <div class="p-filed col-12">
                        <div class="grid">
                            <div class="col-6">
                                <AsFileUpload :fichiers="fichiers" :maxFileSize="maxFileUpload"
                                    :chooseLabel="$t('cms_page.add_documents')" :multiple="true" />
                                <as-input-error :errors="v$.hasFiles.$silentErrors" v-show="submitted" />
                            </div>
                            <div class="col">
                                <AsFileUpload :fichiers="photos" :maxFileSize="maxFileUpload"
                                    :chooseLabel="$t('best_practices_form.choose_image_button')" :multiple="true"
                                    accept="image/*" :fileLimit="1" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="col-6">
                                <div class="field mt-4">
                                    <div class="p-float-label">
                                        <Dropdown v-model="entreprise" :options="entreprisesOptions" optionLabel="label"
                                            optionValue="value" :class="{
                                                'p-invalid': v$.entreprise.$invalid && submitted,
                                            }" />
                                        <label>{{
                                            $t("best_practices_form.select_company")
                                        }}</label>
                                    </div>
                                    <as-input-error :errors="v$.entreprise.$silentErrors" v-show="submitted" />
                                </div>
                                <!-- <div class="field mt-4">
                                    <div class="p-float-label">
                                        <TreeSelect selectionMode="multiple" v-model="v$.categorie.$model"
                                            :options="mappedCategories" :metaKeySelection="checked">
                                        </TreeSelect>
                                        <Chip class="mt-2 mr-2" v-for="(value, categoryId) in v$.categorie.$model"
                                            :key="categoryId" removable @remove="updateTreeSelectOnRemove(categoryId)">
                                            {{ getCategoryLabel(categoryId) }}
                                        </Chip>

                                        <AutoComplete v-model="v$.selectedCategories.$model" multiple optionLabel="label"
                                            @complete="search" :suggestions="fitleredCategories">
                                            <template #option="slotProps">
                                                <div class="flex align-options-center">
                                                    <div>{{ slotProps.option.label }}</div>
                                                </div>
                                            </template>
                                        </AutoComplete> 
                                        <label>{{
                                            $t("best_practices_form.referential_section_field")
                                        }}</label>
                                    </div>
                                    <as-input-error :errors="v$.categorie.$silentErrors" v-show="submitted" />
                                </div> -->
                                <div class="field mt-5">
                                    <div class="p-float-label">
                                        <InputText type="text" v-model="v$.depositorName.$model" :class="{
                                            'p-invalid': v$.depositorName.$invalid && submitted,
                                        }" />
                                        <label>{{
                                            $t("best_practices_form.depositor_field")
                                        }}</label>
                                    </div>
                                    <as-input-error :errors="v$.depositorName.$silentErrors" v-show="submitted" />
                                </div>
                                <div class="field mt-5">
                                    <div class="flex">
                                        <div class="p-float-label">
                                            <InputText type="text" v-model="v$.safetyLeaderName.$model" :class="{
                                                'p-invalid':
                                                    v$.safetyLeaderName.$invalid && submitted,
                                            }" />
                                            <label>{{
                                                $t("best_practices_form.safetyLeader_field")
                                            }}</label>
                                            <as-input-error :errors="v$.safetyLeaderName.$silentErrors"
                                                v-show="submitted" />
                                        </div>

                                        <div class="p-float-label">
                                            <InputText type="text" v-model="v$.implementationCost.$model" :class="{
                                                'p-invalid':
                                                    v$.implementationCost.$invalid && submitted,
                                            }" />
                                            <label>{{
                                                $t("best_practices_form.implementation_cost_field")
                                            }}</label>
                                            <as-input-error :errors="v$.implementationCost.$silentErrors"
                                                v-show="submitted" />
                                        </div>
                                    </div>
                                </div>
                                <div class="field mt-5">
                                    <div class="p-float-label">
                                        <Dropdown v-model="v$.complexityLevel.$model" :options="complexityLevelOptions"
                                            optionLabel="label" optionValue="value" :class="{
                                                'p-invalid': v$.complexityLevel.$invalid && submitted,
                                            }" />
                                        <label>{{
                                            $t("best_practices_form.complexity_level_field")
                                        }}</label>
                                    </div>
                                    <as-input-error :errors="v$.complexityLevel.$silentErrors" v-show="submitted" />
                                </div>
                                <div class="field mt-5">
                                    <div class="flex">
                                        <div class="p-float-label">
                                            <Dropdown v-model="v$.paybackEstimation.$model"
                                                :options="paybackEstimationOptions" optionLabel="label" optionValue="value"
                                                :class="{
                                                    'p-invalid':
                                                        v$.paybackEstimation.$invalid && submitted,
                                                }" />
                                            <label>{{
                                                $t("best_practices_form.payback_estimation_field")
                                            }}</label>
                                            <as-input-error :errors="v$.paybackEstimation.$silentErrors"
                                                v-show="submitted" />
                                        </div>

                                        <div class="p-float-label">
                                            <Dropdown v-model="v$.duplicabilityLevel.$model"
                                                :options="duplicabilityLevelOptions" optionLabel="label" optionValue="value"
                                                :class="{
                                                    'p-invalid':
                                                        v$.duplicabilityLevel.$invalid && submitted,
                                                }" />
                                            <label>{{
                                                $t("best_practices_form.duplicability_level_field")
                                            }}</label>
                                            <as-input-error :errors="v$.duplicabilityLevel.$silentErrors"
                                                v-show="submitted" />
                                        </div>
                                    </div>
                                </div>
                                <div class="field mt-5">
                                    <div class="flex">
                                        <div class="p-float-label">
                                            <InputText type="text" v-model="otherContributorName" />
                                            <label>{{
                                                $t("best_practices_form.other_contributor_field")
                                            }}</label>
                                        </div>
                                        <div class="p-float-label">
                                            <InputText type="text" v-model="otherContributorCoordinate" />
                                            <label>{{
                                                $t(
                                                    "best_practices_form.other_contributor_coordinate_field"
                                                )
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="grid">
                                    <label class="col-12">{{ $t("best_practices_form.referential_section_field")
                                    }}</label>
                                </div>
                                <as-input-error :errors="v$.categorie.$silentErrors" v-show="submitted" />
                                <ScrollPanel style="width: 100%; height: 400px;" class="custombar">
                                    <Tree v-model:selectionKeys="v$.categorie.$model" :value="mappedCategories"
                                        selectionMode="multiple" :metaKeySelection="checked"
                                        @nodeSelect="unselectParentNode">
                                        <template #child="slotProps">
                                            <div class="flex">
                                                <span>{{ slotProps.node.label }}</span>
                                            </div>
                                        </template>
                                    </Tree>
                                </ScrollPanel>
                                <div class="grid">
                                    <div class="col-12">
                                        <Chip class="mt-1 mr-2" v-for="(value, categoryId) in v$.categorie.$model"
                                            :key="categoryId" removable @remove="updateTreeSelectOnRemove(categoryId)">
                                            {{ getCategoryLabel(categoryId) }}
                                        </Chip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-filed col-12">
                        <TabView>
                            <TabPanel v-for="(d, index) in details" :key="index">
                                <template #header>
                                    <div>{{ d.langue ? d.langue.libelle : "" }}</div>
                                </template>
                                <br />
                                <best-practice-detail-form :detail="d" :key="index + d.titre + beingTranslated"
                                    :submitted="submitted" :beingTranslated="beingTranslated"
                                    @onChange="updateDetail($event, index)" @onTranslate="translateTo"
                                    @onTranslateToEditor="translateToEditor" />
                            </TabPanel>
                        </TabView>
                    </div>
                    <div class="p-filed col-12 md:col-3" v-show="false">
                        <as-title class="col-12" :title="'tags:'" :size="5" />
                        <InputText type="text" v-model="newTag" class="mb-2" />
                        <Button class="button-default p-button-sm" @click="postTag" icon="pi pi-plus"
                            :label="$t('best_practices_form.add_tag_button')" />
                        <div class="flex flex-wrap mt-5">
                            <div v-for="tag in availableTags" :key="tag.id" class="field-checkbox mr-4">
                                <Checkbox :id="`tag_${tag.id}`" name="tags" :value="tag" v-model="tags" />
                                <label :for="`tag_${tag.id}`">{{ tag.libelle }}</label>
                                <Button v-if="isAdmin" @click="handleDeleteTag(tag.id)" icon="pi pi-times"
                                    class="ml-1 p-button p-button-sm p-button-rounded p-button-danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <as-confirm-modal :isVisible="showConfirmDeleteTag" :modalMessage="$t('confirm_modal.delete_message')"
                @onConfirm="confirmDeleteTag" @onCancel="cancelDeleteTag" />
        </template>
    </as-form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AsForm from "./AsForm.vue";
import AsTitle from "../Elements/AsTitle.vue";
import AsFileUpload from "../Elements/AsFileUpload.vue";
import AsInputError from "./AsInputError.vue";
import AsConfirmModal from "@/components/Modals/AsConfirmModal.vue";
import Chip from "primevue/chip";
import Tree from "primevue/tree";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ScrollPanel from "primevue/scrollpanel";
import BestPracticeDetailForm from "./BestPracticesDetailForm.vue";
import entreprisesServiceApi from "@/services/entreprisesServiceApi";
import tagsServiceApi from "@/services/tagsServiceApi";
import { mapGetters } from "vuex";
import apiEventTypes from "@/constantes/apiEventTypes";
import localeChangeDetectionMixins from "@/mixins/localeChangeDetectionMixins";
import roleMixins from "@/mixins/roleMixins";
import config from "../../config";
import fichiersServiceApi from "../../services/fichiersServiceApi";
import complexityLevels from "@/constantes/complexityLevels";
import paybackEstimations from "@/constantes/paybackEstimations";
import duplicabilityLevels from "@/constantes/duplicabilityLevels";
import { translate } from "../../services/googleTranslateService";

export default {
    name: "BestPracticesForm",
    components: {
        AsForm,
        AsFileUpload,
        AsInputError,
        AsTitle,
        Chip,
        Tree,
        Dropdown,
        InputText,
        Button,
        Checkbox,
        TabView,
        TabPanel,
        ScrollPanel,
        BestPracticeDetailForm,
        AsConfirmModal,
    },
    props: {
        title: String,
        update: {
            type: Boolean,
            default: false,
        },
        rubriqueBestPractice: {},
        bestPractice: {
            type: Object,
            default: () => {
                return {
                    details: [],
                    tags: [],
                    fichiers: [],
                };
            },
        },
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        const categorie = {}
        if (this.bestPractice?.idCategorie) {
            categorie[this.bestPractice.idCategorie] = true
        }

        return {
            checked: false,
            isBusy: false,
            categorie: categorie,
            categories: {},
            fitleredCategories: [],
            entreprises: [],
            entreprise:
                this.bestPractice.entreprise != undefined
                    ? this.bestPractice.entreprise.id
                    : undefined,
            hasFiles: undefined, //no uploaded file
            fichiers: this.bestPractice ? this.bestPractice.fichiers : [], //uploaded file (used for update)
            image: undefined, // no uploaded image
            photos:
                this.bestPractice != undefined && this.bestPractice.photo
                    ? [this.bestPractice.photo]
                    : [], //uploaded image (used for update)
            submitted: false,
            details:
                this.bestPractice.details != undefined ? this.bestPractice.details : [],
            availableTags: [],
            tags: this.bestPractice.tags != undefined ? this.bestPractice.tags : [],
            newTag: "",
            showConfirmDeleteTag: false,
            tagIdToDelete: NaN,
            maxFileUpload: config.max_file_upload,
            depositorName:
                this.bestPractice != undefined ? this.bestPractice.depositorName : "",
            safetyLeaderName:
                this.bestPractice != undefined
                    ? this.bestPractice.safetyLeaderName
                    : "",
            idCategorie: this.bestPractice ? this.bestPractice.idCategorie : "",
            complexityLevel:
                this.bestPractice != undefined ? this.bestPractice.complexityLevel : "",
            implementationCost:
                this.bestPractice != undefined
                    ? this.bestPractice.implementationCost
                    : "",
            paybackEstimation:
                this.bestPractice != undefined
                    ? this.bestPractice.paybackEstimation
                    : "",
            duplicabilityLevel:
                this.bestPractice != undefined
                    ? this.bestPractice.duplicabilityLevel
                    : "",
            otherContributorName:
                this.bestPractice != undefined
                    ? this.bestPractice.otherContributorName
                    : "",
            otherContributorCoordinate:
                this.bestPractice != undefined
                    ? this.bestPractice.otherContributorCoordinate
                    : "",
            beingTranslated: false,
        };
    },
    computed: {
        mappedCategories() {
            let categories = [];
            if (this.rubriqueBestPractice) {
                categories =
                    this.rubriqueBestPractice && this.rubriqueBestPractice.categories
                        ? this.rubriqueBestPractice.categories.map((cat) => {
                            return this.mapCategory(cat, 0);
                        })
                        : [];
            }

            return categories;
        },
        ...mapGetters({
            languages: "getLanguages",
            user: "getUser",
        }),
        availableLocales() {
            return this.$i18n.availableLocales.filter((el) => {
                return el.length === 2;
            });
        },
        entreprisesOptions() {
            return this.entreprises.map((el) => {
                return {
                    label: `${el.nom}`,
                    value: el.id,
                };
            });
        },
        complexityLevelOptions() {
            let result = [];
            for (const property in complexityLevels) {
                result.push({
                    label: this.$t(`complexityLevels.${property}`),
                    value: complexityLevels[property],
                });
            }
            return result;
        },
        paybackEstimationOptions() {
            let result = [];
            for (const property in paybackEstimations) {
                result.push({
                    label: this.$t(`paybackEstimations.${property}`),
                    value: paybackEstimations[property],
                });
            }
            return result;
        },
        duplicabilityLevelOptions() {
            let result = [];
            for (const property in duplicabilityLevels) {
                result.push({
                    label: this.$t(`duplicabilityLevels.${property}`),
                    value: duplicabilityLevels[property],
                });
            }
            return result;
        },
    },
    watch: {
        languages() {
            this.initDetails();
        },
    },
    validations() {
        return {
            entreprise: { required },
            hasFiles: { required },
            depositorName: { required },
            safetyLeaderName: { required },
            categorie: { required },
            complexityLevel: { required },
            implementationCost: { required },
            paybackEstimation: { required },
            duplicabilityLevel: { required },
        };
    },
    mixins: [localeChangeDetectionMixins, roleMixins, fichiersServiceApi],
    emits: ["onSubmit", "onClose"],
    beforeMount() {
        this.getEntreprises();
        this.getTags();
        this.initDetails();
    },
    mounted() {
        if (!this.depositorName) {
            this.depositorName = this.user.nom;
        }
        if (this.entreprise === undefined) {
            this.entreprise = this.user.idEntreprise;
        }

        if (this.bestPractice && this.bestPractice.categories) {
            this.bestPractice.categories.forEach(e => {
                this.categorie[e.id] = true
            })
        }

    },
    methods: {
        mapCategory(category, level) {
            let result = {};
            if (!this.categories[category.id]) {
                this.categories[category.id] = category
            }
            if (
                !this.categorie &&
                this.bestPractice &&
                this.bestPractice.idCategorie === category.id
            ) {
                // case of updating
                this.categorie = category.id;
            }

            const mappedChilds = []
            if (category.children && category.children.length) {
                const childs = category.children.map((cat) => {
                    return this.mapCategory(cat, level + 1)
                }).map(e => {
                    e.label = `${' '.repeat(level + 1)}${e.label}`
                    return e
                })
                mappedChilds.push(...childs)
            }

            result = {
                key: category.id,
                label: category.detail ? category.detail[0].libelle + "" : "",
                type: level === 0 ? 'parent' : 'child',
                level: level + 1,
                children: mappedChilds.sort((a, b) => {
                    const aSplitted = `${a.label.trim()}`.split(/ /)[0]?.split(".");
                    const bSplitted = `${b.label.trim()}`.split(/ /)[0]?.split(".");
                    const lastASplitted = [...aSplitted].pop();
                    const lastBSplitted = [...bSplitted].pop();
                    return +lastASplitted - +lastBSplitted;
                }),
            };
            return result;
        },
        getCategoryLabel(categoryId) {
            return this.categories[categoryId]?.detail[0]?.libelle
        },
        updateTreeSelectOnRemove(categoryId) {
            delete this.categorie[categoryId]
        },
        unselectParentNode(node) {
            if (node && node.level === 1) {
                setTimeout(() => {
                    delete this.categorie[node.key]
                }, 1)
            }
        },
        getEntreprises() {
            return entreprisesServiceApi
                .getEntreprises()
                .then((result) => {
                    this.entreprises = result;
                    return Promise.resolve(result);
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        },
        getTags() {
            return tagsServiceApi
                .getTags()
                .then((result) => {
                    this.availableTags = result;
                    return Promise.resolve(result);
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        },

        postTag() {
            if (!this.newTag && this.newTag === "") {
                return;
            }
            return tagsServiceApi
                .postTag({
                    libelle: this.newTag,
                })
                .then((result) => {
                    this.availableTags.push(result);
                    document.dispatchEvent(
                        new CustomEvent(apiEventTypes.API_SUCCESS, {
                            detail: {
                                title: this.$t("popup_messages.success_title"),
                                message: this.$t("popup_messages.success_message"),
                            },
                        })
                    );
                    return Promise.resolve(result);
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        },
        handleDeleteTag(id) {
            this.showConfirmDeleteTag = true;
            this.tagIdToDelete = id;
        },
        confirmDeleteTag() {
            return tagsServiceApi
                .deleteTag(this.tagIdToDelete)
                .then(() => {
                    this.availableTags = this.availableTags.filter(
                        (el) => el.id != this.tagIdToDelete
                    );
                    this.tags = this.tags.filter((el) => el.id != this.tagIdToDelete);

                    document.dispatchEvent(
                        new CustomEvent(apiEventTypes.API_SUCCESS, {
                            detail: {
                                title: this.$t("popup_messages.success_delete_title"),
                                message: this.$t("popup_messages.success_delete_message"),
                            },
                        })
                    );
                    return Promise.resolve();
                })
                .catch((err) => {
                    return Promise.reject(err);
                })
                .finally(() => {
                    this.cancelDeleteTag();
                });
        },
        cancelDeleteTag() {
            this.showConfirmDeleteTag = false;
            this.tagIdToDelete = NaN;
        },
        initDetails() {
            if (this.update) {
                this.details = this.bestPractice.detail;
                return;
            }
            if (this.languages && this.languages.length > 0) {
                const availableLocales = [...this.availableLocales];
                const locale = this.$i18n.locale;
                const indexOfLocale = availableLocales.findIndex((e) => e === locale);
                if (indexOfLocale >= 0) {
                    availableLocales.splice(indexOfLocale, 1);
                    availableLocales.unshift(locale);
                }
                availableLocales.forEach((loc) => {
                    const langue = this.languages.find((l) => l.codeIso === loc);
                    this.details.push({
                        titre: "",
                        description: "",
                        idLangue: langue.id,
                        langue,
                    });
                });
            }
        },
        updateDetail(event, index) {
            this.details[index] = event;
        },
        onClose() {
            this.$emit("onClose");
        },
        submit() {
            let bestpracticesToSave = {};
            this.submitted = true;
            this.hasFiles =
                this.fichiers &&
                    this.fichiers.filter((fichier) => fichier.toDelete !== true).length > 0
                    ? true
                    : undefined;
            if (this.v$.$invalid) {
                document.dispatchEvent(
                    new CustomEvent(apiEventTypes.ERROR, {
                        detail: {
                            title: this.$t("popup_messages.required_title"),
                            message: this.$t("popup_messages.required"),
                        },
                    })
                );
                return;
            } else {
                const selectedCategoriesIds = Object.keys(this.categorie)
                const categories = selectedCategoriesIds.map((e) => this.categories[e]);
                bestpracticesToSave = {
                    id: this.update ? this.bestPractice.id : undefined,
                    idEntreprise: this.entreprise,
                    depositorName: this.depositorName,
                    safetyLeaderName: this.safetyLeaderName,
                    categories: categories.map((e) => {
                        return {
                            id: e.id,
                            idRubrique: e.idRubrique,
                            externalId: e.externalId,
                            idCategorieParent: e.idCategorieParent,
                        };
                    }),
                    complexityLevel: this.complexityLevel,
                    implementationCost: this.implementationCost,
                    paybackEstimation: this.paybackEstimation,
                    duplicabilityLevel: this.duplicabilityLevel,
                    otherContributorName: this.otherContributorName,
                    otherContributorCoordinate: this.otherContributorCoordinate,
                    fichiers: this.fichiers,
                    photos: this.photos,
                    detail: this.details,
                    tags: this.tags,
                };
                this.$emit("onSubmit", bestpracticesToSave);
            }
        },

        async translateTo(target, onlyEditor = false) {
            this.beingTranslated = true;
            const primaryLangueAccident = this.details.find(
                (e) => e.idLangue !== target.id
            );
            const stringsToTranslate = onlyEditor
                ? [primaryLangueAccident.description]
                : [primaryLangueAccident.titre, primaryLangueAccident.description];

            const translatedData = await translate(
                stringsToTranslate,
                target.codeIso
            );

            if (stringsToTranslate.length === translatedData.length) {
                const newDetails = [...this.details];
                const indexOfDetails = newDetails.findIndex(
                    (e) => e.langue.codeIso === target.codeIso
                );
                const details = newDetails[indexOfDetails];
                if (!onlyEditor) {
                    details.titre = translatedData.splice(0, 1)[0];
                }
                details.description = translatedData.splice(0, 1)[0];
                this.details = [...newDetails];
            }

            this.beingTranslated = false;
        },

        async translateToEditor(target) {
            this.translateTo(target, true);
        },
    },
};
</script>

<style lang="scss" scoped>
.tags {
    &>div {
        margin: 0;
    }
}

.flex {
    .p-float-label {
        flex: 1;
        margin: 0 5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
</style>
