import configuration from "../config";
import axios from "./axiosConfiguration";

const endpoint = `${configuration.api_url}declarations`;

const getDeclarations = (page, elementsPerPage) => {
  const options = {
    params: {
      page,
      elementsPerPage,
    },
  };
  return axios
    .get(endpoint, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const saveDeclarations = async (declaration) => {
    return axios
      .post(`${endpoint}`, declaration)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

const deleteDeclaration = async (declarationId) => {
  return axios
    .delete(`${endpoint}/${declarationId}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
  
export default {
  getDeclarations,
  saveDeclarations,
  deleteDeclaration
};
