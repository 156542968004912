import { createI18n } from "vue-i18n";
import messages from "./messages";
import datetimeFormats from "./datetimeFormats";
import config from "../config";
import moment from "moment";

moment.locale(config.defaultLocale);

const i18n = createI18n({
  locale: config.defaultLocale,
  fallbackLocale: config.localeFallback, //if no locale for current navigator setting, english locale then
  messages,
  datetimeFormats,
});

export default i18n;
