<template>
  <div id="home">
    <presentation-block />
    <as-container class="bg-radial">
      <objectives-block />
    </as-container>
    <as-container>
      <as-quote><span v-html="$t('home.quote')" /></as-quote>
    </as-container>
    <as-container
      v-if="(isSafetyLeader || isAdmin) && filteredThreeLastAccidents"
      class="bg-radial"
    >
      <as-title
        :title="$t('home.last_accident_title')"
        :size="1"
        style="text-align: center"
        class="w-100 color-white"
      ></as-title>
      <as-block :shadow="true" class="mt-6 pt-6">
        <Carousel :value="filteredThreeLastAccidents" :numVisible="1">
          <template #item="slotProps">
            <accident-block
              :accidentData="slotProps.data"
              :date="
                slotProps.data && slotProps.data.date
                  ? slotProps.data.date
                  : undefined
              "
              :location="
                slotProps && slotProps.data.lieu
                  ? slotProps.data.lieu
                  : $t('unknown')
              "
              :cause="
                slotProps && slotProps.data.causes
                  ? slotProps.data.causes
                  : $t('unknown')
              "
              :improvement="
                slotProps && slotProps.data.mesures
                  ? slotProps.data.mesures
                  : $t('unknown')
              "
              showPdfButton="true"
            />
          </template>
        </Carousel>
      </as-block>
    </as-container>
  </div>
</template>

<script>
// @ is an alias to /src
import AsContainer from "@/components/Containers/AsContainer.vue";
import AsBlock from "@/components/Containers/AsBlock.vue";
import AsQuote from "@/components/Elements/AsQuote.vue";
import PresentationBlock from "@/components/Home/PresentationBlock.vue";
import ObjectivesBlock from "@/components/Home/ObjectivesBlock.vue";
import AccidentBlock from "../components/Home/AccidentBlock.vue";
import AsTitle from "../components/Elements/AsTitle.vue";
import Carousel from "primevue/carousel";

import accidentsServiceApi from "../services/accidentsServiceApi";
import localeChangeDetectionMixin from "../mixins/localeChangeDetectionMixins";
import roleMixins from "@/mixins/roleMixins";

export default {
  name: "Home",
  components: {
    AsContainer,
    AsBlock,
    AsQuote,
    PresentationBlock,
    ObjectivesBlock,
    AsTitle,
    Carousel,
    AccidentBlock,
  },
  data() {
    return {
      lastAccident: undefined,
      isLastAccidentBusy: false,
      threeLastAccidents: [],
      filteredThreeLastAccidents: [],
    };
  },
  mixins: [localeChangeDetectionMixin, roleMixins],
  beforeMount() {
    this.getLastAccident();
  },
  methods: {
    onLocaleChange() {
      this.filteredThreeLastAccidents = [];
      setTimeout(() => {
        this.setFilteredThreeLastAccidents();
      }, 1);
    },

    getLastAccident() {
      this.isLastAccidentBusy = true;
      accidentsServiceApi
        .getLastAccidentFromNumber(3)
        .then((response) => {
          this.threeLastAccidents = response;
          this.isLastAccidentBusy = false;
          this.setFilteredThreeLastAccidents();
        })
        .catch(() => {
          this.isLastAccidentBusy = false;
        });
    },
    setFilteredThreeLastAccidents() {
      this.filteredThreeLastAccidents = this.threeLastAccidents.map((e) => {
        const detail = e.details.find(
          (d) => d.langue.codeIso === this.$i18n.locale
        );
        return {
          titre: detail ? detail.titre : "",
          causes: detail ? detail.causes : "",
          date: e.date,
          id: e.id,
          mesures: detail ? detail.mesures : "",
          description: detail ? detail.description : "",
          fichiers: detail ? detail.fichiers : [],
          image: e.image,
          header: {
            entreprise: e.entreprise.details.nom,
            ...e.declarationAccident,
          },
        };
      });
    },
  },
};
</script>
