<template>
  <div id="content">
    <as-container-block>
      <as-fichiers-list
        :rubriques="rubriques"
        :editMode="editMode"
        @onDelete="deleteDocument"
        @onUpload="uploadDocument"
        @onSelect="getFileInfo"
      />
    </as-container-block>
  </div>
</template>

<script>
import AsContainerBlock from "@/components/Containers/AsContainerBlock.vue";
import AsFichiersList from "@/components/Elements/AsFichiersList.vue";
import rubriquesServiceApi from "@/services/rubriquesServiceApi";
import localeChangeDetectionMixin from "@/mixins/localeChangeDetectionMixins";

export default {
  name: "Content",
  components: {
    AsContainerBlock,
    AsFichiersList,
  },
  data() {
    return {
      rubriques: [],
    };
  },
  mixins: [localeChangeDetectionMixin],
  beforeMount() {
    this.reloadRubriques();
  },
  methods: {
    reloadRubriques() {
      rubriquesServiceApi.getRubriques().then((result) => {
        this.rubriques = result;
      });
    },
    onLocaleChange() {
      this.reloadRubriques();
      this.$emit("onLocaleChange");
    },
  },
};
</script>
