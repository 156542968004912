const home = 1;
const accident_share = 2;
const community = 3;
const safe_place = 4;
const reporting = 5;
const referential = 6;
const statistics = 7;
const best_practices = 8;
const workplace = 9;
const plan = 10;
const content = 11;
const agenda = 12;
const iso45001 = 13;

export default {
  home,
  accident_share,
  community,
  safe_place,
  reporting,
  referential,
  statistics,
  best_practices,
  workplace,
  plan,
  content,
  agenda,
  iso45001,
};
