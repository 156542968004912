<template>
  <div id="evaluation">
    <div class="header grid">
      <div class="col-12 section-title chiffre-line">
        {{ $t("statistics.evaluation_title")
        }}<as-icon :source="iconImg" respectRatio class="ml-2" />
      </div>
    </div>
    <div class="grid">
      <div class="col-7 chiffre-line-demi">
        {{ $t("statistics.evaluation_leadership") }}
      </div>
      <div v-if="editMode" class="col-5 chiffre-line-demi">
        <Dropdown
          v-model="modelValue.evalLeadershipDirigeants"
          :options="evaluationModes"
          optionLabel="label"
          optionValue="value"
        />
      </div>
      <div
        v-else
        class="col-5 chiffre-line-demi evaluation"
        :class="cssClass(modelValue.evalLeadershipDirigeants)"
      >
        {{ showLabel(modelValue.evalLeadershipDirigeants) }}
      </div>
    </div>
    <div class="grid">
      <div class="col-7 chiffre-line-demi">
        {{ $t("statistics.evaluation_communication") }}
      </div>
      <div v-if="editMode" class="col-5 chiffre-line-demi">
        <Dropdown
          v-model="modelValue.evalCommunicationAnimation"
          :options="evaluationModes"
          optionLabel="label"
          optionValue="value"
        />
      </div>
      <div
        v-else
        class="col-5 chiffre-line-demi evaluation"
        :class="cssClass(modelValue.evalCommunicationAnimation)"
      >
        {{ showLabel(modelValue.evalCommunicationAnimation) }}
      </div>
    </div>
    <div class="grid">
      <div class="col-7 chiffre-line-demi">
        {{ $t("statistics.evaluation_risks_control") }}
      </div>
      <div v-if="editMode" class="col-5 chiffre-line-demi">
        <Dropdown
          v-model="modelValue.evalMaitriseRisques"
          :options="evaluationModes"
          optionLabel="label"
          optionValue="value"
        />
      </div>
      <div
        v-else
        class="col-5 chiffre-line-demi evaluation"
        :class="cssClass(modelValue.evalMaitriseRisques)"
      >
        {{ showLabel(modelValue.evalMaitriseRisques) }}
      </div>
    </div>
    <div class="grid">
      <div class="col-7 chiffre-line-demi">
        {{ $t("statistics.evaluation_security_managment") }}
      </div>
      <div v-if="editMode" class="col-5 chiffre-line-demi">
        <Dropdown
          v-model="modelValue.evalManagementSecurite"
          :options="evaluationModes"
          optionLabel="label"
          optionValue="value"
        />
      </div>
      <div
        v-else
        class="col-5 chiffre-line-demi evaluation"
        :class="cssClass(modelValue.evalManagementSecurite)"
      >
        {{ showLabel(modelValue.evalManagementSecurite) }}
      </div>
    </div>
    <div class="grid">
      <div class="col-7 chiffre-line-demi">
        {{ $t("statistics.evaluation_formation") }}
      </div>
      <div v-if="editMode" class="col-5 chiffre-line-demi">
        <Dropdown
          v-model="modelValue.evalFormation"
          :options="evaluationModes"
          optionLabel="label"
          optionValue="value"
        />
      </div>
      <div
        v-else
        class="col-5 chiffre-line-demi evaluation"
        :class="cssClass(modelValue.evalFormation)"
      >
        {{ showLabel(modelValue.evalFormation) }}
      </div>
    </div>
    <div class="grid">
      <div class="col-7 chiffre-line-demi">
        {{ $t("statistics.evaluation_coordination") }}
      </div>
      <div v-if="editMode" class="col-5 chiffre-line-demi">
        <Dropdown
          v-model="modelValue.evalCoordinationCentraleLocale"
          :options="evaluationModes"
          optionLabel="label"
          optionValue="value"
        />
      </div>
      <div
        v-else
        class="col-5 chiffre-line-demi evaluation"
        :class="cssClass(modelValue.evalCoordinationCentraleLocale)"
      >
        {{ showLabel(modelValue.evalCoordinationCentraleLocale) }}
      </div>
    </div>
    <div class="grid">
      <div class="col-7 chiffre-line-demi">
        {{ $t("statistics.evaluation_security_referential") }}
      </div>
      <div v-if="editMode" class="col-5 chiffre-line-demi">
        <Dropdown
          v-model="modelValue.evalReferentielSecurite"
          :options="evaluationModes"
          optionLabel="label"
          optionValue="value"
        />
      </div>
      <div
        v-else
        class="col-5 chiffre-line-demi evaluation"
        :class="cssClass(modelValue.evalReferentielSecurite)"
      >
        {{ showLabel(modelValue.evalReferentielSecurite) }}
      </div>
    </div>
    <div class="grid">
      <div class="col-7 chiffre-line-demi">
        {{ $t("statistics.evaluation_safety_performance") }}
      </div>
      <div v-if="editMode" class="col-5 chiffre-line-demi">
        <Dropdown
          v-model="modelValue.evalAnalyseAnimationPerf"
          :options="evaluationModes"
          optionLabel="label"
          optionValue="value"
        />
      </div>
      <div
        v-else
        class="col-5 chiffre-line-demi evaluation"
        :class="cssClass(modelValue.evalAnalyseAnimationPerf)"
      >
        {{ showLabel(modelValue.evalAnalyseAnimationPerf) }}
      </div>
    </div>
    <div class="grid">
      <div class="col-7 chiffre-line-demi">
        {{ $t("statistics.evaluation_3_year_plan") }}
      </div>
      <div v-if="editMode" class="col-5 chiffre-line-demi">
        <Dropdown
          v-model="modelValue.evalPlan3Ans"
          :options="evaluationModes"
          optionLabel="label"
          optionValue="value"
        />
      </div>
      <div
        v-else
        class="col-5 chiffre-line-demi evaluation"
        :class="cssClass(modelValue.evalPlan3Ans)"
      >
        {{ showLabel(modelValue.evalPlan3Ans) }}
      </div>
    </div>
    <div class="grid last">
      <div class="col-7"></div>
      <div class="col-5"></div>
    </div>
  </div>
</template>

<script>
import vmodelMixins from "../../mixins/vmodelMixins";
import AsIcon from "../Elements/AsIcon.vue";
import evaluations from "../../constantes/evaluations";
import Dropdown from "primevue/dropdown";
export default {
  name: "EvaluationBlock",
  components: {
    AsIcon,
    Dropdown,
  },
  props: {
    editMode: Boolean,
  },
  data() {
    return {
      iconImg: require("../../assets/images/icons/picto-chiffre-evaluation.svg"),
    };
  },
  computed: {
    evaluationModes() {
      let result = [];
      for (const property in evaluations) {
        result.push({
          label: this.$t(`evaluations.${property}`),
          value: evaluations[property],
        });
      }
      return result;
    },
  },
  mixins: [vmodelMixins],
  methods: {
    showLabel(value) {
      if (value) {
        return this.evaluationModes.find((el) => el.value === value).label;
      } else {
        return this.evaluationModes[0].label;
      }
    },
    cssClass(value) {
      switch (value) {
        case evaluations.NOT_STARTED:
        case evaluations.IN_PROGRESS:
          return "en-cours";
        case evaluations.OK:
          return "";
        default:
          return "en-cours";
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
#evaluation {
  .p-dropdown {
    width: 100%;
  }
  background-color: $default_lighter_color;
  height: 100%;
  overflow: hidden;
  .grid {
    // &:not(.header) {
    // }
    .col-7 {
      background-color: $default_light_color;
      text-align: left;
    }
    &.header {
      background-color: $default_color;
    }
    &.last {
      //display: flex;
      height: 100%;
      //overflow: hidden;
    }
    .evaluation {
      font-weight: bold;
      color: $success_color;
      display: flex;
      align-items: center;
      justify-content: center;
      &.en-cours {
        color: $error_color;
      }
    }
  }
}
</style>
