<template>
  <div>
    <div class="flex flex-column" v-if="errors">
      <span v-for="(error, index) of errors" :key="index">
        <small class="p-error">{{
          $t(`validations_form.${error.$validator}`)
        }}</small>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsInputError",
  props: {
    errors: Array,
  },
};
</script>
