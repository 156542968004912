<template>
  <div class="flex justify-content-center">
    <h2>{{ $t("home.quoteTitle") }}</h2>
  </div>
  <blockquote class="quote">
    <p>
      <slot>{{ text }}</slot>
    </p>
  </blockquote>
</template>

<script>
export default {
  name: "AsQuote",
  props: {
    text: String,
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/fonts.scss";

.article .ql-snow .ql-editor blockquote,
.article blockquote,
.quote {
  text-align: center;
  border: 0;
  display: flex;
  justify-content: center;
  font-style: italic;
  font-size: $font_quote_size;
  font-weight: 500;
  line-height: $font_quote_size + $delta_line_height;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 1;
  position: relative;

  p {
    position: relative;
    max-width: 1200px;
    margin-top: 20px;
    text-align: justify;
  }
  
  &::before {
    content: " ";
    display: block;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    background-image: url("@/assets/images/quote.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: -50px;
    margin-top: -20px;
    z-index: -1;
  }
}
</style>
