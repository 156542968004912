import roles from "../constantes/roles";
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            userRole: "getRole",
        }),
        isAdmin() {
            return (
                [roles.ADMIN, roles.ADMIN_TEAM].includes(this.userRole)  //|| process.env.NODE_ENV === "development"
            );
        },
        isSafetyLeader() {
            return (
                [roles.SAFETY_LEADER, roles.SAFETY_TEAM].includes(this.userRole)// || process.env.NODE_ENV === "development"
            );
        },
        isGuest() {
            return (
                this.userRole === roles.GUEST
            );
        },
    },
};
