export const SALES_SPACE = "sales space";
export const RESERVE_STORE = "reserve store";
export const WAREHOUSE = "warehouse";
export const OUTDOOR_ON_SITE = "outdoor on site";
export const OUTDOOR_OFFSITE = "outdoor offsite";
export const AT_CUSTOMER = "at customer";
export const OFFICE = "office";
export const PARKING = "parking";

export default {
  SALES_SPACE,
  RESERVE_STORE,
  WAREHOUSE,
  OUTDOOR_ON_SITE,
  OUTDOOR_OFFSITE,
  AT_CUSTOMER,
  OFFICE,
  PARKING,
};
