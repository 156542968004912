import configCommon from "./config.common.json";
import devConfig from "./config.dev.json";
const deployConfig = {
    "api_url": window.location.origin+'/api/',
    "api_auth_url": window.location.origin+'/auth/',
    "max_file_upload": process.env.VUE_APP_MAX_FILE_UPLOAD,
    "chunk_size": process.env.VUE_APP_CHUNK_SIZE,
    "download_rangesize": process.env.VUE_APP_DOWNLOAD_RANGESIZE,
    "defaultLocale": process.env.VUE_APP_DEFAULT_LOCALE,
    "localeFallback": process.env.VUE_APP_LOCALE_FALLBACK,
    "supportedLangues": `${process.env.VUE_APP_SUPPORTED_LANGUES}`.split(','),
    "localizationHeader": process.env.VUE_APP_LOCALIZATION_HEADER,
    "errorMessagesLifetime": process.env.VUE_APP_ERROR_MESSAGES_LIFETIME,
    "successMessagesLifetime": process.env.VUE_APP_SUCCESS_MESSAGES_LIFETIME,
    "declarationModeleHelp": process.env.VUE_APP_DECLARATION_MODELE_HELP,
    "declarationAnalyzeHelp": process.env.VUE_APP_DECLARATION_ANALYZE_HELP,
}

const configuration = process?.env.NODE_ENV === "development" ? {
    ...configCommon,
    ...devConfig
} :
    {
        ...configCommon,
        ...deployConfig
    }

console.log('configuration =>', configuration)
export default configuration;
