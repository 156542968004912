<template>
  <form id="feedback-form" @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid">
      <div class="col-12">
        <as-title class="title" :title="$t('feedback.form.title')" :size="3" />
      </div>
      <div class="col-12">
        <Textarea
          type="text"
          v-model="v$.text.$model"
          :autoResize="true"
          rows="6"
          cols="30"
          class="w-100"
        />
        <as-input-error :errors="v$.text.$silentErrors" v-show="submitted" />
      </div>
    </div>
    <div class="grid fixed-bottom">
      <div class="col flex justify-content-end">
        <Button
          :label="$t('form.cancel_button')"
          class="button-default"
          @click="onClose"
        />
        <Button
          type="submit"
          :label="$t('form.submit_button')"
          class="button-primary ml-2"
        />
      </div>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import AsTitle from "../Elements/AsTitle.vue";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import AsInputError from "./AsInputError.vue";

export default {
  name: "FeedbackForm",
  components: {
    AsTitle,
    Button,
    Textarea,
    AsInputError,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      text: "",
      submitted: false,
    };
  },
  validations() {
    return {
      text: { required },
    };
  },
  emits: ["onClose", "onSubmit"],
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;
      if (!isFormValid) {
        return;
      }
      this.$emit("onSubmit", this.text);
      this.onClose();
    },
    onClose() {
      this.text = "";
      this.submitted = false;
      this.$emit("onClose");
    },
  },
};
</script>
