<template>
  <div id="performances">
    <div class="header grid">
      <div class="col-12 section-title chiffre-line">
        {{ $t("statistics.performances_title") }}
        <as-icon :source="iconPerfImg" respectRatio class="ml-2" />
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div
        v-for="(tf, index) in modelValue.tauxFrequence"
        :key="index"
        class="col-3"
      >
        <span> {{ $t("statistics.tf") }} <br />{{ tf.annee }} </span>
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div
        v-for="(tf, index) in modelValue.tauxFrequence"
        :key="index"
        class="col-3"
      >
        <b v-if="editMode"
          ><InputNumber
            v-model="modelValue.tauxFrequence[index].value"
            mode="decimal"
            :minFractionDigits="1"
            :maxFractionDigits="1"
          />
        </b>
        <b v-else>{{ tf.value ? tf.value : "/" }} </b>
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div
        v-for="(tg, index) in modelValue.tauxGravite"
        :key="index"
        class="col-3"
      >
        <span> {{ $t("statistics.tg") }} <br />{{ tg.annee }} </span>
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div
        v-for="(tg, index) in modelValue.tauxGravite"
        :key="index"
        class="col-3"
      >
        <b v-if="editMode"
          ><InputNumber
            v-model="modelValue.tauxGravite[index].value"
            mode="decimal"
            :minFractionDigits="1"
            :maxFractionDigits="1"
          />
        </b>
        <b v-else>{{ tg.value ? tg.value : "/" }} </b>
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div
        v-for="(nbAt, index) in modelValue.nbAt"
        :key="index"
        class="col-3"
      >
        <span> {{ $t("statistics.nb_at") }} <br />{{ nbAt.annee }} </span>
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div
        v-for="(nbAt, index) in modelValue.nbAt"
        :key="index"
        class="col-3"
      >
        <b v-if="editMode"
          ><InputNumber v-model="modelValue.nbAt[index].value" />
        </b>
        <b v-else>{{ nbAt.value ? nbAt.value : "/" }} </b>
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div
        v-for="(variation, index) in modelValue.variationHT"
        :key="index"
        class="col-3"
      >
        <span>
          {{ $t("statistics.variation_ht") }} <br />{{ variation.annee }}
        </span>
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div
        v-for="(vht, index) in modelValue.variationHT"
        :key="index"
        class="col-3"
      >
        <b v-if="editMode"
          ><InputText v-model="modelValue.variationHT[index].value" />
        </b>
        <b v-else>{{ vht.value ? vht.value : "/" }} </b>
      </div>
    </div>
    <div class="header grid chiffre-line">
      <div class="col head section-title chiffre-line">
        {{ $t("statistics.audit_data") }}
        <as-icon :source="iconAuditImg" respectRatio class="ml-2" />
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div class="col-3"></div>
      <div
        v-for="(opi, index) in modelValue.opinion"
        :key="index"
        class="col-3"
      >
        <span>{{ opi.annee }} </span>
      </div>
    </div>
    <div class="grid chiffre-line-demi">
      <div class="col-3">{{ $t("statistics.opinion") }}</div>
      <div
        v-for="(op, index) in modelValue.opinion"
        :key="index"
        class="col-3"
      >
        <b v-if="editMode"
          ><InputText v-model="modelValue.opinion[index].value" />
        </b>
        <b v-else>{{ op.value ? op.value : "/" }} </b>
      </div>
    </div>
  </div>
</template>

<script>
import AsIcon from "../Elements/AsIcon.vue";
import vmodelMixins from "../../mixins/vmodelMixins";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";

export default {
  name: "PerformanceBlock",
  components: {
    AsIcon,
    InputNumber,
    InputText,
  },
  props: {
    editMode: Boolean,
  },
  data() {
    return {
      iconPerfImg: require("../../assets/images/icons/picto-chiffre-perf.svg"),
      iconAuditImg: require("../../assets/images/icons/picto-chiffre-audit.svg"),
    };
  },
  mixins: [vmodelMixins],
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";

#performances {
  display: flex;
  flex-direction: column;
  background-color: $quaternary_lighter_color;
  height: 100%;
  .p-inputtext {
    width: 100%;
  }
  .grid {
    &.header {
      background-color: $quaternary_color;
    }
    &.chiffre-line-demi:nth-child(2n) {
      background-color: $quaternary_light_color;
    }
    &.chiffre-line-demi div {
      border: unset !important;
    }
    &.chiffre-line-demi {
      &:nth-last-child(2) {
        background-color: $quaternary_light_color;
      }
      &:last-child {
        background-color: $quaternary_lighter_color;
        border-bottom: unset !important;
      }
    }
  }
}
</style>
