import configuration from "../config";
import axios from "./axiosConfiguration";

// import evaluations from "../constantes/evaluations";

const endpoint = `${configuration.api_url}chiffres`;

export const getChiffres = (idEntreprise) => {
  const options = {
    params: {
      idEntreprise,
    },
  };
  return axios
    .get(endpoint, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveChiffres = (id, chiffres) => {
  return axios
    .put(`${endpoint}/${id}`, chiffres)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getChiffres,
  saveChiffres,
};
