<template>
  <div>
    <div class="grid fixed-bottom">
      <as-title :title="$t('feedback.listTitle')" class="col" :size="3" />
      <div class="col flex justify-content-end">
        <Button
          @click="onClose"
          icon="pi pi-times"
          class="p-button-sm p-button-rounded button-default mr-1"
        />
      </div>
    </div>
    <br />
    <DataTable
      :value="feedbacks"
      :paginator="true"
      :rows="5"
      :totalRecords="feedbacks.length"
      responsiveLayout="scroll"
    >
      <Column field="utilisateur" :header="$t('feedback.columns.user')">
        <template #body="slotProps">
          {{ slotProps.data.utilisateur.nom }}
          {{ slotProps.data.utilisateur.prenom }}
        </template>
      </Column>
      <Column field="rubrique" :header="$t('feedback.columns.rubrique')">
        <template #body="slotProps">
          {{ $t(`menu.${slotProps.data.rubrique.codeRubrique}`) }}
        </template>
      </Column>
      <Column field="date" :header="$t('feedback.columns.date')">
        <template #body="slotProps">
          {{ $d(slotProps.data.date, "shortDatetime") }}
        </template>
      </Column>
      <Column field="lieu" :header="$t('feedback.columns.text')">
        <template #body="slotProps">
          {{ slotProps.data.text }}
        </template>
      </Column>
      <Column :header="$t('feedback.columns.action')">
        <template #body="slotProps">
          <Button
            class="p-button-danger"
            icon="pi pi-trash"
            iconPos="right"
            @click="deleteFeedback(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import AsTitle from "../Elements/AsTitle.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import { ellipsis } from "../../util";

export default {
  name: "FeedbackList",
  components: {
    AsTitle,
    DataTable,
    Column,
    Button,
  },
  props: {
    feedbacks: Array,
  },
  emits: ["onClose", "onDelete"],
  data() {
    return {};
  },
  methods: {
    ellipsis(value) {
      return ellipsis(value);
    },
    deleteFeedback(feedbackId) {
      this.$emit("onDelete", feedbackId);
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>
