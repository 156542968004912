import configuration from "../config";
import axios from "./axiosConfiguration";

const endpoint = `${configuration.api_url}bestpractices`;

const getBestPractice = async (idBestPractice) => {
  return axios
    .get(`${endpoint}/one/${idBestPractice}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
const getBestPractices = async (idCategorie) => {
  const options = {
    params: {
      idCategorie,
    },
  };
  return axios
    .get(endpoint, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getCountByCategories = async () => {
  return axios
    .get(`${endpoint}/countbycategorie/`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getBestPracticesByResearch = async (term) => {
  return axios
    .get(`${endpoint}/terms/${term}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const deleteBestPractice = (idBestPractices) => {
  return axios
    .delete(`${endpoint}/${idBestPractices}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const updateBestPractice = (bestPractice) => {
  return axios
    .patch(`${endpoint}/${bestPractice.id}`, bestPractice)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const updateBestPracticeConsultationCounter = (bestPracticeId) => {
  return axios
    .get(`${endpoint}/updateCounter/${bestPracticeId}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const saveBestPractices = (bestPractice) => {
  return axios
    .post(endpoint, bestPractice)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getBestPracticesNotations = () => {
  return axios
    .get(`${endpoint}-notation`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const saveBestPracticesNotations = (notation) => {
  return axios
    .post(`${endpoint}-notation`, notation)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getBestPracticesTop = async (key) => {
  return axios
    .get(`${endpoint}/${key}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getBestPractice,
  getBestPractices,
  getCountByCategories,
  getBestPracticesByResearch,
  deleteBestPractice,
  saveBestPractices,
  updateBestPractice,
  updateBestPracticeConsultationCounter,
  getBestPracticesNotations,
  saveBestPracticesNotations,
  getBestPracticesTop,
};
