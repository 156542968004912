import configuration from "../config";
import axios from "./axiosConfiguration";

const endpoint = `${configuration.api_url}feedback`;

export const getFeedbacks = () => {
  return axios
    .get(endpoint)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveFeedback = async (feedback) => {
  return axios
    .post(`${endpoint}`, feedback)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteFeedback = async (feedbackId) => {
  return axios
    .delete(`${endpoint}/${feedbackId}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
