<template>
  <div id="list-accidents">
    <AsDataTableSkeleton
      v-if="accidents.length === 0 && isBusy"
      :rows="rows"
      :columns="skeletonColumns"
    />
    <DataTable
      v-else
      :value="accidents"
      :loading="isBusy"
      :paginator="true"
      :rows="rows"
      :totalRecords="totalRecords"
      :lazy="true"
      @page="onPageAccidents($event)"
      @row-click="onSelectAccident"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <h5 class="m-0">{{ $t("share_accident.accident_table_title") }}</h5>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="keyword"
              :placeholder="$t('share_accident.keyword_placeholder')"
              @change="onKeywordChange"
            />
          </span>
        </div>
      </template>
      <template #empty> {{ $t("datatable.no_element_text") }}</template>
      <template #loading> {{ $t("datatable.loading_text") }} </template>
      <Column :header="$t('share_accident.accident_or_near_miss')">
        <template #body="slotProps">
          {{
            slotProps.data.declarationAccident &&
            slotProps.data.declarationAccident.presqueAccident
              ? $t("share_accident.near_miss")
              : $t("share_accident.accident")
          }}
        </template>
      </Column>
      <Column :header="$t('share_accident.title_column')">
        <template #body="slotProps">
          {{ getDetailsFromLocale(slotProps.data.details, "titre") }}
        </template>
      </Column>
      <Column :header="$t('share_accident.description_column')">
        <template #body="slotProps">
          {{ getDetailsFromLocale(slotProps.data.details, "description") }}
        </template>
      </Column>
      <Column :header="$t('share_accident.cause_column')">
        <template #body="slotProps">
          {{ getDetailsFromLocale(slotProps.data.details, "causes") }}
        </template>
      </Column>
      <Column :header="$t('share_accident.datetime_column')">
        <template #body="slotProps">
          {{ $d(slotProps.data.date, "shortDatetime") }}
        </template>
      </Column>
      <Column :header="$t('share_accident.company_column')">
        <template #body="slotProps">
          {{ slotProps.data.entreprise.details.nom }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import AsDataTableSkeleton from "../Elements/Skeletons/AsDataTableSkeleton.vue";

import InputText from "primevue/inputtext";
import { ellipsis } from "../../util";
export default {
  name: "ListAccident",
  components: {
    DataTable,
    Column,
    AsDataTableSkeleton,
    InputText,
  },
  props: {
    isBusy: Boolean,
    accidents: Array,
    rows: Number,
    totalRecords: Number,
  },
  data() {
    return {
      keyword: "",
      skeletonColumns: [
        this.$t("share_accident.title_column"),
        this.$t("share_accident.description_column"),
        this.$t("share_accident.cause_column"),
        this.$t("share_accident.datetime_column"),
        this.$t("share_accident.company_column"),
      ],
    };
  },
  emits: ["onPage", "onKeywordChange", "onSelectAccident"],
  methods: {
    onPageAccidents(event) {
      let evt = {
        event,
        keyword: this.keyword,
      };
      this.$emit("onPage", evt);
    },
    onKeywordChange() {
      this.$emit("onKeywordChange", this.keyword);
    },
    onSelectAccident(event) {
      this.$emit("onSelectAccident", event.data);
    },
    ellipsis(value) {
      return ellipsis(value);
    },
    getDetailsFromLocale(details, key) {
      const detail = details.find(
        (d) => d.langue.codeIso === this.$i18n.locale
      );
      return this.ellipsis(detail ? detail[key] : "");
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
#list-accidents {
  .p-datatable-tbody {
    tr {
      cursor: pointer;
      &:hover {
        background: $default_lighter_color;
      }
    }
  }
}
</style>
