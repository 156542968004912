import axios from "axios";
import configuration from "../config";
import i18n from "../locales";
import apiEventTypes from "../constantes/apiEventTypes";

const instance = axios.create({
  //baseURL: configuration.api_url,
  timeout: 30000,
  withCredentials: true,
  maxContentLength: 256,
});

instance.interceptors.request.use((config) => {
  let isoCode = i18n.global.te("unknown")
    ? i18n.global.locale
    : i18n.global.fallbackLocale;
  config.headers[configuration.localizationHeader] = isoCode;
  return config;
});

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    document.dispatchEvent(
      new CustomEvent(apiEventTypes.API_ERROR, {
        detail: { statusCode: err.response ? err.response.status : 500 },
      })
    );
    throw err;
  }
);

export default instance;
