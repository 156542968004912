export const GUEST = 0;
export const ADMIN = 1;
export const SAFETY_LEADER = 2;
export const SAFETY_TEAM = 3;
export const ADMIN_TEAM = 4;

export default {
  GUEST,
  ADMIN,
  SAFETY_LEADER,
  SAFETY_TEAM,
  ADMIN_TEAM
};
