<template>
  <feedback />
  <upload-download-file />
  <router-view />
  <login-modal />
  <Toast position="top-right" />
</template>

<script>
import store from "./store";
import LoginModal from "./components/Modals/LoginModal.vue";
import Feedback from "./views/Feedback.vue";
import UploadDownloadFile from "./views/UploadDownloadFile.vue";
import Toast from "primevue/toast";
import statusCodes from "./constantes/statusCodes";
import apiEventTypes from "./constantes/apiEventTypes";
import config from "./config";
import { event } from "vue-gtag";
export default {
  name: "App",
  components: {
    LoginModal,
    Toast,
    Feedback,
    UploadDownloadFile,
  },
  async beforeMount() {
    this.getUser().then(this.getReferential());
  },
  mounted() {
    event("AppVue", { method: "mounted" });
    document.addEventListener(apiEventTypes.ERROR, (event) => {
      this.showApiErrors(event.detail);
    });
    document.addEventListener(apiEventTypes.SUCCESS, (event) => {
      this.showApiSuccess(event.detail);
    });
    document.addEventListener(apiEventTypes.API_ERROR, (event) => {
      this.showApiErrors(event.detail);
    });
    document.addEventListener(apiEventTypes.API_SUCCESS, (event) => {
      this.showApiSuccess(event.detail);
    });
  },
  beforeUnmount() {
    document.removeEventListener(
      apiEventTypes.ERROR /*, (event) => {this.showApiErrors(event.detail); }*/
    );
    document.removeEventListener(
      apiEventTypes.SUCCESS /*, (event) => {this.showApiErrors(event.detail); }*/
    );
    document.removeEventListener(
      apiEventTypes.API_ERROR /*, (event) => {this.showApiErrors(event.detail); }*/
    );
    document.removeEventListener(
      apiEventTypes.API_SUCCES /*, (event) => {this.showApiErrors(event.detail); }*/
    );
  },
  methods: {
    busListen(e) {
      console.log(e);
    },
    async getUser() {
      return await store.dispatch("getUserFromApi");
    },
    getReferential() {
      store.dispatch("getLanguagesReferentialsFromApi");
      store.dispatch("getConfigurationFromApi");
    },
    showApiErrors(detail) {
      switch (detail.statusCode) {
        case statusCodes.FORBIDDEN:
          // this.$toast.add({
          //   severity: "error",
          //   summary: this.$t("popup_messages.unauthorized_title"),
          //   detail: this.$t("popup_messages.unauthorized_message"),
          //   life: config.errorMessagesLifetime,
          // });
          break;
        case statusCodes.UNAUTHORIZED:
          // already catched with login modal
          // localStorage.setItem("redirect", this.$route.path);
          // window.location.replace("/auth/login");
          break;
        case statusCodes.NOT_FOUND:
          this.$toast.add({
            severity: "error",
            summary: this.$t("popup_messages.not_found_title"),
            detail: this.$t("popup_messages.not_found_message"),
            life: config.errorMessagesLifetime,
          });
          break;
        case statusCodes.BAD_REQUEST:
          this.$toast.add({
            severity: "error",
            summary: this.$t("popup_messages.bad_request_title"),
            detail: this.$t("popup_messages.bad_request_message"),
            life: config.errorMessagesLifetime,
          });
          break;
        case statusCodes.ERROR:
          this.$toast.add({
            severity: "error",
            summary: detail.title,
            detail: detail.message,
            life: config.errorMessagesLifetime,
          });
          break;
        case statusCodes.SUCCESS:
          this.$toast.add({
            severity: "success",
            summary: detail.title,
            detail: detail.message,
            life: config.errorMessagesLifetime,
          });
          break;
        default:
          this.$toast.add({
            severity: "error",
            summary: this.$t("popup_messages.default_error_title"),
            detail: this.$t("popup_messages.default_error_message"),
            life: config.errorMessagesLifetime,
          });
          break;
      }
    },
    showApiSuccess(detail) {
      this.$toast.add({
        severity: "success",
        summary: detail.title,
        detail: detail.message,
        life: config.successMessagesLifetime,
      });
    },
  },
};
</script>
