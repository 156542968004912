<template>
  <form id="declaration-form" @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid">
      <div class="col-12 md:col-3 block md:hidden help">
        <as-title
          class="title"
          :title="$t('declaration_accident_form.help')"
          :size="5"
        />

        <div class="p-fluid grid">
          <div class="col-6 md:col-12">
            <Button
              :label="$t('declaration_accident_form.help_model')"
              class="button-default"
            />
          </div>
          <div class="col-6 md:col-12">
            <Button
              :label="$t('declaration_accident_form.help_method')"
              class="button-default"
            />
          </div>
        </div>
      </div>
      <div v-bind:class="[isEdit ? 'col-12' : 'col-9']">
        <as-title
          v-if="!isEdit"
          class="title"
          :title="$t('declaration_accident_form.title')"
          :size="5"
        />
        <as-title
          v-else
          class="title"
          :title="$t('declaration_accident_form.edit_title')"
          :size="5"
        />
        <div class="p-fluid grid">
          <div
            class="field col-12"
            v-bind:class="[isEdit ? 'md:col-6' : 'md:col-4']"
          >
            <div class="p-float-label">
              <Dropdown
                v-model="v$.presqueAccident.$model"
                :options="presqueAccidents"
                optionLabel="label"
                :class="{
                  'p-invalid': v$.presqueAccident.$invalid && submitted,
                }"
                @update:modelValue="onEditChange()"
              />
              <label>{{
                $t("declaration_accident_form.accident_or_near_field")
              }}</label>
            </div>
            <as-input-error
              :errors="v$.presqueAccident.$silentErrors"
              v-show="submitted"
            />
          </div>
          <div
            class="field col-12"
            v-bind:class="[isEdit ? 'md:col-6' : 'md:col-4']"
          >
            <div class="p-float-label">
              <ProgressSpinner v-if="isEntrepriseBusy" />
              <Dropdown
                v-else
                v-model="v$.selectedEntreprise.$model"
                :options="entreprisesOptions"
                optionLabel="label"
                :class="{
                  'p-invalid': v$.selectedEntreprise.$invalid && submitted,
                }"
                @update:modelValue="onEditChange()"
              >
                <label>{{ $t("community.select_company") }}</label>
              </Dropdown>
            </div>
            <as-input-error
              :errors="v$.selectedEntreprise.$silentErrors"
              v-show="submitted"
            />
          </div>
          <div v-bind:class="[isEdit ? '' : 'col-offset-4']"></div>

          <div
            class="field col-12"
            v-bind:class="[isEdit ? 'md:col-6' : 'md:col-4']"
          >
            <div class="p-float-label">
              <Dropdown
                v-model="v$.lieu.$model"
                :options="lieux"
                optionLabel="label"
                :class="{
                  'p-invalid': v$.lieu.$invalid && submitted,
                }"
                @update:modelValue="onEditChange()"
              />
              <label>{{
                $t("declaration_accident_form.location_field")
              }}</label>
            </div>
            <as-input-error
              :errors="v$.lieu.$silentErrors"
              v-show="submitted"
            />
          </div>
          <div
            class="field col-12"
            v-bind:class="[isEdit ? 'md:col-6' : 'md:col-4']"
          >
            <span class="p-float-label">
              <Calendar
                v-model="v$.date.$model"
                :showTime="true"
                :dateFormat="$t('form.date_format')"
                :hourFormat="$t('form.hour_format')"
                :maxDate="maxDate"
                :class="{ 'p-invalid': v$.date.$invalid && submitted }"
                @update:modelValue="onEditChange()"
              />
              <label>{{ $t("declaration_accident_form.date_field") }}</label>
            </span>
            <as-input-error
              :errors="v$.date.$silentErrors"
              v-show="submitted"
            />
          </div>
          <div
            class="field col-12"
            v-bind:class="[isEdit ? 'md:col-6' : 'md:col-4']"
          >
            <div class="p-float-label">
              <Dropdown
                v-model="categoriePersonne"
                :options="categoriesPersonne"
                optionLabel="label"
                :class="{
                  'p-invalid': v$.categoriePersonne.$invalid && submitted,
                }"
                @update:modelValue="onEditChange()"
              />
              <label>{{
                $t("declaration_accident_form.category_field")
              }}</label>
            </div>
            <as-input-error
              :errors="v$.categoriePersonne.$silentErrors"
              v-show="submitted"
            />
          </div>
          <div class="field col-12" v-if="!isEdit">
            <span class="p-float-label">
              <Textarea
                type="text"
                v-model="v$.description.$model"
                :class="{ 'p-invalid': v$.description.$invalid && submitted }"
                :autoResize="true"
                rows="2"
                cols="30"
              />
              <label>{{
                $t("declaration_accident_form.description_field")
              }}</label>
            </span>
            <as-input-error
              :errors="v$.description.$silentErrors"
              v-show="submitted"
            />
          </div>
          <div
            class="field col-12"
            v-bind:class="[isEdit ? 'md:col-6' : 'md:col-4']"
          >
            <span class="p-float-label">
              <Dropdown
                v-model="v$.zoneBlessures.$model"
                :options="zonesBlessures"
                optionLabel="label"
                :class="{
                  'p-invalid': v$.zoneBlessures.$invalid && submitted,
                }"
                @update:modelValue="onEditChange()"
              />
              <label>{{
                $t("declaration_accident_form.injury_area_field")
              }}</label>
            </span>
            <as-input-error
              :errors="v$.zoneBlessures.$silentErrors"
              v-show="submitted"
            />
          </div>
          <div
            class="field col-12"
            v-bind:class="[isEdit ? 'md:col-6' : 'md:col-4']"
          >
            <span class="p-float-label">
              <Dropdown
                v-model="v$.natureBlessures.$model"
                :options="naturesBlessures"
                optionLabel="label"
                :class="{
                  'p-invalid': v$.natureBlessures.$invalid && submitted,
                }"
                @update:modelValue="onEditChange()"
              />
              <label>{{
                $t("declaration_accident_form.nature_injury_field")
              }}</label>
            </span>
            <as-input-error
              :errors="v$.natureBlessures.$silentErrors"
              v-show="submitted"
            />
          </div>
          <div
            class="field col-12"
            v-bind:class="[isEdit ? 'md:col-6' : 'md:col-4']"
          >
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="codeBarreProduit"
                @update:modelValue="onEditChange()"
              />
              <label>{{ $t("declaration_accident_form.barcode_field") }}</label>
            </span>
          </div>
          <div class="field col-12" v-if="!isEdit">
            <span class="p-float-label">
              <Textarea
                type="text"
                v-model="actionsMiseEnOeuvre"
                :autoResize="true"
                rows="2"
                cols="30"
              />
              <label>{{ $t("declaration_accident_form.action_field") }}</label>
            </span>
          </div>
          <div class="field col-12" v-if="!isEdit">
            <span class="p-float-label">
              <Textarea
                type="text"
                v-model="consequenceAccidente"
                :autoResize="true"
                rows="2"
                cols="30"
              />
              <label>{{
                this.presqueAccident && this.presqueAccident.value === true
                  ? $t("declaration_accident_form.consequences_field_near_miss")
                  : $t("declaration_accident_form.consequences_field")
              }}</label>
            </span>
          </div>
          <div class="field col-12 xl:col-6" v-if="!isEdit">
            <AsFileUpload
              :fichiers="fichiers"
              :maxFileSize="maxFileUpload"
              :chooseLabel="$t('declaration_accident_form.choose_button')"
              :multiple="true"
            />
          </div>
        </div>
      </div>
      <div class="col-3 hidden md:block" v-if="!isEdit">
        <as-title
          class="title"
          :title="$t('declaration_accident_form.help')"
          :size="5"
        />
        <div class="p-fluid grid">
          <div class="col-6 md:col-12">
            <span class="button-default">
              <a
                :href="modeleLink"
                class="p-button p-component"
                target="_blank"
              >
                <span class="p-button-label">{{
                  $t("declaration_accident_form.help_model")
                }}</span>
              </a>
            </span>
          </div>
          <div class="col-6 md:col-12">
            <span class="button-default">
              <a
                :href="methodLink"
                class="p-button p-component"
                target="_blank"
              >
                <span class="p-button-label">{{
                  $t("declaration_accident_form.help_method")
                }}</span>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="grid fixed-bottom" v-if="!isEdit">
      <div class="col flex justify-content-end">
        <Button
          :label="$t('form.cancel_button')"
          class="button-default"
          :icon="isSending ? 'pi pi-spin pi-spinner' : 'pi pi-times'"
          @click="onClose"
          :disabled="isSending"
        />
        <Button
          type="submit"
          :label="$t('form.submit_button')"
          class="button-primary ml-2"
          :icon="isSending ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          :disabled="isSending"
        />
      </div>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { validDate } from "./customValidations";

import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import AsTitle from "../Elements/AsTitle.vue";
import AsInputError from "./AsInputError.vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import AsFileUpload from "../Elements/AsFileUpload.vue";
import Dropdown from "primevue/dropdown";
import ProgressSpinner from "primevue/progressspinner";
import moment from "moment";
import { mapGetters } from "vuex";

import fichiersServiceApi from "../../services/fichiersServiceApi";
import entreprisesServiceApi from "../../services/entreprisesServiceApi";
import config from "../../config";
import locations from "../../constantes/locations";
import categoryPersons from "../../constantes/categoryPersons";
import woundsTypes from "../../constantes/woundsTypes";
import woundsLocations from "../../constantes/woundsLocations";

export default {
  name: "DeclarationForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    InputText,
    Textarea,
    AsTitle,
    Button,
    Calendar,
    AsFileUpload,
    AsInputError,
    Dropdown,
    ProgressSpinner,
  },
  props: {
    isClosed: Boolean,
    isSending: Boolean,
    isEdit: Boolean,
    declaration: Object,
  },
  mixins: [fichiersServiceApi],
  watch: {
    isClosed(newValue) {
      if (newValue) {
        this.resetForm();
      }
    },
    driveConfig(newValue) {
      if (newValue) {
        this.getFiles().then((result) => {
          this.files = result;
        });
      }
    },
  },
  data() {
    const data = {
      maxDate: moment().add(1, "days").toDate(),
      entreprises: [],
      isEntrepriseBusy: false,
      selectedEntreprise: null,
      categoriePersonne: this.declaration
        ? {
            label: this.$t(
              `categoryPersons.${this.declaration.categoriePersonne}`
            ),
            value: this.declaration.categoriePersonne,
          }
        : "",
      date: this.declaration
        ? moment(`${this.declaration.date} ${this.declaration.heure}`).toDate()
        : moment().toDate(),
      presqueAccident: this.declaration
        ? this.declaration.presqueAccident
          ? { label: this.$t(`accident_or_near_field.NEAR_MISS`), value: true }
          : { label: this.$t(`accident_or_near_field.ACCIDENT`), value: false }
        : false,
      lieu: this.declaration
        ? {
            label: this.$t(`locations.${this.declaration.lieu}`),
            value: this.declaration.lieu,
          }
        : "",
      description: this.declaration ? this.declaration.description : "",
      zoneBlessures: this.declaration
        ? {
            label: this.$t(`woundsLocations.${this.declaration.zoneBlessures}`),
            value: this.declaration.zoneBlessures,
          }
        : "",
      natureBlessures: this.declaration
        ? {
            label: this.$t(`woundsTypes.${this.declaration.natureBlessures}`),
            value: this.declaration.natureBlessures,
          }
        : "",
      codeBarreProduit: this.declaration
        ? this.declaration.codeBarreProduit
        : "",
      actionsMiseEnOeuvre: "",
      consequenceAccidente: "",
      submitted: false,
      files: [],
      selectedFiles: [],
      modeleLink: config.declarationModeleHelp,
      methodLink: config.declarationAnalyzeHelp,
      fichiers: [],
      maxFileUpload: config.max_file_upload,
    };
    return data;
  },
  validations() {
    const baseRequired = {
      presqueAccident: { required },
      categoriePersonne: { required },
      date: { required, validDate },
      lieu: { required },
      selectedEntreprise: { required },
      description: { required },
      zoneBlessures: {},
      natureBlessures: {},
    };
    if (this.isEdit) {
      baseRequired.description = {};
    }
    return !this.presqueAccident || this.presqueAccident.value === true
      ? baseRequired
      : {
          ...baseRequired,
          zoneBlessures: { required },
          natureBlessures: { required },
        };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      driveConfig: "getConfig",
    }),
    entreprisesOptions() {
      return this.entreprises.map((el) => {
        return {
          label: `${el.nom}`,
          value: el.id,
        };
      });
    },
    presqueAccidents() {
      const result = [
        { label: this.$t(`accident_or_near_field.ACCIDENT`), value: false },
        { label: this.$t(`accident_or_near_field.NEAR_MISS`), value: true },
      ];
      return result;
    },
    lieux() {
      let result = [];
      for (const property in locations) {
        result.push({
          label: this.$t(`locations.${property}`),
          value: property,
        });
      }
      return result;
    },
    categoriesPersonne() {
      let result = [];
      for (const property in categoryPersons) {
        result.push({
          label: this.$t(`categoryPersons.${property}`),
          value: property,
        });
      }
      return result;
    },
    zonesBlessures() {
      let result = [];
      for (const property in woundsLocations) {
        result.push({
          label: this.$t(`woundsLocations.${property}`),
          value: property,
        });
      }
      return result;
    },
    naturesBlessures() {
      let result = [];
      for (const property in woundsTypes) {
        result.push({
          label: this.$t(`woundsTypes.${property}`),
          value: property,
        });
      }
      return result;
    },
    availableFiles() {
      return this.files.map((el) => {
        return {
          titre: `${el.name}`,
          externalId: el.id,
          icon: el.iconLink,
        };
      });
    },
  },
  emits: ["onClose", "onSubmit", "onEditChange"],
  beforeMount() {
    this.getFiles().then((result) => {
      this.files = result;
    });
    this.isEntrepriseBusy = true;
    entreprisesServiceApi.getEntreprises().then((results) => {
      this.entreprises = results;
      const checkId = this.declaration
        ? this.declaration.idEntreprise
        : this.user.idEntreprise;
      const selectedEntreprise = results.find((e) => e.id === checkId);
      this.selectedEntreprise = {
        value: selectedEntreprise.id,
        label: selectedEntreprise.nom,
      };
      this.isEntrepriseBusy = false;
    });
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;
      if (!isFormValid) {
        return;
      }

      this.$emit("onSubmit", {
        presqueAccident: this.presqueAccident.value,
        categoriePersonne: this.categoriePersonne.value,
        date: moment.utc(this.date),
        heure: moment.utc(this.date).format("HH:mm:ss"),
        lieu: this.lieu.value,
        description: this.description,
        zoneBlessures: this.zoneBlessures.value,
        natureBlessures: this.natureBlessures.value,
        codeBarreProduit: this.codeBarreProduit,
        actionsMiseEnOeuvre: this.actionsMiseEnOeuvre,
        consequenceAccidente: this.consequenceAccidente,
        idLdap: this.user.idLdap,
        idEntreprise: this.selectedEntreprise.value,
        filesToUpload: this.fichiers,
        fichiers: this.selectedFiles,
      });
    },
    resetForm() {
      this.presqueAccident = false;
      this.categoriePersonne = "";
      this.date = moment().toDate();
      this.lieu = "";
      this.description = "";
      this.zoneBlessures = "";
      this.natureBlessures = "";
      this.codeBarreProduit = "";
      this.actionsMiseEnOeuvre = "";
      this.consequenceAccidente = "";
      this.submitted = false;
      this.selectedDriveFile = undefined;
      this.selectedFiles = [];
      this.fichiers = [];
    },
    onClose() {
      this.$emit("onClose");
    },
    onEditChange() {
      const idEntreprise = this.selectedEntreprise.value;
      this.$emit("onEditChange", {
        idEntreprise: idEntreprise,
        entreprise: this.entreprises.find((e) => e.id === idEntreprise),
        presqueAccident: this.presqueAccident.value,
        categoriePersonne: this.categoriePersonne.value,
        date: moment.utc(this.date),
        heure: moment.utc(this.date).format("HH:mm:ss"),
        lieu: this.lieu.value,
        zoneBlessures: this.zoneBlessures.value,
        natureBlessures: this.natureBlessures.value,
        codeBarreProduit: this.codeBarreProduit,
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
#declaration-form {
  a {
    text-decoration: unset;
  }
  position: relative;

  .help button {
    height: 100%;
  }

  .fixed-bottom {
    position: sticky;
    width: 100%;
    box-sizing: content-box;
    background-color: white;
    margin-left: -40px;
    margin-right: -40px;
    border-top: solid 1px $default_color;
    padding-left: 40px;
    padding-right: 40px;
    bottom: -40px;
    left: 0;
    right: 0;
  }
  .title {
    padding-top: 0;
    padding-bottom: 30px;
  }
  .p-progress-spinner {
    height: 40px;
  }
}
.p-panel {
  .p-toggleable-content {
    .p-panel-content {
      padding: 2rem 1rem !important;
    }
  }
}
</style>
