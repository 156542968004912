<template>
  <div>
    <img
      :src="source"
      :width="size"
      :height="respectRatio ? undefined : size"
    />
  </div>
</template>

<script>
export default {
  name: "AsIcon",
  props: {
    source: {
      type: String,
      default: "",
    },
    size: {
      type: [Number, String],
      default: 60,
    },
    respectRatio: {
      type: Boolean,
    },
  },
};
</script>
