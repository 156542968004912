<template>
  <div class="as-modal-container" v-show="isVisible">
    <as-overlay :isCloseable="isCloseable" @click="onClose" />
    <as-container class="as-modal-content-container">
      <as-block class="as-modal-content" gutters="40px">
        <Button
          v-if="isCloseable"
          icon="pi pi-times"
          severity="secondary"
          rounded text
          class="close-btn"
          @click="onClose"
        />
        <slot> </slot>
      </as-block>
    </as-container>
  </div>
</template>

<script>
import AsOverlay from "./AsOverlay";
import AsContainer from "../Containers/AsContainer";
import AsBlock from "../Containers/AsBlock";
import Button from "primevue/button";

export default {
  name: "AsModal",
  components: {
    AsOverlay,
    AsContainer,
    AsBlock,
    Button,
  },
  props: {
    isVisible: Boolean,
    isCloseable: Boolean,
    title: String,
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        // When the modal is shown, we want a fixed body
        this.memorisedScrollY = window.scrollY;
        document.body.style.overflow = "hidden";
        document.body.style.top = `-${this.memorisedScrollY}px`;
      } else {
        // When the modal is hidden, we want to remain at the top of the scroll position
        document.body.style.overflow = "auto";

        document.body.style.top = "";
        window.scrollTo(0, this.memorisedScrollY);
      }
    },
  },
  emits: ["onClose"],
  methods: {
    onClose() {
      if (this.isCloseable) {
        this.$emit("onClose");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/zindex.scss";
@import "../../assets/styles/sizing.scss";

.as-modal-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 300;
  left: 0;
  top: 0;
  .as-modal-content-container {
    padding: 0;
    position: relative;
    height: auto;
    max-height: 94vh;
    max-width: 94vw;
    z-index: 400;
    overflow: auto;
    width: $sm_container_width;
    @media screen and (min-width: 1360px) {
      width: $xl_container_width;
    }
    .as-modal-content {
      overflow-y: auto;
      .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }
  &.small {
    .as-modal-content-container {
      width: 320px;
      height: auto;
      max-width: 94vw;
    }
  }
}
</style>
