<template>
  <div id="accident-block" ref="accident-block">
    <div class="col-12 flex align-items declaration-header">
      <img :src="presqueAccident ? nearMissImg : accidentImg" class="declaration-accident-img" alt="accident-img" />
      <as-title :title="titre" :size="3" /><br /><br />
      <div class="ml-auto" v-if="!pdfBeingGenerated">
        <ProgressSpinner id="edit-accident-spinner" v-if="editAccidentBusy" />
        <Button v-if="isAdmin && !editAccidentBusy" id="edit-accident" @click="editAccident" icon="pi pi-pencil"
          :title="$t('share_accident.edit')" class="p-button-sm p-button-rounded button-primary ml-auto" />
        &nbsp;
        <Button id="share-accident" @click="shareAccident" icon="pi pi-share-alt" :title="$t('share_accident.share')"
          class="p-button-sm p-button-rounded button-primary ml-auto" />
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-12">
        <div class="grid">
          <div class="col-12">
            <as-block class="bg-tertiary-sliced" gutters="20px">
              <div class="flex accident-header">
                <div v-if="entreprise">
                  <as-title :title="$t('home.last_accident_location')" :size="5" />
                  <span> {{ entreprise }} </span>
                </div>
                <div v-if="lieu">
                  <as-title :title="$t('home.accident_injury_aera')" :size="5" />
                  <span> {{ $t("locations." + lieu) }} </span>
                </div>
                <div v-if="categoriePersonne">
                  <as-title :title="$t('home.accident_category')" :size="5" />
                  <span>
                    {{ $t("categoryPersons." + categoriePersonne) }}
                  </span>
                </div>
                <div v-if="zoneBlessures">
                  <as-title :title="$t('home.accident_injury_location')" :size="5" />
                  <span> {{ $t("woundsLocations." + zoneBlessures) }} </span>
                </div>
                <div v-if="natureBlessures">
                  <as-title :title="$t('home.accident_injury_nature')" :size="5" />
                  <span> {{ $t("woundsTypes." + natureBlessures) }} </span>
                </div>
                <div v-if="codeBarreProduit">
                  <as-title :title="$t('home.accident_bar_code_product')" :size="5" />
                  <span> {{ codeBarreProduit }} </span>
                </div>
              </div>
            </as-block>
          </div>
        </div>
        <br />
        <div class="grid">
          <div class="col-12 lg:col-5">
            <as-block class="bg-secondary flex flex-wrap date-header">
              <div class="date-header__date">
                <div class="day">{{ date ? $d(date, "day") : "?" }}</div>
                <div class="month">
                  {{ date ? $d(date, "month") : "" }}
                  {{ date ? $d(date, "year") : "" }}
                </div>
              </div>
              <div class="date-header__image flex align-items" v-if="isBusy">
                <ProgressSpinner />
              </div>
              <div class="date-header__image flex align-items" v-else>
                <img v-if="image" :src="image" class="grid accident-img m-0" />
                <img v-else :src="defaultImage" class="grid accident-img m-0" />
              </div>
            </as-block>
          </div>
          <div class="col-12 lg:col-7 mb-2">
            <div class="grid">
              <as-block gutters="10px">
                <as-title :title="$t('home.last_accident_description')" :size="5" /><br />
                <p>
                  {{ description }}
                </p>
              </as-block>
              <as-block gutters="10px">
                <as-title :title="$t('home.last_accident_cause')" :size="5" /><br />
                <p>
                  {{ causes }}
                </p>
              </as-block>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="col">
            <as-block gutters="10px">
              <as-title :title="$t('home.last_accident_improvements')" :size="5" />
            </as-block>
            <as-block class="flex improvement">
              <div class="improvement-container" v-for="(improvement, index) in improvementList" :key="index">
                <span class="improvement-container__header">{{
                  index + 1
                }}</span>
                <as-block class="bg-primary h-100" gutters="20px"><br />
                  <p>
                    {{ improvement }}
                  </p>
                </as-block>
              </div>
            </as-block>
            <as-block v-if="!pdfBeingGenerated" class="flex improvement">
              <as-file v-for="fichier in fichiers" :key="fichier.id" class="header-file__content" :fichier="fichier"
                @onDownload="onDownloadFichier" />
            </as-block>
            <div v-if="showPdfButton" class="align-right mt-5">
              <Button v-if="!pdfBeingGenerated" id="download-pdf" ref="download-pdf" icon="pi pi-download" iconPos="right"
                :label="$t('statistics.download_button')" class="button-default" @click="downloadWithCSS" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';

import AsTitle from "../Elements/AsTitle";
import AsBlock from "../Containers/AsBlock";
import AsFile from "@/components/Elements/AsFile";
import fichiersServiceApi from "../../services/fichiersServiceApi";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import { bufferToBlobUrl } from "../../util";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import apiEventTypes from "../../constantes/apiEventTypes";
import roleMixins from "@/mixins/roleMixins";

export default {
  name: "AccidentBlock",
  components: {
    AsTitle,
    AsBlock,
    AsFile,
    ProgressSpinner,
    Button,
  },
  props: {
    editAccidentBusy: Boolean,
    accidentData: {
      titre: String,
      description: String,
      date: [Date, String],
      causes: String,
      improvement: String,
      fichiers: [
        {
          codeIso: String,
          date: String,
          externalId: String,
          id: Number,
          titre: String,
          url: String,
        },
      ],
      header: {
        entreprise: String,
        lieu: String,
        categoriePersonne: String,
        zoneBlessures: String,
        natureBlessures: String,
        codeBarreProduit: String,
        presqueAccident: Boolean,
      },
    },
    showPdfButton: Boolean,
  },

  watch: {
    accidentData() {
      if (!this.image) {
        this.onLoadImage(this.accidentData);
      }
    },
  },
  beforeMount() {
    this.onLoadImage(this.accidentData);
  },
  emits: ["editAccident"],
  mixins: [roleMixins, fichiersServiceApi],
  methods: {
    onDownloadFichier(externalId, titre) {
      this.downloadFile(externalId, titre)
    },

    async onLoadImage(value) {
      if (value.image && value.image.externalId != null) {
        this.isBusy = true;
        try {
          const fichier = await this.downloadFile(value.image.externalId);
          this.image = bufferToBlobUrl(fichier);
        } catch (error) {
          console.log(error)
        }
      }
      this.isBusy = false;
    },
    downloadWithCSS() {
      this.pdfBeingGenerated = true;
      const doc = new jsPDF({ unit: "px" });
      const accidentBlock = this.$refs["accident-block"];
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      accidentBlock.style.width = "1200px";
      accidentBlock.style.height = "1800px";
      setTimeout(() => {
        html2canvas(accidentBlock, { scale: 3 }).then((canvas) => {
          const img = canvas.toDataURL("image/jpeg");
          doc.addImage(img, "JPEG", 5, 0, pdfWidth - 10, pdfHeight);
          doc.save(`${this.accidentData.titre}.pdf`);
          this.pdfBeingGenerated = false;
        });
        accidentBlock.style.width = "inherit";
        accidentBlock.style.height = "inherit";
      }, 100);
    },
    editAccident() {
      this.$emit("editAccident", this.accidentData);
    },
    shareAccident() {
      const key = "share-accidents";
      const splittedHref = window.location.href.split(key);
      const lLink = `${splittedHref[0]}${key}/${this.accidentData.id}`;
      navigator.clipboard.writeText(lLink);
      document.dispatchEvent(
        new CustomEvent(apiEventTypes.SUCCESS, {
          detail: {
            title: this.$t("share_accident.share_copy_title"),
            message: this.$t("share_accident.share_copy_content"),
          },
        })
      );
    },
  },

  data() {
    const { titre, header, date, description, causes, mesures, fichiers } =
      this.accidentData;
    return {
      isBusy: false,
      titre,
      ...header,
      date,
      description,
      causes,
      improvementList:
        mesures.substr(0, 1) === "[" ? JSON.parse(mesures) : [mesures],
      fichiers,
      image: undefined,
      defaultImage: require("../../assets/images/accident.jpeg"),
      pdfBeingGenerated: false,
      accidentImg: require("../../assets/images/declaration_accident.png"),
      nearMissImg: require("../../assets/images/declaration_near_miss.png"),
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/sizing.scss";

.p-carousel-item {
  max-height: 0;
}

.p-carousel-item-active {
  max-height: 100% !important;
}

#accident-block {
  .accident-header {
    flex-wrap: wrap;

    >div {
      width: 33%;
      align-items: center;
    }

    span {
      margin-left: 0.5em;
    }
  }

  .accident-img {
    min-height: 150px;
    max-height: 250px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .location {
    padding-left: 10px;
    align-self: center;
  }

  .date-header {
    &__date {
      flex: 1;
      display: block;
      padding: 20px;
      text-align: left;
      justify-content: center;

      .day {
        font-size: 100px;
        font-weight: bold;
        line-height: 100px;
        letter-spacing: -10px;
      }

      .month {
        font-size: 50px;
        margin-left: 8px;
        vertical-align: top;
        line-height: 40px;
        text-transform: uppercase;
      }
    }

    &__image {
      img {
        margin-left: auto;
        max-width: 100%;
        max-height: 450px;
      }
    }
  }

  .improvement {
    flex-wrap: wrap;
    margin: -1%;

    &-container {
      position: relative;
      padding-top: 25px !important;
      margin: 1%;
      flex-basis: calc(94% / 3);
      flex-grow: 1;

      &__header {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        margin: auto;
        background: $dark_font;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2em;
        font-weight: bold;
      }
    }
  }

  .header-file__content {
    flex-basis: calc(94% / 3);
    margin: 1%;
  }

  @media screen and (max-width: 1200px) {
    .header-file__content {
      flex-basis: calc(96% / 2);
    }
  }

  @media screen and (max-width: 991px) {
    .accident-header {
      >div {
        width: 100%;
      }
    }

    .accident-img {
      border-radius: 20px;
    }

    .improvement {
      &-container {
        flex-basis: calc(93% / 2);
      }
    }

    .header-file__content {
      flex-basis: 95%;
    }

    .date-header {
      &__image {
        img {
          border-radius: 5px;
          min-height: auto;
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    .improvement {
      &-container {
        flex-basis: 100%;
      }
    }
  }
}

.declaration-header {
  .declaration-accident-img {
    height: 75px;
    margin-right: 10px;
  }

  h5.title {
    padding: 0;
  }
}

#edit-accident-spinner.p-progress-spinner {
  height: 30px;
}
</style>
