<template>
  <div id="donnees-bu">
    <div class="header grid">
      <div class="col-12 section-title chiffre-line two-lines">
        {{ $t("statistics.data_bu_title") }}<br />
        <div class="bu-subtitle">{{ $t("statistics.data_bu_subtitle") }}</div>
      </div>
    </div>
    <div class="body grid">
      <div class="col-12 flex flex-column head chiffre-line">
        <span class="col">
          {{ $t("statistics.nb_collaborators_label") }}
        </span>
        <InputNumber
          class="col"
          v-if="editMode"
          v-model="modelValue.nbCollaborateurs"
        />
        <b class="col" v-else>
          {{ modelValue.nbCollaborateurs ? modelValue.nbCollaborateurs : "/" }}
        </b>
      </div>
      <div class="col-12 flex flex-column head chiffre-line">
        <span class="col"> {{ $t("statistics.nb_sites") }} </span>
        <InputNumber
          class="col"
          v-if="editMode"
          v-model="modelValue.nbSites"
        />
        <b class="col" v-else>
          {{ modelValue.nbSites ? modelValue.nbSites : "/" }}
        </b>
      </div>
    </div>
    <div class="grid chiffre-line">
      <div class="col flex flex-column justify-content-evenly">
        <span>{{ $t("statistics.nb_shops") }}</span>
        <InputNumber
          class="col"
          v-if="editMode"
          v-model="modelValue.nbMagasin"
        />
        <b v-else> {{ modelValue.nbMagasin ? modelValue.nbMagasin : "/" }} </b>
      </div>
      <div class="col flex flex-column justify-content-evenly">
        <span>{{ $t("statistics.nb_warehouse") }}</span>
        <InputNumber
          class="col"
          v-if="editMode"
          v-model="modelValue.nbEntrepots"
        />
        <b v-else>
          {{ modelValue.nbEntrepots ? modelValue.nbEntrepots : "/" }}
        </b>
      </div>
      <div class="col flex flex-column justify-content-evenly">
        <span>{{ $t("statistics.nb_internal_services") }}</span>
        <InputNumber
          class="col"
          v-if="editMode"
          v-model="modelValue.nbServicesInternes"
        />
        <b v-else>
          {{
            modelValue.nbServicesInternes ? modelValue.nbServicesInternes : "/"
          }}
        </b>
      </div>
    </div>
    <div class="grid chiffre-line">
      <div class="col-4 flex flex-column justify-content-evenly">
        <span> {{ $t("statistics.nb_surface_less_1000m2") }} </span>
        <InputNumber v-if="editMode" v-model="modelValue.nbSurfaceInf" />
        <b v-else>
          {{ modelValue.nbSurfaceInf ? modelValue.nbSurfaceInf : "/" }}
        </b>
      </div>
      <div class="col-8 flex flex-column justify-content-evenly">
        <span> {{ $t("statistics.nb_hours_worked") }}</span>
        <InputNumber
          v-if="editMode"
          v-model="modelValue.nbHeuresTravaillees"
          mode="decimal"
          :minFractionDigits="3"
          :maxFractionDigits="3"
        />
        <b v-else>
          {{
            modelValue.nbHeuresTravaillees
              ? modelValue.nbHeuresTravaillees
              : "/"
          }}
        </b>
      </div>
    </div>
    <div class="grid">
      <div class="col head section-title chiffre-line">
        {{ $t("statistics.safe_place_title") }}
        <as-icon :source="iconImg" respectRatio class="ml-2" />
      </div>
    </div>
    <div class="grid chiffre-line-2">
      <div class="col flex justify-content-center flex-column">
        <span class="col"> {{ $t("statistics.at_for_1_years_label") }} </span>
        <InputNumber v-if="editMode" v-model="modelValue.atDepuis1An" />
        <b class="col" v-else>
          {{ modelValue.atDepuis1An ? modelValue.atDepuis1An : "/" }}
        </b>
      </div>
      <div class="col flex justify-content-center flex-column">
        <span class="col"> {{ $t("statistics.at_for_2_years_label") }} </span>
        <InputNumber v-if="editMode" v-model="modelValue.atDepuis2An" />
        <b class="col" v-else>
          {{ modelValue.atDepuis2An ? modelValue.atDepuis2An : "/" }}
        </b>
      </div>
      <div class="col flex justify-content-center flex-column">
        <span class="col">
          {{ $t("statistics.at_for_1000_days_label") }}
        </span>
        <InputNumber v-if="editMode" v-model="modelValue.atDepuis1000Jours" />
        <b class="col" v-else>
          {{
            modelValue.atDepuis1000Jours ? modelValue.atDepuis1000Jours : "/"
          }}
        </b>
      </div>
    </div>
  </div>
</template>

<script>
import vmodelMixins from "../../mixins/vmodelMixins";
import AsIcon from "../Elements/AsIcon.vue";
import InputNumber from "primevue/inputnumber";

export default {
  name: "BuBlock",
  components: {
    AsIcon,
    InputNumber,
  },
  props: {
    editMode: Boolean,
  },
  data() {
    return {
      iconImg: require("../../assets/images/icons/picto-chiffre-safeplace.svg"),
    };
  },
  mixins: [vmodelMixins],
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";

#donnees-bu {
  input {
    max-width: 100%;
  }
  background-color: $primary_lighter_color;
  .grid {
    &.header {
      background-color: $primary_color;
    }
    .two-lines {
      flex-direction: column;
      padding-top: 42px;
    }
    .bu-subtitle {
      text-transform: initial;
      font-size: 0.875rem !important;
      font-weight: normal;
    }
    .head {
      background-color: $primary_light_color;
    }
  }
}
</style>
