export const COLLABORATOR = "collaborator";
export const EXTERNAL_PROVIDER = "external provider";
export const TEMP = "temp";
export const CUSTOMER = "customer";
export const SUPPLIER = "supplier";

export default {
  COLLABORATOR,
  EXTERNAL_PROVIDER,
  TEMP,
  CUSTOMER,
  SUPPLIER,
};
