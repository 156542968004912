import configuration from "../config";
import axios from "./axiosConfiguration";
// import roles from "../constantes/roles";

const endpointLangues = `${configuration.api_url}langues`;
const endpointUserInfo = `${configuration.api_auth_url}user`;
const endpointConfig = `${configuration.api_url}config`;

const getLanguages = async () => {
  return axios
    .get(endpointLangues)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getUserInfos = async () => {
  return axios
    .get(endpointUserInfo)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getConfig = async () => {
  return axios
    .get(endpointConfig)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getLanguages,
  getUserInfos,
  getConfig,
};
