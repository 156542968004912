<template>
  <div id="upload-download-component">
    <div id="upload-download-component__uploads">
      <div v-for="(file, index) in $store.state.fileProgress.uploadedFiles" :key="index"
        class="upload-download-component__uploads-list-item">
        <div class="upload-download-component__uploads-list-item__infos">
          <span class="upload-download-component__uploads-list-item__infos-name">
            {{ file?.filename }}
            <i v-show="file?.progress >= 100" class="pi pi-check"></i>
          </span>
          <p class="upload-download-component__uploads-list-item__infos-size">
            {{ formatBytes(file?.size) }}
          </p>

          <div class="progress" :class="{
            success: file?.progress >= 100,
            'first-step': file?.step === 1,
          }">
            <ProgressBar :value="file?.progress"></ProgressBar>
          </div>
        </div>
      </div>
    </div>
    <div id="upload-download-component__downloads">
      <div v-for="(file, index) in $store.state.fileProgress.downloadedFiles" :key="index"
        class="upload-download-component__downloads-list-item">
        <i class="pi pi-file custom-icon"></i>
        <div class="upload-download-component__downloads-list-item__infos">
          <span class="upload-download-component__downloads-list-item__infos-name">
            {{ file?.filename }}
          </span>
          <div class="progress">
            <ProgressBar :value="file?.progress"></ProgressBar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ProgressBar from "primevue/progressbar";

export default {
  name: "UploadDownloadFile",
  components: {
    ProgressBar,
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  mixins: [],
  watch: {},
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) {
        return "0 Octet";
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals;
      const sizes = ["Octet", "Ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
  data() {
    return {
      uploadedFiles: [],
      downloadedFiles: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors.scss";

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

#upload-download-component {
  width: 30em !important;
  position: fixed;
  bottom: 75px;
  right: 0;
  z-index: 1000;

  .upload-download-component {
    &__uploads-list-item {
      display: flex;
      padding: 0.5rem;
      align-items: center;
      border: dashed 1px #979797;
      margin: 0.5em 0;
      background-color: white;

      &:last-child {
        margin-bottom: 0;
      }

      &__infos {
        position: relative;
        width: 100%;

        &-action-icon {
          position: absolute;
          top: 0;
          right: 0;
        }

        &-name {
          width: 100%;
          height: 22px;
          line-height: 22px;
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          display: flex;

          i.pi-check {
            margin-left: auto;
            color: $success_color;
            font-size: 25px;
          }
        }

        &-size {
          font-size: 12px;
          font-weight: 500;
          color: #a4a4a4;
          margin: 0;
          margin-bottom: 0.25rem;
        }
      }
    }

    &__downloads-list-item {
      display: flex;
      min-height: 4em;
      padding: 0.5rem;
      align-items: center;
      border: solid 1px #979797;
      border-style: dotted;
      margin: 0.5em 0;
      background-color: white;

      .custom-icon {
        font-size: 2rem;
        margin-right: 0.8rem;
        color: $primary_dark_color;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__infos {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &-name {
          font-size: 14px !important;
          font-weight: 500;
          margin: 0;
          flex: 1;
        }
      }
    }
  }
}

:deep(.progress) {
  .p-progressbar {
    height: 20px;

    .p-progressbar-value {
      background-color: $primary_dark_color !important;
      background: linear-gradient(45deg,
          rgba(255, 255, 255, 0.15) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.15) 50%,
          rgba(255, 255, 255, 0.15) 75%,
          transparent 75%,
          transparent);
      background-size: 1rem;
      animation: 1s linear infinite progress-bar-stripes;

      .p-progressbar-label {
        font-size: 13px;
      }
    }
  }

  .p-progressbar-determinate .p-progressbar-value-animate {
    transition: width 0.1s ease-in-out !important;
  }

  &.first-step {
    .p-progressbar-value {
      background-color: $tertiary_color !important;
    }
  }

  &.success {
    .p-progressbar-value {
      background: none;
      background-color: $success_color !important;
    }
  }
}
</style>
