export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const BAD_REQUEST = 502;

export default {
  FORBIDDEN,
  UNAUTHORIZED,
  NOT_FOUND,
  BAD_REQUEST,
};
