import configuration from "../config";
import axios from "./axiosConfiguration";

const endpoint = `${configuration.api_url}agendas`;

const getAgendas = (page, elementsPerPage, past) => {
  const options = {
    params: {
      page,
      elementsPerPage,
      past,
    },
  };
  return axios
    .get(endpoint, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getAgenda = (agendaId) => {
  return axios
    .get(`${endpoint}/${agendaId}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const deleteAgenda = (agendaId) => {
  return axios
    .delete(`${endpoint}/${agendaId}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const saveAgenda = (agenda) => {
  return axios
    .post(`${endpoint}`, agenda)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const updateAgenda = (id, agenda) => {
  return axios
    .put(`${endpoint}/${id}`, agenda)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getAgendas,
  getAgenda,
  deleteAgenda,
  saveAgenda,
  updateAgenda,
};
