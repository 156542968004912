import configuration from "../config";
import axios from "./axiosConfiguration";

const endpoint = `${configuration.api_url}accidents`;

const getAccidents = (page, elementsPerPage, keyword) => {
  const options = {
    params: {
      page,
      elementsPerPage,
      keyword,
    },
  };
  return axios
    .get(endpoint, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getAccidentById = (id) => {
  return axios
    .get(`${endpoint}/${id}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};


const getLastAccident = () => {
  const options = {};
  return axios
    .get(`${endpoint}/last`, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getLastAccidentFromNumber = (number) => {
  const options = {};
  return axios
    .get(`${endpoint}/last/${number}`, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const saveAccident = async (accident, declarationId) => {
  // une fois les fichiers uploadés, POST de l'accident (et suppression de la déclaration)
  //return Promise.all(promises).then(() => {
  const options = {
    params: {
      declarationId,
    },
  };
  return axios
    .post(`${endpoint}`, accident, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  //});
};

export default {
  getAccidents,
  getAccidentById,
  getLastAccident,
  getLastAccidentFromNumber,
  saveAccident,
};
