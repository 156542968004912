export const API_SUCCESS = "ApiSuccess";
export const API_ERROR = "ApiError";
export const SUCCESS = "Success";
export const ERROR = "Error";

export default {
  API_SUCCESS,
  API_ERROR,
  SUCCESS,
  ERROR,
};
