import { from, of, Observable } from 'rxjs'
import { map, catchError } from 'rxjs/operators';
import { Subject } from "rxjs"
import { debounceTime } from "rxjs/operators"
import configuration from "../config";
import axios from "./axiosConfiguration";
import store from "../store";

export default {
    computed: {
        endpointFichiers() {
            return `${configuration.api_url}fichiers`;
        },
    },
    methods: {
        sseObservable: undefined,
        getFiles() {
            return axios
                .get(`${this.endpointFichiers}`)
                .then((response) => {
                    return Promise.resolve(response.data);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
        },

        getSseObservable(idLdap) {
            return new Observable((observer) => {
                const eventSource = new EventSource(
                    `${configuration.api_url}sse/${idLdap}`,
                    { withCredentials: true }
                )
                eventSource.onmessage = (event) => { observer.next(JSON.parse(event.data)) }
                eventSource.onerror = (error) => {
                    console.log(`Event source error => ${error}`)
                    return observer.error(error)
                }
                return () => { eventSource.close() }
            })
        },

        uploadFiles(files, typeOfDocument, entityId, userIdLdap, refreshCallback) {
            if (!Array.isArray(files) || files.length === 0 || !entityId) {
                return;
            }

            store.dispatch(
                "setProgressUpload",
                files.map(({ file }) => {
                    return { filename: file.name, progress: 0, size: file.size, step: 1 };
                })
            );
            files.forEach(({ addtionnalData, file }) => {
                const formData = new FormData();
                formData.append("files[]", file, file.name);
                formData.append("addtionnalData", addtionnalData ? JSON.stringify(addtionnalData) : null);
                this._uploadFile(typeOfDocument, entityId, formData, file.name, userIdLdap, refreshCallback);
            });
        },

        _uploadFile(typeOfDocument, entityId, formData, title, userIdLdap, refreshCallback) {
            const requestConfig = {
                method: 'POST',
                url: `${this.endpointFichiers}/uploads/${typeOfDocument}/${entityId}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 0,
                onUploadProgress: (progressEvent) => {
                    const total = progressEvent.total
                    const percentage = Math.round((progressEvent.loaded * 100) / total)
                    store.dispatch("setProgressUpload", [{ progress: percentage, filename: title, size: total, step: 1 }]);
                },
            }

            from(axios.request(requestConfig)).pipe(
                map(result => result),
                catchError(() => of(null))
            ).subscribe((observer) => {
                if (!observer) { return }

                const refreshSubject = new Subject();
                refreshSubject.pipe(debounceTime(1000)).subscribe(async () => {
                    refreshCallback()
                })
                this.sseObservable?.unsubscribe();
                this.sseObservable = this.getSseObservable(userIdLdap).pipe(
                    catchError((error) => {
                        console.log(error)
                        store.dispatch("removeDocument", { filename: title, isUpload: true })
                        return of(null)
                    })
                ).subscribe(
                    (data) => {
                        if (data) {
                            store.dispatch("setProgressUpload", [{ ...data, step: 2 }]);
                            if (data?.progress >= 100) {
                                refreshSubject.next()
                            }
                        }
                    }
                );
            })
        },

        async downloadFile(documentId, title = false) {
            const requestConfig = {
                method: 'GET',
                url: `${this.endpointFichiers}/download/${documentId}/`,
                responseType: 'blob',
                timeout: 0
            }
            if (title) {
                this._navigatorDownloadFile(requestConfig, documentId, title)
            } else {
                return new Promise((resolve, reject) => {
                    from(axios.request(requestConfig)).pipe(
                        map(result => result),
                        catchError(() => {
                            reject()
                            return of()
                        })
                    ).subscribe((observer) => {
                        if (!observer) { return }
                        const { data } = observer
                        resolve(data)
                    })
                })
            }
        },

        async _navigatorDownloadFile(requestConfig, documentId, title, retry = 0) {
            const downloading = await store.dispatch("isAlreadyDownloading", documentId)
            if (downloading) { return }

            store.dispatch("setProgressDownload", [{ progress: 0, filename: title, documentId }]);
            requestConfig.onDownloadProgress = (progressEvent) => {
                const backupSize = progressEvent.event.currentTarget.getResponseHeader('backup-size')
                const total = progressEvent.total ? progressEvent.total : backupSize ? backupSize : 1
                const percentage = Math.round((progressEvent.loaded * 100) / total)
                store.dispatch("setProgressDownload", [{ progress: percentage, filename: title, documentId }]);
            }

            from(axios.request(requestConfig)).pipe(
                map(result => result),
                catchError((error) => {
                    console.log(error)
                    store.dispatch("removeDocument", { filename: title, isUpload: true })
                    return retry < 1 ?
                        this._navigatorDownloadFile(requestConfig, documentId, title, retry + 1) :
                        of(null)
                })
            ).subscribe((observer) => {
                if (!observer) { return }
                const { data } = observer
                const url = window.URL.createObjectURL(new Blob([data]))
                const downloadLink = document.createElement('a')
                downloadLink.href = url
                downloadLink.download = title
                downloadLink.click()
                downloadLink.remove()
            })
        }
    }
};
