import frDateFormat from "./fr";
import enDateFormat from "./en";

export default {
  fr: frDateFormat,
  "fr-FR": frDateFormat,
  "fr-BE": frDateFormat,
  "fr-CA": frDateFormat,
  "fr-LU": frDateFormat,
  "fr-CH": frDateFormat,
  en: enDateFormat,
  "en-GB": enDateFormat,
  "en-US": enDateFormat,
};
