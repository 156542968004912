import configuration from "../config";
import axios from "./axiosConfiguration";

const endpointTags = `${configuration.api_url}tags`;

const getTags = async () => {
  return axios
    .get(endpointTags)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
const postTag = async (tag) => {
  return axios
    .post(`${endpointTags}`, tag)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const deleteTag = async (tagId) => {
  return axios
    .delete(`${endpointTags}/${tagId}`)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getTags,
  postTag,
  deleteTag,
};
