<template>
  <div id="presentation-block" class="grid grid-nogutter">
    <div id="presentation-block__content" class="col-12 lg:col-5">
      <div class="flex align-items">
        <as-title :title="$t('home.presentation_title')" />
      </div>
      <p>
        <strong>{{ $t("home.presentation_paragraph1") }}</strong>
      </p>
      <p>
        {{ $t("home.presentation_paragraph2") }}
      </p>
    </div>
    <div class="lg:col-offset-1"></div>
    <div id="presentation-block__image" class="col-12 lg:col-6">
      <img :src="backgroundImage" />
    </div>
  </div>
</template>

<script>
import AsTitle from "../Elements/AsTitle.vue";
export default {
  name: "Presentation",
  components: {
    AsTitle,
  },
  data() {
    return {
      backgroundImage: require("../../assets/images/presentation.jpeg"),
    };
  },
};
</script>

<style lang="scss" scoped>
#presentation-block {
  &__content {
    padding: 60px;
    padding-bottom: 30px;
    p {
      padding-top: 25px;
      text-align: justify;
      &:last-child {
        padding-bottom: 25px;
      }
    }
  }

  &__image {
    overflow: hidden;
    max-height: 500px;
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }
}
</style>
