import configuration from "../config";
import axios from "./axiosConfiguration";

const endpointEntreprises = `${configuration.api_url}entreprises`;

const getEntreprises = async () => {
  return axios
    .get(endpointEntreprises)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getEntreprises,
};
