<template>
  <div>
    <DataTable :value="declarations" responsiveLayout="scroll">
      <Column :header="$t('share_accident.accident_or_near_miss')">
        <template #body="slotProps">
          {{
            slotProps.data.presqueAccident
              ? $t("share_accident.near_miss")
              : $t("share_accident.accident")
          }}
        </template>
      </Column>
      <Column :header="$t('share_accident.company_column')">
        <template #body="slotProps">
          {{
            slotProps.data.entreprise
              ? slotProps.data.entreprise.details.nom
              : "?"
          }}
        </template>
      </Column>
      <Column
        field="description"
        :header="$t('share_accident.description_column')"
      >
        <template #body="slotProps">
          {{ ellipsis(slotProps.data.description) }}
        </template>
      </Column>
      <Column field="date" :header="$t('share_accident.datetime_column')">
        <template #body="slotProps">
          {{ formatDate(slotProps.data.date) + " " + slotProps.data.heure }}
        </template>
      </Column>
      <Column field="lieu" :header="$t('share_accident.location_column')">
        <template #body="slotProps">
          {{ $t("locations." + slotProps.data.lieu) }}
        </template>
      </Column>
      <Column
        field="categoriePersonne"
        :header="$t('share_accident.category_column')"
      >
        <template #body="slotProps">
          {{ $t("categoryPersons." + slotProps.data.categoriePersonne) }}
        </template>
      </Column>
      <Column :header="$t('share_accident.actions_column')">
        <template #body="slotProps">
          <Button
            :label="$t('share_accident.validate_button')"
            class="button-default"
            icon="pi pi-check"
            iconPos="right"
            @click="validateDeclaration(slotProps.data.id)"
          />
          <Button
            class="p-button-danger pl-2"
            icon="pi pi-trash"
            iconPos="right"
            @click="deleteDeclaration(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import moment from "moment";
import { ellipsis } from "../../util";

export default {
  name: "ListDeclarations",
  components: {
    DataTable,
    Column,
    Button,
  },
  props: {
    declarations: Array,
  },
  emits: ["onShowValidateModal","onShowDeleteModal"],
  methods: {
    validateDeclaration(idDeclaration) {
      this.$emit("onShowValidateModal", idDeclaration);
    },
    deleteDeclaration(idDeclaration) {
      this.$emit("onShowDeleteModal", idDeclaration);
    },
    ellipsis(value) {
      return ellipsis(value);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" >
    .pl-2 {
        margin-left: 0.5rem !important;
    }
</style>