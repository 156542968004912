<template>
  <Button
    id="feedback"
    @click="showModalFeedback"
    icon="pi pi-comment"
    class="p-button-sm p-button-rounded button-primary mr-1"
  />
  <Badge
    id="badge-feedbacks-count"
    v-if="isAdmin"
    :value="feedbacks.length"
    severity="danger"
  ></Badge>
  <Button
    v-if="isAdmin"
    id="showFeedback"
    @click="showModalFeedbackList"
    icon="pi pi-comments"
    class="p-button-sm p-button-rounded button-primary mr-1"
  />
  <as-modal :isVisible="showFeedback" class="reduced-form-modal">
    <feedback-form @onClose="closeModalFeedback" @onSubmit="saveFeedback" />
  </as-modal>
  <as-modal v-if="isAdmin" :isVisible="showFeedbackList">
    <feedback-list
      :feedbacks="feedbacks"
      @onDelete="deleteFeedback"
      @onClose="closeModalFeedbackList"
    />
  </as-modal>
</template>

<script>
import Button from "primevue/button";
import Badge from "primevue/badge";
import AsModal from "@/components/Modals/AsModal.vue";
import FeedbackForm from "@/components/Forms/FeedbackForm.vue";
import FeedbackList from "@/components/Feedback/FeedbackList.vue";
import config from "../config";
import { mapGetters } from "vuex";
import {
  saveFeedback,
  getFeedbacks,
  deleteFeedback,
} from "../services/feedbackServiceApi";
import roleMixins from "../mixins/roleMixins";
import apiEventTypes from "../constantes/apiEventTypes";

export default {
  name: "Feedback",
  components: {
    Button,
    Badge,
    AsModal,
    FeedbackForm,
    FeedbackList,
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    currentPathName() {
      return this.$route.fullPath;
    },
  },
  mixins: [roleMixins],
  watch: {
    isAdmin() {
      this.getFeedbacks();
    },
  },
  methods: {
    showModalFeedback() {
      this.showFeedback = true;
    },
    closeModalFeedback() {
      this.showFeedback = false;
    },
    showModalFeedbackList() {
      this.showFeedbackList = true;
    },
    closeModalFeedbackList() {
      this.showFeedbackList = false;
    },
    async getFeedbacks() {
      if (this.isAdmin) {
        this.feedbacks = await getFeedbacks();
      }
    },
    async saveFeedback(text) {
      const currentRubrique =
        this.currentPathName !== "/"
          ? config.menuPages
            ? config.menuPages.find((e) => e.link === this.currentPathName)
            : null
          : { id: 1 };
      if (currentRubrique) {
        const feedback = {
          idRubrique: currentRubrique.id,
          idLdap: this.user.idLdap,
          date: new Date().toISOString(),
          text: text,
        };
        const savedFeedback = await saveFeedback(feedback);
        if (savedFeedback) {
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_title"),
                message: this.$t("popup_messages.success_created_message"),
              },
            })
          );
        }
      }
    },

    async deleteFeedback(feedbackId) {
      const result = await deleteFeedback(feedbackId);
      if (result) {
        const newFeedbackList = [...this.feedbacks];
        const indexOf = newFeedbackList.findIndex((e) => e.id === feedbackId);
        newFeedbackList.splice(indexOf, 1);
        this.feedbacks = newFeedbackList;
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_SUCCESS, {
            detail: {
              title: this.$t("popup_messages.success_delete_title"),
              message: this.$t("popup_messages.success_delete_message"),
            },
          })
        );
      }
    },
  },
  data() {
    return {
      showFeedback: false,
      showFeedbackList: false,
      feedbacks: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors.scss";
#feedback,
#showFeedback {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  padding: 25px;
  margin: 10px !important;
  border: 1px solid $default_color;
  .p-button-icon {
    font-size: 1.5rem;
  }
}
#showFeedback {
  right: 60px;
}

#badge-feedbacks-count {
  position: fixed;
  bottom: 45px;
  right: 65px;
  z-index: 11;
}
</style>
