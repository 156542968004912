<template>
  <div class="container-block" :style="backgroundStyles">
    <div class="content-container-block">
      <as-block :shadow="true" :gutters="gutters">
        <slot />
      </as-block>
    </div>
  </div>
</template>

<script>
import AsBlock from "./AsBlock.vue";

export default {
  name: "AsContainerBlock",
  components: {
    AsBlock,
  },
  props: {
    backgroundImg: String,
    gutters: {
      type: String,
      default: () => {
        if (window.screen.width < 425) {
          return "10px";
        } else {
          return "40px";
        }
      },
    },
  },
  computed: {
    backgroundStyles() {
      return {
        "background-image": this.backgroundImg ? this.backgroundImg : undefined,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/sizing.scss";

.container-block {
  padding-top: $container_block_padding;
  padding-bottom: $container_block_padding;
  width: 100%;
  display: flex;
  justify-content: center;
  .content-container-block {
    width: $sm_container_width;
  }
  @media screen and (min-width: 1360px) {
    .content-container-block {
      width: $xl_container_width;
    }
  }
}
</style>
