<template>
  <div class="as-cms-page">
    <as-container>
      <div class="grid as-cms-page__content">
        <div class="col-12 lg:col-8">
          <slot name="headerPage"> </slot>
          <slot>
            <div v-if="articles.length === 0" class="empty grid">
              <div class="col flex justify-content-center align-items-center flex-column">
                <as-title :title="$t('cms_page.no_element')" :size="5" />
                <Button
                  v-if="isAdmin && !modelValue && !hideEnterEditMode"
                  @click="enterEditMode"
                  class="button-default mr-2"
                  icon="pi pi-pencil"
                  iconPos="right"
                  :label="$t('cms_page.enter_edit_mode')"
                >
                </Button>
              </div>
            </div>
            <div
              class="article"
              :class="[{ edition: modelValue }]"
              v-for="(article, index) in updatedArticles"
              :key="
                articleKey[article.id]
                  ? articleKey[article.id]
                  : article.id + article.ordre + index
              "
              :id="`art_${article.id}`"
            >
              <as-article-title
                :first="index === 0"
                :last="index === updatedArticles.length - 1"
                v-model="article.titre"
                :editMode="modelValue"
                @onEnterEditMode="enterEditMode"
                @onDelete="handleDeleteArticle(article.id)"
                @onUp="upArticle(article.id, index)"
                @onDown="downArticle(article.id, index)"
              />
              <as-block shadow :gutters="!modelValue ? '20px' : '0'">
                <div v-if="!modelValue" v-html="article.html"></div>
                <Editor
                  v-else
                  v-model="article.html"
                  api-key="xd809ttvkuqrskp59c9i51jewqsfhjfulzkxm49rerbfbgio"
                  :init="configTinyMce(index)"
                  editorStyle="height: 320px; width:100%"
                  @change="dirtyArticle(article.id)"
                >
                  <template #toolbar>
                    <span class="ql-formats">
                      <button class="ql-bold"></button>
                      <button class="ql-italic"></button>
                      <button class="ql-underline"></button>
                    </span>
                    <span class="ql-formats">
                      <select class="ql-header">
                        <option selected></option>
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                        <option value="4"></option>
                        <option value="5"></option>
                        <option value="6"></option>
                      </select>
                      <button class="ql-list" value="ordered" />
                      <button class="ql-list" value="bullet" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                    </span>
                    <span class="ql-formats">
                      <select class="ql-align">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                      </select>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-blockquote"></button>
                      <button class="ql-link"></button>
                      <button class="ql-image"></button>
                      <button class="ql-video"></button>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-clean"></button>
                    </span>
                  </template>
                </Editor>
                <div class="editor-action-bar">
                  <Button
                    v-show="modelValue && dirty[article.id] === true"
                    icon="pi pi-times"
                    class="p-button-sm button-default mr-2"
                    iconPos="right"
                    :label="$t('cms_page.cancel')"
                    @click="disableChanges(article.id)"
                    :loading="isBusy"
                  >
                  </Button>
                  <Button
                    v-show="modelValue && dirty[article.id] === true"
                    icon="pi pi-check"
                    class="p-button-sm button-primary"
                    iconPos="right"
                    :label="$t('cms_page.save')"
                    @click="saveArticle(article.id)"
                    :loading="isBusy"
                  >
                  </Button></div
              ></as-block>
            </div>
          </slot>
        </div>
        <div class="col-12 lg:col-4">
          <div>
            <div class="pb-2 title-left-panel">
              <slot name="titleLeftPanel">
                <as-title :title="$t('cms_page.documents_title')" :size="5" />
              </slot>
            </div>
            <as-block shadow
              ><slot name="leftPanel">
                <as-fichiers-list
                  :rubrique="rubrique"
                  :editMode="modelValue"
                  @onDelete="reloadRubrique"
                  @onCategoryToReload="reloadRubrique"
                  @onUpload="uploadDocument"
                /> </slot
            ></as-block>
          </div>
        </div>
      </div>
      <as-cms-actions-tab
        v-show="modelValue === true"
        @onAddArticle="addArticle"
        @onQuitEdition="handleQuitEditMode"
        :beingTranslated="beingTranslated"
        :customAction1Label="customAction1Label"
        :customAction1Icon="customAction1Icon"
        @customAction1="customAction1"
      />
    </as-container>
    <as-confirm-modal
      :isVisible="showConfirmModal"
      :modalMessage="modalMessage"
      @onConfirm="confirm"
      @onCancel="cancel"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import AsBlock from "@/components/Containers/AsBlock.vue";
import AsContainer from "@/components/Containers/AsContainer.vue";
import AsConfirmModal from "@/components/Modals/AsConfirmModal.vue";
import AsTitle from "@/components/Elements/AsTitle.vue";
import AsArticleTitle from "@/components/Elements/AsArticleTitle.vue";
import AsFichiersList from "@/components/Elements/AsFichiersList.vue";
import Button from "primevue/button";
import Editor from "@tinymce/tinymce-vue";
import { getTinyMceConfig } from "../../config/tinymce.config";
import AsCmsActionsTab from "./AsCmsActionsTab.vue";
import { mapGetters } from "vuex";

import articlesServiceApi from "@/services/articlesServiceApi";
import modalModes from "../../constantes/modalModes";
import apiEventTypes from "../../constantes/apiEventTypes";
import roleMixins from "../../mixins/roleMixins";
import { translate } from "../../services/googleTranslateService";

export default {
  name: "AsCmsPageLayout",
  components: {
    AsContainer,
    AsConfirmModal,
    AsBlock,
    AsTitle,
    AsArticleTitle,
    AsFichiersList,
    Button,
    Editor,
    AsCmsActionsTab,
  },
  props: {
    title: String,
    content: String,
    articles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rubrique: {
      type: Object,
    },
    modelValue: null,
    idRubrique: Number,
    hideEnterEditMode: {
      type: Boolean,
      default: false,
    },
    customAction1Label: String,
    customAction1Icon: String,
  },
  data() {
    return {
      modalMessage: "",
      modalMode: "",
      selectDeletion: NaN,
      showConfirmModal: false,
      updatedArticles: this.articles.map((el) => {
        return {
          ...el,
        };
      }),
      dirty: {},
      order: NaN,
      isBusy: false,
      configTinyMce: (index) => {
        return getTinyMceConfig(this.translateTo, index);
      },
      selectedLocale: this.$i18n.locale,
      articleKey: {},
      beingTranslated: false,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    ...mapGetters({
      languages: "getLanguages",
    }),
  },
  mixins: [roleMixins],
  watch: {
    locale() {
      if (this.hasNotSavedData()) {
        this.modalMessage = this.$t("confirm_modal.updates_not_saved_message");
        this.modalMode = modalModes.CONFIRM_CHANGELOCALE;
        this.showConfirmModal = true;
      } else {
        this.reloadArticles();
      }
    },
    articles(newValues) {
      this.updatedArticles = newValues;
      this.order =
        this.updatedArticles && this.updatedArticles.length > 0
          ? this.updatedArticles[this.updatedArticles.length - 1].ordre + 1
          : 1;
    },
  },
  emits: [
    "reloadArticles",
    "onEnterEditMode",
    "onQuitEditMode",
    "update:modelValue",
    "reloadRubrique",
    "customAction1",
  ],
  methods: {
    // enterEditModeAndAdd() {
    //   this.enterEditMode();
    //   this.addArticle();
    // },
    enterEditMode() {
      if (this.isAdmin) {
        this.$emit("update:modelValue", true);
      }
    },
    handleQuitEditMode() {
      if (this.hasNotSavedData()) {
        this.modalMessage = this.$t("confirm_modal.updates_not_saved_message");
        this.modalMode = modalModes.CONFIRM_QUIT;
        this.showConfirmModal = true;
      } else {
        this.quitEditMode();
      }
    },
    hasNotSavedData() {
      let notSavedData = false;
      for (const key in this.dirty) {
        if (this.dirty[key] === true) {
          notSavedData = true;
        }
      }
      return notSavedData;
    },
    quitEditMode() {
      this.$emit("update:modelValue", false);
    },
    dirtyArticle(articleId) {
      this.dirty[articleId] = true;
    },
    disableChanges(articleId) {
      this.updatedArticles.find((el) => el.id === articleId).html =
        this.articles.find((el) => el.id === articleId).html;
      this.dirty[articleId] = false;
    },
    saveArticle(articleId) {
      this.isBusy = true;
      this.saveOnlyArticle(articleId)
        .then(() => {
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_title"),
                message: this.$t("popup_messages.success_message"),
              },
            })
          );
          this.dirty[articleId] = false;
          this.isBusy = false;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
    saveOnlyArticle(articleId) {
      const articleToSave = this.updatedArticles.find(
        (el) => el.id === articleId
      );
      delete articleToSave.langue;
      return articlesServiceApi
        .updateArticle(articleId, articleToSave)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    saveDirtyArticles() {
      let promises = [];
      for (const key in this.dirty) {
        if (this.dirty[key] === true) {
          promises.push(this.saveOnlyArticle(parseInt(key)));
        }
      }
      return Promise.all(promises)
        .then(() => {
          this.dirty = {};
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_title"),
                message: this.$t("popup_messages.success_message"),
              },
            })
          );
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    addArticle() {
      this.updatedArticles.push();
      this.newId -= 1;
      articlesServiceApi
        .saveArticle({
          idLangue: this.languages.find(
            (el) => el.codeIso === this.$i18n.locale
          ).id,
          idRubrique: this.idRubrique,
          ordre: this.order,
          titre: "",
          html: "",
        })
        .then((article) => {
          if (article && article.id) {
            this.articleKey[article.id] = Math.random() + article.id;
          }
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_created_title"),
                message: this.$t("popup_messages.success_created_message"),
              },
            })
          );
          this.reloadArticles();
        });
    },
    handleDeleteArticle(articleId) {
      this.modalMessage = this.$t("confirm_modal.delete_message");
      this.modalMode = modalModes.CONFIRM_DELETE;
      this.selectDeletion = articleId;
      this.showConfirmModal = true;
    },
    deleteArticle(articleId) {
      this.dirty[articleId] = undefined;
      articlesServiceApi.deleteArticle(articleId).then(() => {
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_SUCCESS, {
            detail: {
              title: this.$t("popup_messages.success_delete_title"),
              message: this.$t("popup_messages.success_delete_message"),
            },
          })
        );
        this.reloadArticles();
      });
    },
    upArticle(articleId, index) {
      if (index === 0) {
        return;
      } else {
        const indexOf = this.updatedArticles.findIndex(
          (e) => e.id === articleId
        );
        let articleToDown = this.updatedArticles[indexOf - 1];
        let articleToUp = this.updatedArticles[indexOf];
        articlesServiceApi
          .updateOrderArticle(
            { id: articleToUp.id, ordre: articleToUp.ordre },
            articleToUp.id,
            articleToDown.id
          )
          .then(() => {
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.API_SUCCESS, {
                detail: {
                  title: this.$t("popup_messages.success_title"),
                  message: this.$t("popup_messages.success_message"),
                },
              })
            );
            this.reloadAndSortArticle(articleToDown, articleToUp);
          });
      }
    },
    downArticle(articleId, index) {
      if (index === this.updatedArticles.length - 1) {
        return;
      } else {
        const indexOf = this.updatedArticles.findIndex(
          (e) => e.id === articleId
        );
        let articleToDown = this.updatedArticles[indexOf];
        let articleToUp = this.updatedArticles[indexOf + 1];
        articlesServiceApi
          .updateOrderArticle(
            { id: articleToDown.id, ordre: articleToDown.ordre },
            articleToDown.id,
            articleToUp.id
          )
          .then(() => {
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.API_SUCCESS, {
                detail: {
                  title: this.$t("popup_messages.success_title"),
                  message: this.$t("popup_messages.success_message"),
                },
              })
            );

            this.reloadAndSortArticle(articleToDown, articleToUp);
          });
      }
    },

    reloadAndSortArticle(articleToDown, articleToUp) {
      const articleToDownOrder = articleToDown.ordre;
      const articleToUpOrder = articleToUp.ordre;
      articleToUp.ordre = articleToDownOrder;
      articleToDown.ordre = articleToUpOrder;
      this.updatedArticles.sort((a, b) => a.ordre - b.ordre);
    },
    uploadDocument() {
      this.reloadRubrique();
    },
    confirm() {
      switch (this.modalMode) {
        case modalModes.CONFIRM_DELETE:
          this.deleteArticle(this.selectDeletion);
          this.selectDeletion = NaN;
          break;
        case modalModes.CONFIRM_QUIT:
          this.saveDirtyArticles().then(() => {
            this.quitEditMode();
          });
          break;
        case modalModes.CONFIRM_CHANGELOCALE:
          this.saveDirtyArticles().then(() => {
            this.reloadArticles();
          });
          break;
      }
      this.showConfirmModal = false;
    },
    cancel() {
      switch (this.modalMode) {
        case modalModes.CONFIRM_QUIT:
          this.quitEditMode();
          break;
        case modalModes.CONFIRM_CHANGELOCALE:
          this.reloadArticles();
          break;
      }
      this.showConfirmModal = false;
    },
    reloadArticles() {
      this.$emit("reloadArticles");
    },
    reloadRubrique() {
      this.$emit("reloadRubrique");
    },
    customAction1() {
      this.$emit("customAction1");
    },

    getLangue(article) {
      return this.languages.find((el) => el.id === article.idLangue);
    },

    async translateTo(index) {
      const article = this.updatedArticles[index];
      const target = this.getLangue(article);
      this.beingTranslated = true;

      const stringsToTranslate = [article.titre, article.html];

      const translatedData = await translate(
        stringsToTranslate,
        target.codeIso
      );

      if (stringsToTranslate.length === translatedData.length) {
        article.titre = translatedData.splice(0, 1)[0];
        article.html = translatedData.splice(0, 1)[0];
        const newList = [...this.updatedArticles];
        newList.splice(index, 1, article);
        this.articleKey[article.id] = Math.random() + article.id;
        this.updatedArticles = newList;
      }

      this.beingTranslated = false;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";

.title-left-panel {
  .title {
    color: $light_font;
  }
}
.empty {
  .title {
    color: $light_font;
  }
}
.article {
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-left {
    text-align: left;
  }
  .ql-align-justify {
    text-align: justify;
  }
  &:first-child {
    .title {
      color: $light_font;
    }
  }
  &:not(:first-child) {
    padding-top: 20px;
  }
  img {
    max-width: 100% !important;
    height: auto;
  }
  &:not(.edition) {
    ul,
    ol {
      padding-left: 1.5rem;
    }
    ol {
      li {
        list-style: decimal;
      }
    }
    ul {
      li {
        list-style: disc;
      }
    }
  }
  position: relative;
  .editor-action-bar {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }

  .p-editor-content {
    min-height: 320px;
    height: auto !important;
  }
  &.edition {
    .p-inputtext {
      flex: 1;
    }
  }
}

@media screen and (max-width: 991px) {
  .as-cms-page {
    &__content {
      display: flex;
    }
  }
}
</style>
