import { mapGetters } from "vuex";

export default {
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    currentLocale() {
      return this.languages.find((el) => el.codeIso.includes(this.locale));
    },
    ...mapGetters({
      languages: "getLanguages",
    }),
  },
  watch: {
    locale() {
      this.onLocaleChange();
    },
  },
  emits: ["onLocaleChange"],
  methods: {
    onLocaleChange() {
      this.$emit("onLocaleChange");
    },
  },
};
