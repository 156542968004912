import { createApp } from "vue";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./locales";
import "primevue/resources/themes/saga-blue/theme.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./assets/styles/main.scss";
import VueGtag from "vue-gtag";

const app = createApp({
    extends: App,
});
app.use(VueGtag, {
    config: { id: "G-DZ46JL4TTJ" }
})
app.use(store);
app.use(router);
app.use(i18n);
app.use(PrimeVue);
app.use(ToastService);
app.directive("tooltip", Tooltip);
app.mount("#app");

(function (h, o, t, j, a, r) {
    h.hj =
        h.hj ||
        function () {
            (h.hj.q = h.hj.q || []).push(arguments);
        };
    h._hjSettings = { hjid: 2899137, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
})(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
