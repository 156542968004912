<template>
  <div id="best-practices">
    <as-container>
      <div class="grid">
        <div class="col-12">
          <div>
            <div class="flex justify-content-between mb-1">
              <as-title :title="$t('best_practices.page_title')" :size="1" />
              <div class="formgroup-inline">
                <div class="field">
									<IconField iconPosition="left">
                    <InputText type="text" v-model="bestPracticeSearchText"
                      :placeholder="$t('best_practices.search_best_practice')" class="search"
                      @keypress.enter="searchBestPractice()" />
    								<InputIcon class="pi pi-search"> </InputIcon>
									</IconField>
                  <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText type="text" v-model="bestPracticeSearchText"
                      :placeholder="$t('best_practices.search_best_practice')" class="search"
                      @keypress.enter="searchBestPractice()" />
                  </span> -->
                </div>
                <Button icon="pi pi-search" class="p-button-sm button-default mr-2 mb-1"
                  v-tooltip.top="$t('best_practices.search_best_practice')" @click="searchBestPractice()" />
              </div>
              <Button v-show="!editMode && (isSafetyLeader || isAdmin)" @click="editMode = true" icon="pi pi-pencil"
                class="p-button-sm p-button-rounded button-default reversed" />
            </div>
            <as-block shadow>
              <div id="bp-grid" class="grid">
                <div id="bp-list" class="xl:col-4 lg:col-5 col-12">
                  <ScrollPanel style="width: 100%; height: 70vh; max-height: 100vh" class="custombar">
                    <Tree :value="mappedCategories" :filter="false"
                      filterBy="label,referentialSection,entreprise.details.nom" filterMode="lenient"
                      selectionMode="single" :expandedKeys="expandedKeys" @nodeSelect="onSelectCategory"
                      :loading="isBusy">
                      <template #rubrique="slotProps">
                        <div class="flex">
                          <span>{{ $t("best_practices.page_title") }}</span>
                          <div>
                            <Button v-show="editMode && isAdmin" icon="pi pi-plus pi-folder"
                              class="p-button-sm button-default mr-2 mb-1"
                              v-tooltip.top="$t('best_practices.add_category')" @click="addCategory(slotProps.node)" />
                          </div>
                        </div>
                      </template>
                      <template #category="slotProps">
                        <div class="flex">
                          <span :class="slotProps.node.class">{{
                            slotProps.node.label
                          }}</span>
                          <div>
                            <Button v-show="editMode" type="button" icon="pi pi-cog"
                              class="p-button p-button-sm button-default mr-2 mb-1"
                              @click="toggleMenu($event, slotProps.node)" />
                            <Menu ref="menu" :model="items" :popup="true" v-if="slotProps.node.level < 2" />
                          </div>
                        </div>
                      </template>
                    </Tree>
                  </ScrollPanel>
                </div>
                <ProgressSpinner class="shared-bestpractice-spinner" v-if="isLoadSharedBestPractice" />
                <div class="xl:col-8 lg:col-7 col-12" v-if="!isLoadSharedBestPractice &&
                  bestPracticesLoaded &&
                  bestPracticesBlockTitle.length > 0
                  ">
                  <div>
                    <div class="col-12 flex justify-content-between">
                      <span>{{
                        bestPracticesBlockTitle +
                        ": ( " +
                        $t(
                          "best_practices.numberOfItemsLabel",
                          bestPracticesLoaded.length,
                          { count: bestPracticesLoaded.length }
                        ) +
                        " )"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <best-practice-block v-show="!isBusy" v-for="(bestPractice, index) in bestPracticesLoaded" :key="`${index}${getBestPracticesNotationsReduced(
                        bestPractice.id
                      )}`" :bestPractice="bestPractice" :editMode="editMode" :globalNotation="bestPracticesNotationsReduced[bestPractice.id]
  " @onEdit="handleUpdateBestPractice(bestPractice)" @onDelete="handleDeleteBestPractice(bestPractice)"
                        @onShow="openBestPracticesDetails" />
                    </div>
                  </div>
                  <div class="h-100 flex align-items">
                    <ProgressSpinner v-if="isBusy" />
                  </div>
                </div>
              </div>
            </as-block>
          </div>
        </div>
      </div>
      <div v-show="editMode" class="grid actions-tab align-items w-100 justify-content-center">
        <Button icon="pi pi-plus" class="button-default mr-2" :label="$t('best_practices.add_best_practice')"
          v-tooltip.top="$t('best_practices.add_best_practice')" iconPos="right" @click="addBestPractice()" />
        <Button @click="editMode = false" class="button-default" icon="pi pi-sign-out" iconPos="right"
          :label="$t('cms_page.quit_edit_mode')">
        </Button>
      </div>
    </as-container>
    <as-modal v-if="isAdmin || isSafetyLeader" :isVisible="showAddModal">
      <best-practices-form v-if="showAddModal" :title="$t('best_practices_form.title')" :rubriqueBestPractice="rubrique"
        :isBusy="isBusy" @onClose="closeBestPractice" @onSubmit="saveBestPractices" />
    </as-modal>

    <as-modal v-if="isAdmin || isSafetyLeader" :isVisible="showUpdateModal">
      <best-practices-form v-if="showUpdateModal" :title="$t('best_practices_form.title_update')" :rubrique="rubrique"
        :isBusy="isBusy" :update="true" :bestPractice="selectedBestPractice" :rubriqueBestPractice="rubrique"
        @onClose="closeBestPractice" @onSubmit="updateBestPractice" />
    </as-modal>
    <as-confirm-modal :isVisible="showConfirmModal" :modalMessage="$t('confirm_modal.delete_message')"
      @onConfirm="confirmDelete" @onCancel="cancelDelete" />
    <as-confirm-modal :isVisible="showConfirmModalDeleteCategory" :modalMessage="modalMessage"
      @onConfirm="confirmDeleteCategory" @onCancel="cancelDeleteCategory" />
    <as-modal v-if="isAdmin" :isVisible="showAddCategoryModal" class="reduced-form-modal">
      <category-form v-if="showAddCategoryModal" :isBusy="isBusy" :node="selectedNode"
        @onClose="showAddCategoryModal = false" @onSubmit="saveCategory" />
    </as-modal>
    <as-modal v-if="isAdmin" :isVisible="showEditCategoryModal" class="reduced-form-modal">
      <category-form v-if="showEditCategoryModal" :categorie="categoryToEdit" :isBusy="isBusy" :node="node"
        @onClose="showEditCategoryModal = false" @onSubmit="updateCategory" />
    </as-modal>
    <as-modal :isVisible="showDetailModal" :isCloseable="true" @onClose="closeBestPracticesDetails">
      <best-practice-info v-if="showDetailModal" :rubriqueBestPractice="rubrique" :bestPractice="selectedBestPractice"
        :image="selectedBestPracticeImage" :userNotation="getUserNotation(selectedBestPractice)"
        :notationList="getNotationList(selectedBestPractice)" @onNotation="handleUpdateBestPracticeNotation" />
    </as-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import AsBlock from "@/components/Containers/AsBlock.vue";
import AsContainer from "@/components/Containers/AsContainer.vue";
import AsTitle from "@/components/Elements/AsTitle.vue";
// import AsIcon from "@/components/Elements/AsIcon.vue";
import AsModal from "@/components/Modals/AsModal.vue";
import AsConfirmModal from "@/components/Modals/AsConfirmModal.vue";
import BestPracticeBlock from "@/components/BestPractices/BestPracticeBlock.vue";
import BestPracticeInfo from "@/components/BestPractices/BestPracticeInfo.vue";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import BestPracticesForm from "@/components/Forms/BestPracticesForm.vue";
import CategoryForm from "@/components/Forms/CategoryForm.vue";
import roleMixins from "@/mixins/roleMixins";
import localeChangeDetectionMixins from "@/mixins/localeChangeDetectionMixins";
import bestPracticesServiceApi from "@/services/bestPracticesServiceApi";
import fichiersServiceApi from "@/services/fichiersServiceApi";
import rubriquesServiceApi from "@/services/rubriquesServiceApi";
import apiEventTypes from "@/constantes/apiEventTypes";
import ScrollPanel from "primevue/scrollpanel";
import Tree from "primevue/tree";
import Menu from "primevue/menu";
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from 'primevue/inputicon';
import categoryServiceApi from "../services/categoryServiceApi";
import rubriques from "@/constantes/rubriques";
import { mapGetters } from "vuex";
import { bufferToBlobUrl } from "../util";

export default {
  name: "BestPractices",
  components: {
    AsBlock,
    AsContainer,
    AsTitle,
    // AsIcon,
    AsModal,
    AsConfirmModal,
    Button,
    ProgressSpinner,
    // Tag,
    BestPracticesForm,
    BestPracticeBlock,
    BestPracticeInfo,
    ScrollPanel,
    Tree,
    Menu,
    InputText,
		IconField,
		InputIcon,
    CategoryForm,
  },
  data() {
    return {
      isBusy: false,
      rubriqueFromCategoryToDelete: NaN,
      categoryToDelete: NaN,
      editMode: false,
      fileBlob: undefined,
      bestPractices: {},
      bestPracticesBlockTitle: "",
      keyword: "",
      modalMessage: undefined,
      showAddModal: false,
      showUpdateModal: false,
      showConfirmModal: false,
      showConfirmModalDeleteCategory: false,
      categoryToEdit: NaN,
      showEditCategoryModal: false,
      idRubrique: rubriques.best_practices,
      rubrique: undefined,
      bestPracticeSearchText: "",
      selectedNode: undefined,
      selectedBestPractice: undefined,
      selectedBestPracticeImage: undefined,
      showAddCategoryModal: false,
      showDetailModal: false,
      onDelete: false,
      expandedKeys: {},
      bestPracticesLoaded: [],
      countByCategories: [],
      bestPracticesNotations: [],
      bestPracticesNotationsReduced: {},
      items: [
        {
          label: this.$t("cms_page.add_category"),
          icon: "pi pi-plus",
          command: () => {
            this.addCategory(this.$refs.menu.currentNode);
          },
        },
        {
          label: this.$t("cms_page.edit_category"),
          icon: "pi pi-pencil",
          command: () => {
            this.handleEditCategory(this.$refs.menu.currentNode.id);
          },
        },
        {
          visible: () => {
            return (
              this.$refs.menu &&
              this.$refs.menu.currentNode &&
              this.$refs.menu.currentNode.children &&
              this.$refs.menu.currentNode.children.length === 0 &&
              this.bestPracticesLoaded.length === 0
            );
          },
          label: this.$t("cms_page.delete_category"),
          icon: "pi pi-trash",
          command: () => {
            this.handleDeleteCategory(this.$refs.menu.currentNode.id);
          },
        },
      ],
      isLoadSharedBestPractice: false,
      topBpKey: "c-top-bp",
      sseObservable: undefined,
    };
  },
  watch: {
    async mappedCategories() {
      this.$nextTick(() => {
        this.expandAll();
      });
    },
  },
  computed: {
    mappedCategories() {
      let categories = [];
      if (this.rubrique) {
        const childs = this.rubrique.categories
          ? this.rubrique.categories
            .map((cat) => {
              return this.mapCategory(cat, 0);
            })
            .concat(
              this.bestPractices[0] !== undefined ? this.bestPractices[0] : []
            )
          : [];
        categories = [
          {
            id: this.rubrique.id,
            codeRubrique: this.rubrique.codeRubrique,
            externalId: this.rubrique.externalId,
            type: "rubrique",
            key: "r-" + this.rubrique.id,
            leaf: true,
            icon: "pi pi-fw pi-folder",
            level: 0,
            selectable: false,
            children: this.sortCategories(childs),
          },
        ];

        const topCategory = {
          key: this.topBpKey,
          label: this.$t("best_practices.top_title"),
          labelForTitle: this.$t("best_practices.top_title"),
          type: "category",
          level: 1,
          icon: "pi pi-fw pi-folder categorie",
          class: "categorie",
          children: [
            {
              id: `${this.topBpKey}-rated`,
              key: `${this.topBpKey}-rated`,
              label: this.$t("best_practices.top_most_viewved_title"),
              labelForTitle: this.$t("best_practices.top_most_viewved_title"),
              type: "category",
              level: 2,
              icon: "pi pi-fw pi-folder sousCategorie",
              class: "sousCategorie",
              leaf: true,
            },
            {
              id: `${this.topBpKey}-consulted`,
              key: `${this.topBpKey}-consulted`,
              label: this.$t("best_practices.top_most_consulted"),
              labelForTitle: this.$t("best_practices.top_most_consulted"),
              type: "category",
              level: 2,
              icon: "pi pi-fw pi-folder sousCategorie",
              class: "sousCategorie",
              leaf: true,
            },
          ],
          leaf: true,
        };
        categories[0].children.unshift(topCategory);
      }
      return this.sortCategories(categories);
    },
    ...mapGetters({
      user: "getUser",
    }),
  },
  mixins: [roleMixins, localeChangeDetectionMixins, fichiersServiceApi],
  async beforeMount() {
    this.isBusy = true;
    await this.loadCategories();
    await this.loadBestPracticesNotations();
    this.isBusy = false;

    if (this.$route.params.id) {
      this.isLoadSharedBestPractice = true;
      const result = await bestPracticesServiceApi.getBestPractice(
        this.$route.params.id
      );
      if (result) {
        try {
          const image = await this.downloadFile(result.photo.externalId);
          this.openBestPracticesDetails(result, bufferToBlobUrl(image));
        } catch (error) {
          console.log(error)
        }
      }
      this.isLoadSharedBestPractice = false;
    }
  },
  methods: {
    toggleMenu(event, currentNode) {
      this.$refs.menu.currentNode = currentNode;
      this.$refs.menu.toggle(event);
    },
    handleDeleteCategory(categoryId) {
      this.rubriqueFromCategoryToDelete = this.rubrique.id;
      this.categoryToDelete = categoryId;
      this.modalMessage = this.$t("confirm_modal.delete_message");
      this.showConfirmModalDeleteCategory = true;
    },
    handleEditCategory(categoryId) {
      rubriquesServiceApi
        .getCategory(this.rubrique.id, categoryId)
        .then((result) => {
          this.categoryToEdit = result;
          this.showEditCategoryModal = true;
        });
    },
    expandAll() {
      for (let node of this.mappedCategories) {
        this.expandNode(node);
      }
      this.expandedKeys = { ...this.expandedKeys };
    },
    expandNode(node) {
      this.expandedKeys[node.key] = true;
      if (node.children && node.children.length) {
        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    mapCategory(category, level) {
      let nbBestPractice;
      let nbBestPracticeLabel = "";
      if (this.countByCategories && level >= 1) {
        nbBestPractice = this.countByCategories.find(
          (m) => m.id_categorie === category.id
        );
        nbBestPracticeLabel = " (0)";
        if (nbBestPractice) {
          nbBestPracticeLabel = " (" + nbBestPractice.nb + ")";
        }
      }
      const chlids = category.children
        ? category.children.map((cat) => {
          return this.mapCategory(cat, level + 1);
        })
        : [];

      return {
        key: `c-${category.id}`,
        label: category.detail
          ? category.detail[0].libelle + nbBestPracticeLabel + ""
          : "",
        labelForTitle: category.detail ? category.detail[0].libelle + "" : "",
        ...category,
        type: "category",
        level: level + 1,
        selectable: level >= 1,
        icon:
          level + 1 > 1
            ? "pi pi-fw pi-folder sousCategorie"
            : "pi pi-fw pi-folder categorie",
        class: level + 1 > 1 ? "sousCategorie" : "categorie",
        children: this.sortCategories(chlids),
        leaf: true,
      };
    },
    handleDeleteBestPractice(bestPractice) {
      this.selectedBestPractice = bestPractice;
      this.onDelete = true;
      this.showConfirmModal = true;
    },
    handleUpdateBestPractice(bestPractice) {
      this.selectedBestPractice = bestPractice;
      this.onUpdate = true;
      this.showUpdateModal = true;
    },
    cancelUpdateBestPractice() {
      this.selectedBestPractice = undefined;
      this.onUpdate = false;
      this.showUpdateModal = false;
    },
    confirmDelete() {
      this.showConfirmModal = false;
      this.deleteBestPractice();
    },
    cancelDelete() {
      this.showConfirmModal = false;
      this.onDelete = false;
    },
    confirmDeleteCategory() {
      rubriquesServiceApi
        .deleteCategory(
          this.rubriqueFromCategoryToDelete,
          this.categoryToDelete
        )
        .then(() => {
          this.rubriqueFromCategoryToDelete = NaN;
          this.categoryToDelete = NaN;
          this.showConfirmModalDeleteCategory = false;
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_delete_title"),
                message: this.$t("popup_messages.success_delete_message"),
              },
            })
          );
          this.loadCategories();
        })
        .catch((e) => {
          console.error(e);
          this.rubriqueFromCategoryToDelete = NaN;
          this.categoryToDelete = NaN;
          this.showConfirmModalDeleteCategory = false;
        });
    },
    cancelDeleteCategory() {
      this.showConfirmModalDeleteCategory = false;
    },
    addCategory(node) {
      this.selectedNode = node;
      this.showAddCategoryModal = true;
    },
    async saveCategory(object) {
      this.isBusy = true;
      try {
        switch (object.node.type) {
          case "rubrique": {
            await categoryServiceApi.createCategory(
              {
                idRubrique: object.node.id,
                detail: object.detail,
              }
            )
            break;
          }
          case "category": {
            await categoryServiceApi.createCategory(
              {
                idRubrique: 8,
                idCategorieParent: object.node.id,
                detail: object.detail,
              }
            );
            break;
          }
        }
        this.isBusy = false;
        this.showAddCategoryModal = false;
        this.loadCategories();
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_SUCCESS, {
            detail: {
              title: this.$t("popup_messages.success_title"),
              message: this.$t("popup_messages.success_message"),
            },
          })
        );
      } catch (e) {
        console.error(e);
        this.isBusy = false;
      }
    },
    async updateCategory(object) {
      this.isBusy = true;
      try {
        this.categoryToEdit.detail = object.detail;
        await categoryServiceApi.updateCategory(
          this.categoryToEdit
        );
        this.loadCategories();
        this.loadBestPractices(this.categoryToEdit.id);
        this.showEditCategoryModal = false;
        this.isBusy = false;
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_SUCCESS, {
            detail: {
              title: this.$t("popup_messages.success_title"),
              message: this.$t("popup_messages.success_message"),
            },
          })
        );
      } catch (e) {
        console.error(e);
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_ERROR, {
            detail: {
              title: this.$t("popup_messages.default_error_title"),
              message: this.$t("popup_messages.default_error_message"),
            },
          })
        );
        this.isBusy = false;
      }
    },
    addBestPractice() {
      this.showAddModal = true;
    },
    async loadCategories() {
      this.countByCategories =
        await bestPracticesServiceApi.getCountByCategories();
      this.rubrique = await rubriquesServiceApi.getRubrique(this.idRubrique);
    },
    loadBestPracticesNotations() {
      return bestPracticesServiceApi
        .getBestPracticesNotations()
        .then((result) => {
          this.bestPracticesNotations = result.data;
          this.bestPracticesNotationsReduced = result.reduced;
        });
    },
    loadBestPractices(idCategorie) {
      if (`${idCategorie}`.includes(this.topBpKey)) {
        const splitted = idCategorie.split("-");
        return bestPracticesServiceApi
          .getBestPracticesTop(splitted.pop())
          .then((result) => {
            this.bestPracticesLoaded = result.data;
            this.bestPracticesBlockTitle = this.selectedNode
              ? this.selectedNode.labelForTitle
              : "";
            return Promise.resolve();
          })
          .catch((e) => {
            console.error(e);
            return Promise.reject();
          });
      } else {
        return bestPracticesServiceApi
          .getBestPractices(idCategorie)
          .then((result) => {
            this.bestPracticesLoaded = result.data;
            this.bestPracticesBlockTitle = this.selectedNode
              ? this.selectedNode.labelForTitle
              : "";
            return Promise.resolve();
          })
          .catch((e) => {
            console.error(e);
            return Promise.reject();
          });
      }
    },
    searchBestPractice() {
      if (this.bestPracticeSearchText.trim().length > 2) {
        this.isBusy = true;
        this.bestPracticesBlockTitle = this.$t("best_practices.search_result");
        return bestPracticesServiceApi
          .getBestPracticesByResearch(this.bestPracticeSearchText)
          .then((result) => {
            this.bestPracticesLoaded = result.data;
            this.isBusy = false;
            return Promise.resolve();
          })
          .catch(() => {
            this.bestPracticesLoaded = [];
            this.isBusy = false;
            return Promise.reject();
          });
      } else {
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_ERROR, {
            detail: {
              title: this.$t("best_practices.search_best_practice"),
              message: this.$t(
                "best_practices.search_minimum_characters_request"
              ),
            },
          })
        );
      }
    },
    async onLocaleChange() {
      this.expandedKeys = {};
      this.isBusy = true;
      await this.loadBestPractices(
        !this.selectedNode ||
          (this.selectedNode && this.selectedNode.type === "rubrique")
          ? undefined
          : this.selectedNode.id
      );
      this.loadCategories();
      this.isBusy = false;
      this.$emit("onLocaleChange");
    },
    saveBestPractices(el) {
      this.asyncSaveBestPractices(el, this);
    },
    async asyncSaveBestPractices(el, refThis) {
      if (el) {
        refThis.isBusy = true;
        bestPracticesServiceApi
          .saveBestPractices(el)
          .then(async (savedBp) => {
            this._uploadBestPracticeFiles(
              el,
              savedBp.id
            );
            await refThis.loadCategories();
            await refThis.loadBestPractices(
              !refThis.selectedNode ||
                (refThis.selectedNode &&
                  refThis.selectedNode.type === "rubrique")
                ? undefined
                : el.idCategorie
            );
            refThis.showAddModal = false;
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.API_SUCCESS, {
                detail: {
                  title: refThis.$t("popup_messages.success_title"),
                  message: refThis.$t("popup_messages.success_message"),
                },
              })
            );
          })
          .catch((e) => {
            console.error(e);
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.API_ERROR, {
                detail: {
                  title: refThis.$t("popup_messages.default_error_title"),
                  message: refThis.$t("popup_messages.default_error_message"),
                },
              })
            );
          })
          .finally(() => {
            refThis.isBusy = false;
          });
      }
    },
    updateBestPractice(el) {
      this.asyncUpdateBestPractice(el, this);
    },
    async asyncUpdateBestPractice(el, refThis) {
      if (el) {
        bestPracticesServiceApi
          .updateBestPractice(el)
          .then(async (savedBp) => {
            this._uploadBestPracticeFiles(
              el,
              savedBp.id,
            );
            await refThis.loadCategories();
            await refThis.loadBestPractices(
              !refThis.selectedNode ||
                (refThis.selectedNode &&
                  refThis.selectedNode.type === "rubrique")
                ? undefined
                : refThis.selectedNode.id
            );
            refThis.showUpdateModal = false;
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.API_SUCCESS, {
                detail: {
                  title: refThis.$t("popup_messages.success_title"),
                  message: refThis.$t("popup_messages.success_message"),
                },
              })
            );
          })
          .catch((e) => {
            console.error(e);
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.API_ERROR, {
                detail: {
                  title: refThis.$t("popup_messages.default_error_title"),
                  message: refThis.$t("popup_messages.default_error_message"),
                },
              })
            );
          })
          .finally(() => {
            refThis.isBusy = false;
          });
      }
    },
    closeBestPractice() {
      this.showUpdateModal = false;
      this.showAddModal = false;
      this.loadBestPractices(
        !this.selectedNode ||
          (this.selectedNode && this.selectedNode.type === "rubrique")
          ? undefined
          : this.selectedNode.id
      );
    },
    deleteBestPractice() {
      this.isBusy = true;
      bestPracticesServiceApi
        .deleteBestPractice(this.selectedBestPractice.id)
        .then(async () => {
          await this.loadCategories();
          await this.loadBestPractices(this.selectedBestPractice.idCategorie);
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_delete_title"),
                message: this.$t("popup_messages.success_delete_message"),
              },
            })
          );
          this.isBusy = false;
          this.onDelete = false;
        })
        .catch((e) => {
          console.error(e);
          this.isBusy = false;
          this.onDelete = false;
        });
    },
    async onSelectCategory(node) {
      this.selectedNode = node;
      switch (node.type) {
        case "rubrique":
          this.isBusy = true;
          await this.loadBestPractices();
          this.bestPracticesBlockTitle = this.selectedNode.labelForTitle;
          this.isBusy = false;
          break;
        case "category":
          this.isBusy = true;
          await this.loadBestPractices(node.id);
          this.bestPracticesBlockTitle = this.selectedNode.labelForTitle;
          this.isBusy = false;
          break;

        default:
          break;
      }
    },
    openBestPracticesDetails(node, photoBlob) {
      this.selectedBestPractice = node;
      this.selectedBestPracticeImage = photoBlob;
      this.showDetailModal = true;
    },
    closeBestPracticesDetails() {
      this.selectedBestPractice = undefined;
      this.selectedBestPracticeImage = undefined;
      this.showDetailModal = false;
    },

    getUserNotation(bestPractice) {
      const mappedBestPractice = this.bestPracticesNotations.find(
        (e) =>
          e.idBestPractice === bestPractice.id && e.idLdap === +this.user.idLdap
      );
      return mappedBestPractice ? mappedBestPractice.note : 0;
    },
    getNotationList(bestPractice) {
      const notationsList = this.bestPracticesNotations.filter(
        (e) => e.idBestPractice === bestPractice.id
      );
      return notationsList;
    },
    handleUpdateBestPracticeNotation(notation) {
      const newBestPracticesNotations = [...this.bestPracticesNotations];
      const indexOf = newBestPracticesNotations.findIndex(
        (e) => e.idBestPractice === notation.idBestPractice
      );
      indexOf >= 0
        ? newBestPracticesNotations.splice(indexOf, 0, notation)
        : newBestPracticesNotations.push(notation);
      this.bestPracticesNotations = newBestPracticesNotations;
      this.loadBestPracticesNotations();
    },
    getBestPracticesNotationsReduced(id) {
      return this.bestPracticesNotationsReduced[id]
        ? this.bestPracticesNotationsReduced[id]
        : "";
    },
    sortCategories(categories) {
      // TODO : Remplace par le champ "ordre" non administrable actuellement
      return [...categories].sort((a, b) => {
        const aSplitted = `${a.label}`.split(/ /)[0]?.split(".");
        const bSplitted = `${b.label}`.split(/ /)[0]?.split(".");
        const lastASplitted = [...aSplitted].pop();
        const lastBSplitted = [...bSplitted].pop();
        return +lastASplitted - +lastBSplitted;
      });
    },

    async _uploadBestPracticeFiles(el, bestPracticeId) {
      const files = this._getFilesToUpload(el)
      this.uploadFiles(files, "best-practrice", bestPracticeId, +this.user.idLdap, () => {
        const bestPracticeCategoriesIds = el.categories.map(e => e.id)
        if (bestPracticeCategoriesIds.includes(this.selectedNode.id)) {
          this.loadBestPractices(this.selectedNode.id);
        }
      })
    },

    _getFilesToUpload(el) {
      const newFiles = [...el.fichiers.filter((e) => e.file)].map((e) => {
        e["addtionnalData"] = { type: "bp-files" };
        return e;
      });
      const newPhotos = [...el.photos.filter((e) => e.file)].map((e) => {
        e["addtionnalData"] = { type: "bp-photo" };
        return e;
      });
      return [...newFiles, ...newPhotos];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/colors.scss";

.reader {
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 50vh;
  padding-left: 0 !important;

  &.reader-large {
    min-height: 68vh;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

#bp-list {
  padding-right: 0;
  border-right: solid 1px $footer_bg;

  .p-tag {
    background-color: $default_color;
    font-size: 0.7rem;
    font-weight: 400;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 15px;
    word-break: break-word;
  }

  .best-practice-file {
    flex: 1;
    margin: 2px;
  }
}

.p-tree .p-treenode-children {
  padding: 0 0 0 0.6rem;
}

.p-treenode-children {

  //padding: 0 !important;
  .p-treenode-leaf {
    display: flex;

    .p-tree-toggler {
      display: none;
    }

    .p-treenode-selectable {
      display: flex;
      flex: 1;

      &:hover {
        background-color: $light_blue !important;
      }
    }
  }
}

.p-treenode-content {
  &:focus {
    box-shadow: unset !important;
  }

  align-items: baseline;
}

.p-treenode-leaf {
  &:hover {
    background-color: $light_blue;
  }

  min-height: 50px;
  width: 100%;
}

.p-treenode-label {
  flex: 1;
  display: flex;
  justify-content: space-between;

  .tree-title {
    display: flex;
    flex-direction: column;
  }
}

.categorie {
  text-transform: none;
  font-weight: bold;
  color: $primary_color;
  font-size: 0.9rem;
}

.pi.pi-fw.pi-folder.categorie:before {
  font-weight: bold;
  color: $primary_color;
}

.sousCategorie {
  text-transform: none;
  font-weight: normal;
  font-size: 0.9rem;
}

.search {
  width: 27vw;
}

.search:enabled:hover {
  border-color: $primary_color;
}

.p-tree-container .p-button.p-button-icon-only {
  height: 1.6rem;
  padding: 0.7rem;
  position: absolute;
  right: 20px;
}

:deep(.p-menu-overlay) {
  min-width: 230px;
}

:deep(.p-tree-container) {
  overflow-x: hidden;
}

:deep(.p-scrollpanel) {
  &.custombar {
    .p-scrollpanel-wrapper {
      border-top: 0;
      border-right: 9px none var(--surface-b);
    }

    .p-scrollpanel-bar {
      background-color: $primary_color;
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: $primary_color;
      }
    }
  }
}

@media (max-width: 425px) {
  .grid {
    flex-wrap: wrap;
  }

  #bp-list {
    border-bottom: solid 2px #189eab;
  }
}

.shared-bestpractice-spinner.p-progress-spinner {
  height: 100% !important;
  margin: auto;
}
</style>
