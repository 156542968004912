export const LESS_1_YEAR = "less 1 year";
export const LESS_2_YEAR = "less 2 years";
export const LESS_3_YEAR = "less 3 years";
export const THREE_YEARS_MORE = "3 years and more";
export const NO_EVALUATION = "no evalutation";

export default {
  LESS_1_YEAR,
  LESS_2_YEAR,
  LESS_3_YEAR,
  THREE_YEARS_MORE,
  NO_EVALUATION,
};
