<template>
  <div class="event-block">
    <div class="grid">
      <div class="md:col-4 col-12 flex p-jc-stretch align-items-center">
        <img v-if="image" :src="image" alt="event-img" />
        <ProgressSpinner v-if="isBusy" />
      </div>
      <div class="md:col-8 col-12">
        <div class="flex justify-content-between align-items-end">
          <span class="date">
            {{ formatDate(event.dateDebut) }}
            <span v-if="event.dateFin">{{
              " - " + formatDate(event.dateFin)
            }}</span>
          </span>

          <div>
            <Button v-show="editMode" @click="onEdit" icon="pi pi-pencil"
              class="p-button-sm p-button-rounded button-default mr-1">
            </Button>
            <Button v-show="editMode" @click="onDelete" icon="pi pi-trash"
              class="p-button-sm p-button-rounded p-button-danger">
            </Button>
          </div>
        </div>
        <as-title :title="event.details.titre" :size="5" />
        <p class="description">{{ event.details.description }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import AsTitle from "../Elements/AsTitle.vue";
import Button from "primevue/button";
import fichiersServiceApi from "../../services/fichiersServiceApi";
import { bufferToBlobUrl } from "../../util";
import ProgressSpinner from "primevue/progressspinner";
import moment from "moment";

export default {
  name: "EventBlock",
  components: {
    AsTitle,
    Button,
    ProgressSpinner,
  },
  props: {
    event: Object,
    editMode: Boolean,
  },
  data() {
    return {
      image: undefined,
      isBusy: false,
    };
  },
  mixins: [fichiersServiceApi],
  watch: {
    event() {
      this.onLoadImage(this.event);
    },
  },
  emits: ["onEdit", "onDelete"],
  beforeMount() {
    this.onLoadImage(this.event);
  },
  methods: {
    onEdit() {
      this.$emit("onEdit");
    },
    onDelete() {
      this.$emit("onDelete");
    },
    async onLoadImage(value) {
      if (value.image && value.image.externalId != null) {
        this.isBusy = true;
        try {
          const fichier = await this.downloadFile(value.image.externalId);
          this.image = bufferToBlobUrl(fichier);
        } catch (error) {
          console.log(error)
        }
      }
      this.isBusy = false;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";

.event-block {
  padding-bottom: 20px;

  &:not(:last-child) {
    border-bottom: solid 1px $default_color;
  }

  &:not(:first-child) {
    padding-top: 20px;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .date {
    font-weight: 400;
    background-color: $primary_color;
    color: $light_font;
    margin: 30px 10px 10px 0;
    padding: 3px;
    border-radius: 5px;
  }

  .description {
    color: $default_color;
    white-space: pre-line;
  }
}
</style>
