import moment from "moment";
import i18n from "./index";
import config from "../config";
// import { defineComponent, onMounted } from "vue";
// import { usePrimeVue } from "primevue/config";
// import * as fr from "../locales/primevue/fr.json";
// import * as en from "../locales/primevue/en.json";

export const getNavigatorLanguage = () => {
  let result = navigator.language || navigator.userLanguage;
  try {
    result = result.substr(0, 2);
    if (config.supportedLangues.includes(result)) {
      return result;
    }
  } catch (e) {
    console.error("Error in language dermination", e);
  }
  return config.defaultLocale;
};

export const changeLocale = (language) => {
  i18n.global.locale = language;
  moment.locale(language);
};

export default {
  getNavigatorLanguage,
  changeLocale,
};
