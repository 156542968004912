<template>
  <div id="user-info">
    <div id="profile-img">
      <img :src="userPhoto" />
    </div>
    <span>
      <b>{{ userInfo.nom ? userInfo.nom : $t("unknown") }}</b></span
    >
  </div>
</template>

<script>
import changeLocalesMixins from "../../mixins/changeLocalesMixins";
import { getNavigatorLanguage } from "../../locales/utils";
export default {
  name: "UserInfoBlock",
  props: {
    noUserImg: {
      type: String,
      default: () => {
        return require("../../assets/images/no-user-image.jpg");
      },
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInformations;
    },
    userPhoto() {
      if (this.userInfo && this.userInfo.picture) {
        return `data:image/jpeg;base64,${this.userInfo.picture}`;
      } else {
        return this.noUserImg;
      }
    },
  },
  mixins: [changeLocalesMixins],
  beforeMount() {
    if (this.userInfo.preferredlanguage) {
      this.changeLocale(this.userInfo.preferredlanguage);
    } else {
      this.changeLocale(getNavigatorLanguage());
    }
  },
  methods: {
    changeLocale(locale) {
      this.changeCurrentLocale(locale);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
#user-info {
  display: flex;
  align-items: center;
  color: $light_font;
  #profile-img {
    height: 60px;
    width: 60px;
    border: solid 2px $light_blue;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    img {
      width: inherit;
      height: inherit;
      border-radius: inherit;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>
