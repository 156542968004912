import referentialsServiceApi from "../../services/referentialsServiceApi";

export const config = {
  state: {
    config: {},
  },
  getters: {
    getConfig(state) {
      return state.config;
    },
  },
  mutations: {
    setConfig: (state, value) => {
      state.config = value;
    },
  },
  actions: {
    getConfigurationFromApi(store) {
      referentialsServiceApi.getConfig().then((result) => {
        store.commit("setConfig", result);
      });
    },
  },
};
