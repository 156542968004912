import configuration from "../config";
import axios from "./axiosConfiguration";

const endpointUsers = `${configuration.api_url}utilisateurs`;

export const getUsers = (idEntreprise) => {
  const options = {
    params: {
      idEntreprise: idEntreprise ? idEntreprise : undefined,
    },
  };
  return axios
    .get(endpointUsers, options)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteUser = (idLdap) => {
  return axios
    .delete(`${endpointUsers}/${idLdap}`)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateUser = (user) => {
  return axios
    .put(`${endpointUsers}/${user.idLdap}`, user)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveNewUser = (user) => {
  return axios
    .post(`${endpointUsers}`, user)
    .then(() => {
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  getUsers,
  deleteUser,
  updateUser,
  saveNewUser,
};
