<template>
  <div>
    <as-confirm-modal
      :isCancelable="false"
      :isVisible="showLoginModal"
      :modalMessage="$t('confirm_modal.not_logged_message')"
      :yesButtonMessage="$t('confirm_modal.confirm_login_redirect_button')"
      @onConfirm="goToLoginPage"
    />
  </div>
</template>

<script>
import AsConfirmModal from "@/components/Modals/AsConfirmModal.vue";
import statusCode from "../../constantes/statusCodes";
import apiEventTypes from "../../constantes/apiEventTypes";

export default {
  name: "LoginModal",
  components: {
    AsConfirmModal,
  },
  data() {
    return {
      showLoginModal: false,
    };
  },
  mounted() {
    document.addEventListener(apiEventTypes.API_ERROR, (event) => {
      if (
        event.detail.statusCode === statusCode.UNAUTHORIZED &&
        !sessionStorage.getItem("starting")
      ) {
        //this.showLoginModal = true;
      }
    });
  },
  beforeUnmount() {
    // document.removeEventListener(apiEventTypes.API_ERROR, (event) => {
    //   if (event.detail.statusCode === statusCode.UNAUTHORIZED) {
    //     this.showLoginModal = true;
    //   }
    // });
  },
  methods: {
    goToLoginPage() {
      // localStorage.setItem("redirect", this.$route.path);
      // window.location.replace("/auth/login");
    },
  },
};
</script>
