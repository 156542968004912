<template>
  <div id="pdfvuer">    
    <VuePdfEmbed annotation-layer text-layer :source="pdfdata"
    style="margin: 20px auto"/>
  </div>
</template>

<script>

import VuePdfEmbed from 'vue-pdf-embed'
export default {
  name: "PdfViewer",
  components: {
    VuePdfEmbed
  },
  props: {
    pdfUrl: String,
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
    };
  },
  mounted() {
    this.getPdf();
  },
  methods: {
    getPdf() {
      this.pdfdata = this.pdfUrl;
    },
  },
};
</script>
<style lang="css" scoped>
#pdfvuer {
  width: 100%;
  height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Page content */
.content {
  padding: 16px;
}
</style>