<template>
  <div class="as-overlay" :class="[{ closeable: isCloseable }]"></div>
</template>

<script>
export default {
  name: "AsOverlay",
  props: {
    isCloseable: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/zindex.scss";
.as-overlay {
  position: fixed;
  z-index: $overlay_zindex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $default_color;
  opacity: 0.6;
  &.closeable {
    cursor: pointer;
  }
}
</style>
