export const CONFIRM_DELETE = 1;
export const CONFIRM_QUIT = 2;
export const CONFIRM_CHANGELOCALE = 3;
export const CONFIRM_SAVE = 4;

export default {
  CONFIRM_DELETE,
  CONFIRM_QUIT,
  CONFIRM_CHANGELOCALE,
  CONFIRM_SAVE,
};
