<template>
  <div class="fichiers">
    <Tree :value="categories" :filter="true" filterMode="lenient" :expandedKeys="expandedKeys" selectionMode="single"
      @nodeSelect="onNodeSelect">
      <template #rubrique="slotProps">
        <div class="flex align-items">
          <span>{{ slotProps.node.label }}</span>
          <Button v-show="editMode" icon="pi pi-plus" class="p-button-sm button-default ml-auto"
            v-tooltip.top="$t('cms_page.add_category')" @click="addCategory(slotProps.node)" />
        </div>
      </template>
      <template #category="slotProps">
        <div class="flex align-items">
          <span :class="slotProps.node.class">{{ slotProps.node.label }}</span>
          <div class="flex align-items ml-auto">
            <Button v-show="editMode" type="button" icon="pi pi-cog" class="p-button p-button-sm button-default"
              @click="toggleMenu($event, slotProps.node)" />
            <Menu ref="menu" :model="items" :popup="true" />
            <Fileupload :ref="'fileupload-' + slotProps.node.id" v-show="editMode" mode="basic" :multiple="false"
              :customUpload="true" :auto="true" :accept="fileFilters" icon="pi pi-folder"
              @uploader="upload($event, slotProps.node)" :chooseLabel="$t('cms_page.add_document_label')"
              v-tooltip.top="$t('cms_page.add_document')" class="p-button-sm button-default ml-2" />
          </div>
        </div>
      </template>
      <template #file="slotProps">
        <div class="fichier" @click="
          downloadFichier(slotProps.node.externalId, slotProps.node.label)
          ">
          <div>
            {{ slotProps.node.label }}
          </div>
          <div>
            <Button v-if="isDownloadable" v-show="!editMode" icon="pi pi-download"
              class="p-button-sm p-button-rounded button-primary" v-tooltip.top="$t('cms_page.download_document') + ': ' + slotProps.node.label
                " />
            <Button v-show="editMode" icon="pi pi-times" class="p-button-sm p-button-rounded p-button-danger"
              @click.stop.prevent="
                handleDeleteFichier(
                  slotProps.node.id,
                  slotProps.node.categoryId
                )
                " />
          </div>
        </div>
      </template>
    </Tree>
  </div>
  <as-confirm-modal :isVisible="showConfirmModal" :modalMessage="modalMessage"
    @onConfirm="confirmDeleteFichierFromCategory" @onCancel="cancelDeleteFichierFromCategory" />
  <as-confirm-modal :isVisible="showConfirmModalDeleteCategory" :modalMessage="modalMessage"
    @onConfirm="confirmDeleteCategory" @onCancel="cancelDeleteCategory" />
  <as-modal v-if="isAdmin" :isVisible="showAddCategoryModal" class="reduced-form-modal">
    <category-form v-if="showAddCategoryModal" :isBusy="isBusy" :node="node" @onClose="showAddCategoryModal = false"
      @onSubmit="saveCategory" />
  </as-modal>
  <as-modal v-if="isAdmin" :isVisible="showEditCategoryModal" class="reduced-form-modal">
    <category-form v-if="showEditCategoryModal" :categorie="categoryToEdit" :isBusy="isBusy" :node="node"
      @onClose="showEditCategoryModal = false" @onSubmit="updateCategory" />
  </as-modal>
</template>

<script>
import Button from "primevue/button";
import Menu from "primevue/menu";
import AsConfirmModal from "@/components/Modals/AsConfirmModal.vue";
import Fileupload from "primevue/fileupload";
import Tree from "primevue/tree";
import config from "../../config";
import fichiersServiceApi from "../../services/fichiersServiceApi";
import rubriquesServiceApi from "../../services/rubriquesServiceApi";
import apiEventTypes from "../../constantes/apiEventTypes";
import CategoryForm from "@/components/Forms/CategoryForm.vue";
import categoryServiceApi from "@/services/categoryServiceApi";
import AsModal from "@/components/Modals/AsModal.vue";
import roleMixins from "@/mixins/roleMixins";
import { mapGetters } from "vuex";

export default {
  name: "AsFichierList",
  components: {
    Button,
    Menu,
    Fileupload,
    Tree,
    AsConfirmModal,
    CategoryForm,
    AsModal,
  },
  props: {
    rubriques: {
      type: Array,
    },
    rubrique: {
      type: Object,
    },
    editMode: Boolean,
    isDownloadable: {
      type: Boolean,
      default: true,
    },
    fileFilters: {
      type: String,
      default: "",
    },
    maxFileSize: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fichierToDelete: NaN,
      categoryFichierToDelete: NaN,
      rubriqueFromCategoryToDelete: NaN,
      categoryToDelete: NaN,
      showConfirmModal: false,
      showConfirmModalDeleteCategory: false,
      modalMessage: "",
      expandedKeys: {},
      selectedFileExternalId: null,
      showAddCategoryModal: false,
      categoryToEdit: NaN,
      showEditCategoryModal: false,
      isBusy: false,
      node: undefined,
      maxFileUpload: this.maxFileSize ? this.maxFileSize : config.max_file_upload,
      items: [
        {
          label: this.$t("cms_page.add_category"),
          icon: "pi pi-plus",
          command: () => {
            this.addCategory(this.$refs.menu.currentNode);
          },
        },
        {
          label: this.$t("cms_page.edit_category"),
          icon: "pi pi-pencil",
          command: () => {
            this.handleEditCategory(this.$refs.menu.currentNode.id);
          },
        },
        {
          visible: () => {
            return (
              this.$refs.menu &&
              this.$refs.menu.currentNode &&
              this.$refs.menu.currentNode.children &&
              this.$refs.menu.currentNode.children.length === 0 &&
              this.$refs.menu.currentNode.fichiers &&
              this.$refs.menu.currentNode.fichiers.length === 0
            );
          },
          label: this.$t("cms_page.delete_category"),
          icon: "pi pi-trash",
          command: () => {
            this.handleDeleteCategory(this.$refs.menu.currentNode.id);
          },
        },
      ],
    };
  },
  computed: {
    categories() {
      let result = [];
      if (this.rubrique && this.rubrique.id) {
        result = [
          {
            id: this.rubrique.id,
            codeRubrique: this.rubrique.codeRubrique,
            externalId: this.rubrique.externalId,
            label: this.$t(`menu.${this.rubrique.codeRubrique}`),
            type: "rubrique",
            key: "r-" + this.rubrique.id,
            leaf: false,
            icon: "pi pi-fw pi-folder",
            level: 0,
            children: this.rubrique.categories
              ? this.rubrique.categories
                .map((cat) => {
                  return this.mapCategory(cat, 1, this.rubrique.id);
                })
                .sort((a, b) => (a.label < b.label ? -1 : 1))
              : [],
          },
        ];
      }
      if (this.rubriques) {
        this.rubriques.forEach((rub) => {
          result.push({
            id: rub.id,
            codeRubrique: rub.codeRubrique,
            externalId: rub.externalId,
            label: this.$t(`menu.${rub.codeRubrique}`),
            type: "rubrique",
            key: "r-" + rub.id,
            leaf: false,
            icon: "pi pi-fw pi-folder",
            level: 0,
            children: rub.categories
              ? rub.categories
                .map((cat) => {
                  return this.mapCategory(cat, 0, rub.id);
                })
                .concat(
                  rub.fichier
                    ? rub.fichiers.map((fichier) => {
                      return this.mapFichier(undefined, fichier, rub.id);
                    })
                    : []
                )
                .sort((a, b) => (a.label < b.label ? -1 : 1))
              : [],
          });
        });
      }
      return result.sort((a, b) => (a.label < b.label ? -1 : 1));
    },
    ...mapGetters({
      user: "getUser",
    }),
  },
  watch: {
    categories() {
      this.$nextTick(() => {
        this.expandAll();
      });
    },
  },
  emits: [
    "onDelete",
    "onUpload",
    "onDownload",
    "onSelect",
    "onCategoryCreated",
    "onCategoryToReload",
  ],
  mixins: [roleMixins, fichiersServiceApi],
  methods: {
    formatSize(bytes) {
      if (bytes === 0) {
        return "0 B";
      }
      let k = 1000,
        dm = 3,
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    toggleMenu(event, currentNode) {
      this.$refs.menu.currentNode = currentNode;
      this.$refs.menu.toggle(event);
    },
    handleDeleteFichier(fichierId, categoryId) {
      this.fichierToDelete = fichierId;
      this.categoryFichierToDelete = categoryId;
      this.modalMessage = this.$t("confirm_modal.delete_message");
      this.showConfirmModal = true;
    },
    handleDeleteCategory(categoryId) {
      this.rubriqueFromCategoryToDelete = this.rubrique.id;
      this.categoryToDelete = categoryId;
      this.modalMessage = this.$t("confirm_modal.delete_message");
      this.showConfirmModalDeleteCategory = true;
    },
    handleEditCategory(categoryId) {
      rubriquesServiceApi
        .getCategory(this.rubrique.id, categoryId)
        .then((result) => {
          this.categoryToEdit = result;
          this.showEditCategoryModal = true;
        });
    },
    upload(filesContent, categorie) {
      if (
        this.maxFileUpload &&
        filesContent.files[0].size > this.maxFileUpload
      ) {
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.ERROR, {
            detail: {
              title: this.$t("file.invalidFileSizeMessageTitle"),
              message: this.$t("file.invalidFileSizeMessage", {
                filename: filesContent.files[0].name,
                maxFileSize: this.formatSize(this.maxFileUpload),
              }),
            },
          })
        );
        this.$refs["fileupload-" + categorie.id].clear();
        return false;
      }

      this._uploadFiles(filesContent.files.map(e => { return { file: e } }), categorie.id)
    },
    downloadFichier(fichierId, fichierTitre) {
      if (this.isDownloadable) {
        this.downloadFile(fichierId, fichierTitre);
        this.$emit("onDownload", fichierId);
      }
    },
    confirmDeleteFichierFromCategory() {
      rubriquesServiceApi
        .deleteFichierFromCategory(
          this.fichierToDelete
        )
        .then(() => {
          this.fichierToDelete = NaN;
          this.categoryFichierToDelete = NaN;
          this.showConfirmModal = false;
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_delete_title"),
                message: this.$t("popup_messages.success_delete_message"),
              },
            })
          );
          this.$emit("onDelete");
        })
        .catch(() => {
          this.fichierToDelete = NaN;
          this.categoryFichierToDelete = NaN;
          this.showConfirmModal = false;
        });
    },
    cancelDeleteFichierFromCategory() {
      this.showConfirmModal = false;
    },
    confirmDeleteCategory() {
      rubriquesServiceApi
        .deleteCategory(
          this.rubriqueFromCategoryToDelete,
          this.categoryToDelete
        )
        .then(() => {
          this.rubriqueFromCategoryToDelete = NaN;
          this.categoryToDelete = NaN;
          this.showConfirmModalDeleteCategory = false;
          document.dispatchEvent(
            new CustomEvent(apiEventTypes.API_SUCCESS, {
              detail: {
                title: this.$t("popup_messages.success_delete_title"),
                message: this.$t("popup_messages.success_delete_message"),
              },
            })
          );
          this.$emit("onDelete");
        })
        .catch(() => {
          this.rubriqueFromCategoryToDelete = NaN;
          this.categoryToDelete = NaN;
          this.showConfirmModalDeleteCategory = false;
        });
    },
    cancelDeleteCategory() {
      this.showConfirmModalDeleteCategory = false;
    },
    expandAll() {
      for (let node of this.categories) {
        this.expandNode(node);
      }

      this.expandedKeys = { ...this.expandedKeys };
    },
    expandNode(node) {
      this.expandedKeys[node.key] = true;
      if (node && node.children && node.children.length) {
        for (let child of node.children) {
          if (child.type == "fichier" || this.editMode) {
            this.expandNode(child);
          }
        }
      }
    },
    onNodeSelect(el) {
      this.$nextTick(() => {
        if (
          el.type === "file" &&
          el.externalId !== this.selectedExternalId &&
          isNaN(this.fichierToDelete) //don't consider select the file if delete button was clicked
        ) {
          this.selectedExternalId = el.externalId;
          this.$emit("onSelect", el.externalId);
        }
      });
    },
    mapCategory(category, level, rubriqueId) {
      return {
        key: `c-${category.id}-${rubriqueId}`,
        label: category.detail[0].libelle,
        data: category.detail[0].libelle,
        ...category,
        type: "category",
        level: level,
        icon:
          level > 1
            ? "pi pi-fw pi-folder sousCategorie"
            : "pi pi-fw pi-folder categorie",
        class: level > 1 ? "sousCategorie" : "categorie",
        children: category.children
          ? category.children
            .map((cat) => {
              return this.mapCategory(cat, level + 1, rubriqueId);
            })
            .concat(
              category.fichiers
                ? category.fichiers.map((fichier) => {
                  return this.mapFichier(category.id, fichier);
                })
                : []
            )
            .sort((a, b) => (a.label < b.label ? -1 : 1))
          : category.fichiers
            ? category.fichiers
              .map((fichier) => {
                return this.mapFichier(category.id, fichier);
              })
              .sort((a, b) => (a.label < b.label ? -1 : 1))
            : [],
        leaf: true,
      };
    },
    mapFichier(categorieId, fichier, rubriqueId) {
      return {
        key: `f-${rubriqueId}-${categorieId}-${fichier.id}`,
        id: fichier.id,
        categoryId: categorieId,
        externalId: fichier.externalId,
        date: fichier.date,
        label: fichier.titre,
        icon: "pi pi-fw pi-file",
        type: "file",
      };
    },
    addCategory(node) {
      this.node = node;
      this.showAddCategoryModal = true;
    },
    async saveCategory(object) {
      this.isBusy = true;
      try {
        switch (object.node.type) {
          case "rubrique": {
            if (object.node.externalId === undefined) {
              document.dispatchEvent(
                new CustomEvent(apiEventTypes.API_ERROR, {
                  detail: {
                    title: this.$t("popup_messages.default_error_title"),
                    message: this.$t(
                      "popup_messages.no_external_id_rubrique_defined"
                    ),
                  },
                })
              );
            }
            await categoryServiceApi.createCategory(
              {
                idRubrique: object.node.id,
                detail: object.detail,
              }
            );
            break;
          }
          case "category": {
            await categoryServiceApi.createCategory(
              {
                idRubrique: object.node.idRubrique,
                idCategorieParent: object.node.id,
                detail: object.detail,
              }
            );
            break;
          }
        }
        this.isBusy = false;
        this.showAddCategoryModal = false;
        this.$emit("onCategoryToReload");
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_SUCCESS, {
            detail: {
              title: this.$t("popup_messages.success_title"),
              message: this.$t("popup_messages.success_message"),
            },
          })
        );
      } catch {
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_ERROR, {
            detail: {
              title: this.$t("popup_messages.default_error_title"),
              message: this.$t("popup_messages.default_error_message"),
            },
          })
        );
        this.isBusy = false;
      }
    },
    async updateCategory(object) {
      this.isBusy = true;
      try {
        this.categoryToEdit.detail = object.detail;
        await categoryServiceApi.updateCategory(
          this.categoryToEdit
        );
        this.isBusy = false;
        this.showEditCategoryModal = false;
        this.$emit("onCategoryToReload");
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_SUCCESS, {
            detail: {
              title: this.$t("popup_messages.success_title"),
              message: this.$t("popup_messages.success_message"),
            },
          })
        );
      } catch {
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_ERROR, {
            detail: {
              title: this.$t("popup_messages.default_error_title"),
              message: this.$t("popup_messages.default_error_message"),
            },
          })
        );
        this.isBusy = false;
      }
    },

    async _uploadFiles(files, entityId) {
      this.uploadFiles(files, "categorie", entityId, +this.user.idLdap, () => {
        this.$emit("onUpload");
      })
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/sizing.scss";

.fichiers {
  max-height: $fichier_block_height;
  overflow: auto;

  .p-fileupload {
    .p-button {
      font-size: 0.875rem !important;
    }
  }

  .p-treenode {
    font-size: $tree-font-size;
  }

  .grid {
    margin: 0 !important;
  }

  h5 {
    padding: 0;
  }

  .p-treenode-children {
    .p-treenode-leaf {
      display: flex;
      margin-left: 2rem;

      .p-tree-toggler {
        display: none;
      }

      .p-treenode-selectable {
        display: flex;
        flex: 1;

        &:hover {
          background-color: $light_blue !important;
        }
      }
    }
  }

  .p-treenode-content {
    &:focus {
      box-shadow: unset !important;
    }

    display: flex;
    align-items: center;
    padding: 5px !important;
  }

  .p-treenode-leaf {
    &:hover {
      background-color: $light_blue;
    }
  }

  .p-treenode-label {
    width: 100%;

    h5 {
      flex: 1;
    }

    .tree-title {
      display: flex;
      flex-direction: column;
    }
  }

  .p-button-danger {
    width: 25px !important;
    height: 25px !important;
  }

  .categorie {
    text-transform: none;
    font-weight: bold;
    color: $primary_color;
  }

  .pi.pi-fw.pi-folder.categorie:before {
    font-weight: bold;
    color: $primary_color;
  }

  .sousCategorie {
    text-transform: none;
    font-weight: normal;
  }

  .pi.pi-fw.pi-folder.sousCategorie {
    margin: 0;
  }

  .fichier {
    flex: 1;
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;

    div {
      align-self: center;
      word-break: break-all;

      &:first-child {
        padding-right: 10px;
      }
    }
  }
}

@media (max-width: 425px) {
  .fichiers {
    height: auto !important;
  }
}
</style>
