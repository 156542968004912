<template>
  <div>
    <div id="header">
      <div id="left-menu">
        <img id="safety" :src="safety" />
        <div id="logo">
          <a href="https://www.adeo.com/"><img :src="logo" /></a>
        </div>
      </div>
      <div id="right-menu-mobile">
        <as-overlay
          @click="closeMobileMenu"
          v-show="showMobileMenu"
        ></as-overlay>
        <div id="right-menu-mobile-button" @click="openMobileMenu">
          <as-icon :source="burger" :size="20" />
        </div>
        <div id="right-menu-mobile-content" v-show="showMobileMenu">
          <div class="menu-mobile-content-container">
            <as-language-selector />
            <user-info-block />
          </div>
          <as-menu displayOnColumn />
        </div>
      </div>
      <div id="right-menu">
        <as-language-selector />
        <user-info-block />
      </div>
    </div>
  </div>
</template>

<script>
import AsLanguageSelector from "../Elements/AsLanguageSelector";
import AsIcon from "../Elements/AsIcon";
import AsMenu from "../Elements/AsMenu";
import AsOverlay from "../Modals/AsOverlay";
import UserInfoBlock from "./UserInfoBlock";

export default {
  name: "Header",
  components: {
    AsLanguageSelector,
    AsIcon,
    UserInfoBlock,
    AsMenu,
    AsOverlay,
  },
  data() {
    return {
      showMobileMenu: false,
      logo: require("../../assets/images/logo.svg"),
      safety: require("../../assets/images/LOGO-HUMANFIRST-SAFETYFIRST.svg"),
      burger: require("../../assets/images/icons/burger.svg"),
    };
  },
  watch: {
    $route() {
      this.closeMobileMenu();
    },
  },
  methods: {
    closeMobileMenu() {
      this.showMobileMenu = false;
    },
    openMobileMenu() {
      this.showMobileMenu = true;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/sizing.scss";
@import "../../assets/styles/zindex.scss";

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header_height;
  background-color: $header_bg;
  background-image: url("../../assets/images/header.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-left: $header_padding;
  padding-right: $header_padding;
  box-sizing: border-box;
  #logo {
    height: auto;
    width: 120px;
  }
  #left-menu {
    display: flex;
    align-items: center;
    #safety {
      height: 100%;
      width: 80px;
      padding: 5px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 20px;
    }
  }
  #right-menu {
    align-items: center;
    width: auto;

    > div:not(:first-child) {
      margin-left: 30px;
    }
  }
}

#right-menu-mobile {
  width: 60px;
  height: 60px;

  #right-menu-mobile-button {
    background-color: $menu_hamburger;
    display: flex;
    align-items: center;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #right-menu-mobile-content {
    .menu-mobile-content-container {
      background: $menu_hamburger;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: $overlay_content_zindex;
    top: 0;
    right: 0;
    max-height: 100vh;
    height: 100vh;
    box-sizing: border-box;
    background: $menu_bg;
    width: 80vw;
    min-width: 250px;
    overflow: auto;
    & > div {
      margin-top: 10px;
    }
    a.router-link-exact-active {
      border-bottom: none !important;
    }
    .menu {
      box-sizing: border-box;
      height: auto;
      width: 100%;
      ul {
        overflow: hidden;
      }
    }
  }
}

@media screen and (max-width: $media_small) {
  #right-menu-mobile {
    display: flex;
  }
  #left-menu,
  #right-menu {
    display: none;
  }
}
@media screen and (min-width: $media_small - 1px) {
  #right-menu-mobile {
    display: none;
  }
  #left-menu,
  #right-menu {
    display: flex;
  }
}
</style>
