<template>
  <div id="footer">
    <div class="flex align-items w-100">
      <div id="footer-logo">
        <a href="https://www.adeo.com/"><img :src="logo" /></a>
      </div>
      <div id="footer-workplace" class="ml-4 mr-4">
        <a
          href="https://adeo.workplace.com/groups/579248309513400/"
          target="_blank"
          ><img :src="workplace"
        /></a>
      </div>
    </div>
    <div id="footer-partenaires">
      <img :src="partenaires" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      logo: require("../../assets/images/logo.svg"),
      workplace: require("../../assets/images/workplace-footer.png"),
      partenaires: require("../../assets/images/partenaires-footer.png"),
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/sizing.scss";

#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../../assets/images/footer.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-left: $footer_padding;
  padding-right: $footer_padding;
  box-sizing: border-box;
  img {
    max-width: 100%;
    max-height: 85px;
  }
  #footer-logo {
    height: auto;
    width: 160px;
    flex: 1;
  }
  #footer-workplace {
    height: auto;
    width: 250px;
    flex: 1;
    img {
      max-height: 56px;
      background: white;
      padding: 10px 20px;
      border-radius: 30px;
    }
  }
  #footer-partenaires {
    height: auto;
    width: auto;
    background-color: $menu_bg;
    border-radius: 100px;
    img {
      width: 100%;
    }
  }
}

@media screen and (min-width: 831px) {
  #footer {
    flex-direction: row;
    height: $footer_height;
  }
}
@media screen and (max-width: 830px) {
  #footer {
    flex-direction: column;
    height: auto;
    #footer-partenaires {
      width: 90vw;
      min-width: 250px;
      margin-bottom: 20px;
    }
  }
}
</style>
