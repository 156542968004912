<template>
  <div id="best-practices-details-form" class="bp-detail grid-nogutter">
    <div class="col-12 flex grid-nogutter mb-2">
      <div class="field mr-2 mb-0">
        <div class="p-float-label">
          <InputText
            type="text"
            v-model="v$.titre.$model"
            :class="{ 'p-invalid': v$.titre.$invalid && submitted }"
            @change="onChange"
          />
          <label>{{ $t("best_practices_form.title_field") }}</label>
        </div>
        <as-input-error :errors="v$.titre.$silentErrors" v-show="submitted" />
      </div>
      <div class="flex align-items">
        <img class="flag" :src="getFlag" />
        <ProgressSpinner
          strokeWidth="5"
          v-if="beingTranslated && currentIsoCodeLocale !== selectedLocale"
        />
        <Button
          v-if="!beingTranslated && currentIsoCodeLocale !== selectedLocale"
          :label="$t('form.translate')"
          class="button-primary ml-2"
          @click="translateTo"
        />
      </div>
    </div>

    <div class="col-12 grid-nogutter">
      <div class="field">
        <label>{{ $t("best_practices_form.description_field") }}</label>
        <div class="p-float-label">
          <Editor
            v-model="v$.description.$model"
            editorStyle="height: 320px; width:100%"
            api-key="xd809ttvkuqrskp59c9i51jewqsfhjfulzkxm49rerbfbgio"
            :init="configTinyMce"
            :class="{ 'p-invalid': v$.description.$invalid && submitted }"
            @change="onChange"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
              </span>
              <span class="ql-formats">
                <select class="ql-header">
                  <option selected></option>
                  <option value="1"></option>
                  <option value="2"></option>
                  <option value="3"></option>
                  <option value="4"></option>
                  <option value="5"></option>
                  <option value="6"></option>
                </select>
                <button class="ql-list" value="ordered" />
                <button class="ql-list" value="bullet" />
                <!-- Note a missing, thus falsy value, is used to reset to default -->
              </span>
              <span class="ql-formats">
                <select class="ql-align">
                  <option selected></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-blockquote"></button>
                <button class="ql-link"></button>
                <button class="ql-image"></button>
                <button class="ql-video"></button>
              </span>
              <span class="ql-formats">
                <button class="ql-clean"></button>
              </span>
            </template>
          </Editor>
        </div>
        <as-input-error
          :errors="v$.description.$silentErrors"
          v-show="submitted"
        />
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Editor from "@tinymce/tinymce-vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import AsInputError from "./AsInputError.vue";
import { mapGetters } from "vuex";
import { getTinyMceConfig } from "../../config/tinymce.config";

export default {
  components: {
    InputText,
    Editor,
    AsInputError,
    Button,
    ProgressSpinner,
  },
  props: {
    detail: {
      type: Object,
      default: () => {
        return { titre: "", description: "", idLangue: 1 };
      },
    },
    submitted: Boolean,
    beingTranslated: Boolean,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      titre: this.detail.titre,
      description: this.detail.description,
      configTinyMce : getTinyMceConfig(
        this.translateToEditor
      ),
      currentIsoCodeLocale: "",
      selectedLocale: this.$i18n.locale,
    };
  },
  mounted() {
    this.currentIsoCodeLocale = this.getCurrentIsoCode();
  },
  validations() {
    return {
      titre: { required },
      description: { required },
    };
  },
  computed: {
    ...mapGetters({
      languages: "getLanguages",
    }),
    getFlag() {
      return require(`../../assets/images/flags/${this.getCurrentIsoCode()}.svg`);
    },
  },
  emits: ["onChange", "onTranslate", "onTranslateToEditor"],
  methods: {
    onChange() {
      this.$emit("onChange", {
        ...this.detail,
        titre: this.titre,
        description: this.description,
        idLangue: this.detail.idLangue,
      });
    },
    getCurrentIsoCode() {
      return this.languages.find((el) => el.id === this.detail.idLangue)
        .codeIso;
    },
    getLangue() {
        return this.languages.find(
            (el) => el.id === this.detail.idLangue
        );
    },

    translateTo() {
      this.$emit("onTranslate", this.getLangue());
    },

    translateToEditor() {
      this.$emit("onTranslateToEditor", this.getLangue());
    },
  },
};
</script>

<style lang="scss" scoped>
.flag {
  height: 40px;
}
.bp-detail .field {
  flex: 1;
}
#best-practices-details-form {
  .p-progress-spinner {
    height: 30px !important;
  }
}
</style>
