export const SUPERFIACIAL_TRAUMATIC_INJURY =
  "Superficial traumatic injury and open wound";
export const OPEN_WOUND = "Open wound";
export const CLOSED_FRACTURE = "Closed fracture";
export const OPEN_FRACTURE = "Open fracture";
export const OTHER_FRACTURES = "Other fractures";
export const LUXATION_SUBLUXATION = "Luxation and subluxation";
export const SPRAIN_STRAIN = "Sprain and strain";
export const TRAUMATIC_AMPUTATION = "Traumatic Amputation";
export const CONCUSSION_INTERNAL_TRAUMATIC_INJURY =
  "Concussion and internal traumatic injury";
export const BURNS = "Burns";
export const CHEMICAL_BURN = "Chemical burns";
export const SCALDING = "Scalding";
export const FROSTBITE = "Frostbite";
export const ACUTE_POISONING = "Acute Poisoning";
export const INFECTIONS = "Infections";
export const EFFECTS_RADIATION = "effects radiation";
export const HEAT_LIGHT_EFFECTS = "Heat and Light Effects";
export const HYPOTHERMIA = "Hypothermia";
export const ATMOSPHERIC_WATER_PRESSURE_EFFECT =
  "Effect of atmospheric and water Pressure";
export const ASPHYXIATION = "Asphyxiation";
export const EFFECT_MALTREATMENT = "Effects of maltreatment";
export const LIGHTNING_EFFECT = "Lightning Effects";
export const DROWNING_NONLETHAL_SUBMERSION =
  "Drowning and non-lethal submersion";
export const EFFECT_NOISE_VIBRATION = "Effects of Noise and Vibration";
export const EFFECT_ELECTRIC_CURRENT = "Effects of electric current";
export const OTHER_SPECIFIED_INJURIES = "Other specified injuries";
export const LESION_WITHOUT_DETAILS = "Type of lesion without details";

export default {
  SUPERFIACIAL_TRAUMATIC_INJURY,
  OPEN_WOUND,
  CLOSED_FRACTURE,
  OPEN_FRACTURE,
  OTHER_FRACTURES,
  LUXATION_SUBLUXATION,
  SPRAIN_STRAIN,
  TRAUMATIC_AMPUTATION,
  CONCUSSION_INTERNAL_TRAUMATIC_INJURY,
  BURNS,
  CHEMICAL_BURN,
  SCALDING,
  FROSTBITE,
  ACUTE_POISONING,
  INFECTIONS,
  EFFECTS_RADIATION,
  HEAT_LIGHT_EFFECTS,
  HYPOTHERMIA,
  ATMOSPHERIC_WATER_PRESSURE_EFFECT,
  ASPHYXIATION,
  EFFECT_MALTREATMENT,
  LIGHTNING_EFFECT,
  DROWNING_NONLETHAL_SUBMERSION,
  EFFECT_NOISE_VIBRATION,
  EFFECT_ELECTRIC_CURRENT,
  OTHER_SPECIFIED_INJURIES,
  LESION_WITHOUT_DETAILS,
};
