<template>
  <div class="grid as-cms-actions-tab align-items w-100 justify-content-center">
    <Button
      @click="addArticle"
      class="button-default mr-2"
      icon="pi pi-plus"
      iconPos="right"
      :label="$t('cms_page.add_article')"
    >
    </Button>
    <Button
      v-if="customAction1Label"
      @click="customAction1"
      class="button-default mr-2"
      iconPos="right"
      :icon="`pi ${customAction1Icon}`"
      :label="customAction1Label"
    >
    </Button>
    <ProgressSpinner strokeWidth="5" v-if="beingTranslated" />
    <Button
      @click="quitEditMode"
      class="button-default"
      icon="pi pi-sign-out"
      iconPos="right"
      :label="$t('cms_page.quit_edit_mode')"
    >
    </Button>
  </div>
</template>

<script>
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
export default {
  name: "AsCmsActionsTab",
  components: {
    Button,
    ProgressSpinner,
  },
  props: {
    customAction1Label: String,
    customAction1Icon: String,
    beingTranslated: Boolean,
  },
  emits: ["onQuitEdition", "onAddArticle", "customAction1"],
  methods: {
    quitEditMode() {
      this.$emit("onQuitEdition");
    },
    addArticle() {
      this.$emit("onAddArticle");
    },
    customAction1() {
      this.$emit("customAction1");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";
.as-cms-actions-tab {
  background: $header_bg;
  height: auto;
  position: fixed;
  margin: 0;
  padding-bottom: 5px;
  top: 170px;
  left: 0;
  right: 0;
  z-index: 100;
  pointer-events: none;
  .p-button {
    pointer-events: fill;
    margin-top: 5px;
  }

  .p-progress-spinner {
    height: 20px;
    width: 35px;
    margin: 0;
    margin-right: 15px;
  }
}
@media screen and (max-width: 1098px) {
  .as-cms-actions-tab {
    top: 100px;
  }
}

@media screen and (max-width: 715px) {
  .as-cms-actions-tab {
    top: 5px;
    .p-button {
      flex-basis: 90%;
      margin-right: 0 !important;
    }
  }
}
</style>
