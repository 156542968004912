<template>
  <div
    class="menu"
    :class="[{ column: displayOnColumn, row: !displayOnColumn }]"
  >
    <ul>
      <li class="home">
        <router-link to="/" class="link">
          <as-icon :source="home" :size="40" />
        </router-link>
      </li>
      <li
        v-for="page in pages"
        :key="page.id"
        v-show="page.id != 0 || isSafetyLeader || isAdmin"
      >
        <router-link v-if="page.link" :to="page.link" class="link">
          {{ $t(`menu.${page.key}`) }}
        </router-link>
        <a v-else :href="page.externalLink" target="_blank" class="link">
          {{ $t(`menu.${page.key}`) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import AsIcon from "../Elements/AsIcon";
import config from "../../config";
import roleMixins from "@/mixins/roleMixins";
export default {
  name: "AsMenu",
  components: {
    AsIcon,
  },
  props: {
    displayOnColumn: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    const pages = config.menuPages;
    if (this.isGuest) {
      const filteredKeys = ["accident_share"];
      this.pages = pages.filter((e) => !filteredKeys.includes(e.key));
    } else {
      this.pages = pages;
    }
  },
  data() {
    return {
      home: require("../../assets/images/icons/home.svg"),
      pages: [],
    };
  },
  mixins: [roleMixins],
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/sizing.scss";
@import "../../assets/styles/fonts.scss";

.menu {
  padding-left: $menu_padding;
  padding-right: $menu_padding;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $menu_bg;
  font-size: $font_navigation_size;
  box-sizing: border-box;
  &.row {
    height: $menu_height;
    ul {
      flex-direction: row;
    }
  }
  &.column {
    height: auto;
    ul {
      flex-direction: column;
      overflow: scroll;
      height: auto;
      li {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  ul {
    .home {
      width: 40px;
    }
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    font-weight: bold;
    li {
      &:not(:first-child) {
        a {
          &.router-link-exact-active {
            padding: 15px 5px;
            color: $safety_card_arrow_blue !important;
            border-bottom: 10px solid $safety_card_arrow_blue;
          }
        }
      }
      a {
        &.link {
          text-decoration: none;
          &:active,
          &:link,
          &:focus,
          &:hover,
          &:visited {
            color: inherit;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1300px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (max-width: 1500px) {
    font-size: $font_navigation_size_sm;
  }
}
</style>
