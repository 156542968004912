<template>
  <div class="grid">
    <div class="col-12 md:col-3">
      <img v-if="image" :src="image" alt="bp-img" />
      <div>
        <tag
          class="mr-1 mb-1 tag"
          v-for="(tag, index) in bestPractice.tags"
          :key="index"
          >{{ tag.libelle }}</tag
        >
      </div>
    </div>
    <div class="col-12 md:col-9">
      <div class="flex flex-wrap align-items margin-1p">
        <div class="flex align-items">
          <as-title
            :title="bestPractice.detail[0].titre"
            :size="3"
            class="mr-2"
          />
        </div>
        <Button
          id="share-bestpractice"
          @click="shareBestPractice"
          icon="pi pi-share-alt"
          :title="$t('best_practices.share')"
          class="p-button-sm p-button-rounded button-primary mr-auto"
        />
        <div class="flex flex-column opinion-and-share pl-2 mb-2">
          <span>
            {{ $t("best_practices.my_opinion") }}
          </span>
					<Rating
							class="ml-0"
							v-model="notation"
							:stars="5"
							:cancel="false"
							@change="notationChange"
					/>
        </div>
      </div>

      <div class="description">
        <div class="flex flex-wrap">
          <div class="header-file">
            <as-icon
              class="mr-1"
              :source="bestPractice.entreprise.fichierLogo.url"
              :size="40"
              respectRatio
            />
            <as-icon
              class="mr-1"
              :source="bestPractice.entreprise.fichierDrapeau.url"
              :size="40"
              respectRatio
            />
            {{ bestPractice.entreprise.details.nom }}
          </div>
          <div
            class="header-file"
            v-for="(fichier, index) of bestPractice.fichiers"
            :key="index"
          >
            <as-file
              class="header-file__content"
              :fichier="fichier"
              @onDownload="onDownloadFichier"
            />
          </div>
        </div>
        <div class="margin-1p">
          <div class="grid">
            <div class="col">
              <label>{{ $t("best_practices_form.depositor_field") }}</label>
            </div>
            <div class="col">{{ bestPractice.depositorName }}</div>
          </div>
          <div class="grid">
            <div class="col">
              <label>{{ $t("best_practices_form.safetyLeader_field") }}</label>
            </div>
            <div class="col">{{ bestPractice.safetyLeaderName }}</div>
          </div>
          <div class="grid">
            <div class="col">
              <label>{{
                $t("best_practices_form.referential_section_field")
              }}</label>
            </div>
            <div class="col">{{ categoryName }}</div>
          </div>
          <div class="grid">
            <div class="col">
              <label>{{
                $t("best_practices_form.complexity_level_field")
              }}</label>
            </div>
            <div class="col">{{ complexityLevel }}</div>
          </div>
          <div class="grid">
            <div class="col">
              <label>{{
                $t("best_practices_form.payback_estimation_field")
              }}</label>
            </div>
            <div class="col">{{ paybackEstimation }}</div>
          </div>
          <div class="grid">
            <div class="col">
              <label>{{
                $t("best_practices_form.duplicability_level_field")
              }}</label>
            </div>
            <div class="col">{{ duplicabilityLevel }}</div>
          </div>
          <div class="grid">
            <div class="col">
              <label>{{
                $t("best_practices_form.implementation_cost_field")
              }}</label>
            </div>
            <div class="col">{{ bestPractice.implementationCost }}</div>
          </div>
          <div class="grid">
            <div class="col">
              <label>{{
                $t("best_practices_form.other_contributor_field")
              }}</label>
            </div>
            <div class="col">{{ bestPractice.otherContributorName }}</div>
          </div>
          <div class="grid">
            <div class="col">
              <label>{{
                $t("best_practices_form.other_contributor_coordinate_field")
              }}</label>
            </div>
            <div class="col">
              {{ bestPractice.otherContributorCoordinate }}
            </div>
          </div>
          <div class="grid">
            <div class="col-12">
              <label>{{ $t("best_practices_form.description_field") }}</label>
            </div>
            <div class="col-12">
              <div v-html="bestPractice.detail[0].description"></div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div id="notation-list">
        <Accordion>
          <AccordionTab :header="$t('best_practices.notation_list_title')">
            <DataTable :value="list" responsiveLayout="scroll">
              <Column field="utilisateur">
                <template #body="slotProps">
                  <div class="flex align-items">
                    <img
                      class="profile-img"
                      :src="userPhoto(slotProps.data.utilisateur)"
                    />
                    <div class="grid">
                      <div class="col-12">
                        {{ slotProps.data.utilisateur.nom }}
                        {{ slotProps.data.utilisateur.prenom }}
                      </div>
                      <div class="col-12">
                        <Rating
                          v-model="slotProps.data.note"
                          disabled="true"
                          :stars="5"
                          :cancel="false"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </Column>
            </DataTable>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
</template>

<script>
import AsTitle from "@/components/Elements/AsTitle";
import AsIcon from "@/components/Elements/AsIcon";
import AsFile from "@/components/Elements/AsFile";
import Tag from "primevue/tag";
import Rating from "primevue/rating";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

import complexityLevels from "@/constantes/complexityLevels";
import paybackEstimations from "@/constantes/paybackEstimations";
import duplicabilityLevels from "@/constantes/duplicabilityLevels";

import fichiersServiceApi from "@/services/fichiersServiceApi";
import bestPracticesServiceApi from "@/services/bestPracticesServiceApi";
import { mapGetters } from "vuex";
import apiEventTypes from "../../constantes/apiEventTypes";
import { bufferToJpegBase64 } from "../../util";

export default {
  name: "BestPracticeInfo",
  props: {
    bestPractice: Object,
    rubriqueBestPractice: {},
    image: String,
    userNotation: Number,
    notationList: [],
  },
  components: {
    AsTitle,
    AsIcon,
    AsFile,
    Tag,
    Rating,
    Button,
    Accordion,
    AccordionTab,
    DataTable,
    Column,
  },
  mixins: [fichiersServiceApi],
  mounted() {
    bestPracticesServiceApi.updateBestPracticeConsultationCounter(
      this.bestPractice.id
    );
  },
  computed: {
    categoryName() {
      return this.findCategoryLabel(
        this.rubriqueBestPractice.categories,
        this.bestPractice.idCategorie
      );
    },
    complexityLevel() {
      for (const property in complexityLevels) {
        if (complexityLevels[property] === this.bestPractice.complexityLevel) {
          return this.$t(`complexityLevels.${property}`);
        }
      }
      return undefined;
    },
    paybackEstimation() {
      for (const property in paybackEstimations) {
        if (
          paybackEstimations[property] === this.bestPractice.paybackEstimation
        ) {
          return this.$t(`paybackEstimations.${property}`);
        }
      }
      return undefined;
    },
    duplicabilityLevel() {
      for (const property in duplicabilityLevels) {
        if (
          duplicabilityLevels[property] === this.bestPractice.duplicabilityLevel
        ) {
          return this.$t(`duplicabilityLevels.${property}`);
        }
      }
      return undefined;
    },
    ...mapGetters({
      user: "getUser",
    }),
  },
  methods: {
    onDownloadFichier(externalId, titre) {
      this.downloadFile(externalId, titre);
    },
    findCategoryLabel(categories, idCategorie) {
      let result = idCategorie + "";
      categories.forEach((categorie) => {
        if (categorie.children) {
          categorie.children.forEach((children) => {
            if (children.id === idCategorie) {
              result = children.detail[0].libelle;
            }
          });
        }
      });
      return result;
    },
    async notationChange(event) {
      const notation = {
        idLdap: this.user.idLdap,
        idBestPractice: this.bestPractice.id,
        note: event.value,
      };
      const result = await bestPracticesServiceApi.saveBestPracticesNotations(
        notation
      );
      if (result) {
        this.$emit("onNotation", result);
      }
    },
    shareBestPractice() {
      const key = "best-practices";
      const splittedHref = window.location.href.split(key);
      const bestPracticeLink = `${splittedHref[0]}${key}/${this.bestPractice.id}`;
      navigator.clipboard.writeText(bestPracticeLink);
      document.dispatchEvent(
        new CustomEvent(apiEventTypes.SUCCESS, {
          detail: {
            title: this.$t("best_practices.share_copy_title"),
            message: this.$t("best_practices.share_copy_content"),
          },
        })
      );
    },

    userPhoto(user) {
      if (user.photo && user.photo.data.length) {
        return bufferToJpegBase64(user.photo.data);
      } else {
        return this.noUserImg;
      }
    },
  },
  data() {
    return {
      notation: this.userNotation,
      list: this.notationList,
    };
  },
};
</script>

<style lang="scss" scoped>
.margin-1p {
  margin: 0 1%;
}
.opinion-and-share {
  min-width: 130px;
}
.header-file {
  display: flex;
  align-items: center;
  margin: 1%;
  flex-basis: 30.3333333333%;
  &__content {
    width: 100%;
  }
}
img {
  max-width: 100%;
}
label {
  font-weight: bold !important;
  text-decoration: underline;
}

#notation-list {
  :deep(.p-datatable-thead) {
    display: none;
  }
  .profile-img {
    background-color: white;
    height: 70px;
    width: 70px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    border-radius: 40px;
    overflow: hidden;
  }
}

@media screen and (max-width: 1360px) {
  .description {
    font-size: 0.8rem;
  }
  .header-file {
    img {
      width: 30px !important;
    }
    flex-basis: 100%;
  }
}
@media screen and (max-width: 500px) {
  #notation-list {
    .profile-img {
      height: 60px;
      width: 60px;
      margin-right: 15px;
    }
  }
}
@media screen and (max-width: 400px) {
  :deep(.p-datatable-tbody td) {
    padding: 0.5rem !important;
  }
  #notation-list {
    .profile-img {
      height: 40px;
      width: 40px;
      margin-right: 15px;
    }
  }
}
</style>
